import {useEffect, Fragment, useState} from "react"
import {useHistory, useParams} from "react-router-dom"
import {CheckCircle, File, Video, Link2} from "react-feather"
import {
    Button,
    Row,
    Col,
    Spinner,
    CardBody,
    InputGroup,
    InputGroupAddon,
    Input,
    Card,
    ModalHeader, ModalBody, Modal
} from "reactstrap"
import {useSelector, useDispatch} from "react-redux"
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import {resourceContentRDAction} from "@src/views//apps/opportunitiesBrands/store/actions"
import queryString from "query-string"

const ResourcesContent = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const history = useHistory()

    const [search, setSearch] = useState('')
    const [showDescModal, setShowDescModal] = useState(false)
    const [resourceTitle, setResourceTitle] = useState('')
    const [resourceDesc, setResourceDesc] = useState('')

    const {resourceContentRDLoading, resourceContentRDData, resourceContentRDError} = useSelector((store) => store.resourceContentRReducer)

    useEffect(() => {
        const parsed = queryString.parseUrl(window.location.href)
        const pageNo = parsed?.query?.page
        const qryTitle = parsed?.query?.title
        if (qryTitle) {
            setSearch(qryTitle)
        }
        dispatch(resourceContentRDAction({
            folder_id: id,
            title: qryTitle,
            page: pageNo
        }))
    }, [])

    const handleCustomPagination = (pageUrl) => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        dispatch(resourceContentRDAction({
            folder_id: id,
            title: search,
            page: pageNo
        }))
        history.push({
            pathname: window.location.pathname,
            search: `?page=${pageNo}`
        })
    }

    const resetResourcesContent = () => {
        setSearch('')
        dispatch(resourceContentRDAction({
            folder_id: id
        }))
        history.push({
            pathname: window.location.pathname,
            search: ''
        })
    }

    const searchResourcesContent = () => {
        const parsed = queryString.parseUrl(window.location.href)
        const pageNo = parsed?.query?.page
        dispatch(resourceContentRDAction({
            folder_id: id,
            page: pageNo,
            title: search
        }))
    }

    const viewData = (URL) => {
        if (URL) {
            window.open(URL, '_blank')
        }
    }

    const closeResourceModal = () => {
        setShowDescModal(false)
        setResourceTitle('')
        setResourceDesc('')
    }

    const viewDetails = (title, desc) => {
        setShowDescModal(true)
        setResourceTitle(title)
        setResourceDesc(desc)
    }

    return (
        <Fragment>
            <Card className="mt-2">
                <CardBody className="">
                    <Row>
                        <Col md="12">
                            <InputGroup size='lg' className="mt-2 mt-md-0">
                                <Input placeholder='Search for items' onChange={(e) => setSearch(e.target.value)} value={search}/>
                                <InputGroupAddon addonType='append'>
                                    <Button color='primary' outline onClick={searchResourcesContent}>
                                        Search
                                    </Button>
                                </InputGroupAddon>
                                <InputGroupAddon addonType='append'>
                                    <Button color='primary' outline onClick={resetResourcesContent}>
                                        Reset
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <h4 className="brandSlidesHeading font-weight-bolder mb-3">
                {resourceContentRDData?.folder?.title}
            </h4>
            <div className="brandSlides mb-3">
                <Row>
                    {resourceContentRDLoading && (<div className="w-100 my-4 text-center"><Spinner color="primary"/></div>)}
                    {!resourceContentRDLoading && resourceContentRDData?.data?.length === 0 && (<div className="w-100 my-4 text-center"><h5>No resource found.</h5></div>)}
                    {!resourceContentRDLoading && resourceContentRDData?.data?.length > 0 && resourceContentRDData?.data?.map((val) => (
                        <Fragment>
                            <Col md="3" className="mb-4">
                                <div className="brandContent">
                                    <div className="row justify-content-between">
                                        <div className="col-auto ml-auto">
                                            {val.full_type && (
                                                <small className="border border-1 px-75 py-25 border-muted text-muted">
                                                    {val.full_type}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    {val.type === 'link' && (
                                        <div className="mt-1">
                                            {val.cover ? (<img src={val.cover} className="resource-cover-img"/>) : (<Link2 size={100} style={{color: '#56B6FF'}}/>)}
                                        </div>
                                    )}
                                    {val.type === 'doc' && (
                                        <div className="mt-1">
                                            {val.cover ? (<img src={val.cover} className="resource-cover-img"/>) : (<File size={100} style={{color: '#56B6FF'}}/>)}
                                        </div>
                                    )}
                                    {val.type === 'media' && (
                                        <div className="mt-1">
                                            {val.cover ? (<img src={val.cover} className="resource-cover-img"/>) : (<Video size={100} style={{color: '#56B6FF'}}/>)}
                                        </div>
                                    )}
                                    <h2 className="resource-content-title mt-2 cursor-default" id={`folder-title-${val.id}`}>
                                        {val.short_title}
                                        {/*{val.description && (<UncontrolledTooltip placement='top' target={`folder-title-${val.id}`}>*/}
                                        {/*      {val.description}*/}
                                        {/*    </UncontrolledTooltip>*/}
                                        {/*)}*/}
                                    </h2>
                                    <div className="mt-2" style={{height: '75px'}} onClick={() => viewDetails(val.title, val.description)}>
                                        <p className="cursor-pointer text-break" style={{color: "#161d31", whiteSpace: "break-spaces"}}>
                                            {(val.description?.length > 100) ? `${val.description.substring(0, 80)}...` : val.description}
                                        </p>
                                    </div>
                                    <Button.Ripple color="secondary" className="btn-block" onClick={() => viewData(val.content_path)}>
                                        <CheckCircle size={14}/> View
                                    </Button.Ripple>
                                </div>
                            </Col>
                        </Fragment>
                    ))}
                </Row>
                <Row className="pt-4 justify-content-centered">
                    <Col md="12">
                        <CustomPagination pageLinks={resourceContentRDData?.pages} onPaginationClick={handleCustomPagination}/>
                    </Col>
                </Row>
            </div>

            <Modal isOpen={showDescModal} className='modal-dialog-centered modal-lg'>
                <ModalHeader toggle={closeResourceModal}>Resource Details</ModalHeader>
                <ModalBody>
                    <div className="p-1 bg-light font-weight-bold mb-1">
                        {resourceTitle}
                    </div>
                    <div className="p-1 bg-light text-break" style={{whiteSpace: 'break-spaces', textAlign: 'justify'}}>
                        {resourceDesc}
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default ResourcesContent
