import {useState, useEffect, useRef, Fragment, memo, useContext} from "react"
import ReactDOM from "react-dom"
import {SocketContext} from '@src/utility/context/Socket'
import {Link} from "react-router-dom"
import Avatar from "@components/avatar"
import {useDispatch, useSelector} from "react-redux"
import {
    storeMessagesInRedux,
    addBrandContactsInRedux,
    sendMsgRD,
    uploadMediaToserver,
    recieveMsg,
    saveTemplete,
    sendBroadcastMsg,
    myRdList,
    allRdList
} from "./store/actions"
import {
    getFavoriteFolders,
    getFavoriteRDProfiles,
    searchNutritionExperts
} from '@src/redux/actions/brandsDashboard'
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import {
    MessageSquare,
    Send,
    Paperclip,
    Save,
    FileText,
    Heart,
    ArrowLeftCircle,
    File,
    List,
    ChevronLeft, XCircle, MessageCircle
} from "react-feather"
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Form,
    Label,
    InputGroup,
    InputGroupAddon,
    Input,
    InputGroupText,
    Button,
    UncontrolledButtonDropdown,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    FormGroup,
    UncontrolledTooltip,
    CustomInput,
    Card,
    CardBody,
    Row,
    Col,
    Spinner,
    Alert, Table
} from "reactstrap"
import {allowedFiles, allowedFilesErrorMessages} from '@src/utility/Utils'
import Linkify from "react-linkify"
import BlankProfile from "@src/assets/images/avatars/avatar-blank.png"
import {Slide, toast} from "react-toastify"
import {getGoalsAttributes} from '@src/views/brands/nutritionExperts/store/actions'
import {
    rdGroupList,
    rdGroupListReset,
    massMessageTempSave,
    massMessageTempSaveReset,
    massMessageTempLoad,
    massMessageTempDelete,
    massMessageTempDeleteReset
} from "@src/redux/actions/BrandActions"
import {useFormik} from "formik"
import * as yup from "yup"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import draftToHtml from "draftjs-to-html"
import {ContentState, convertToRaw, EditorState} from "draft-js"
import {Editor} from "react-draft-wysiwyg"
import '@styles/react/libs/editor/editor.scss'
import '@styles/base/plugins/forms/form-quill-editor.scss'
import htmlToDraft from "html-to-draftjs"

const ChatLog = (props) => {
    const sweetAlert = withReactContent(Swal)

    const ps = useRef([])
    const {
        handleUser,
        handleUserSidebarRight,
        handleSidebar,
        store,
        userSidebarLeft,
        user,
        selectedUserID,
        selectedUserBrandChat,
        selectedContactDetail,
        currentUser
    } = props

    const brandChatStoreSlice = useSelector((state) => state.chatBrands)

    const brandsDashboardStoreSlice = useSelector(store => store.brandsDashboardSlice)

    const {
        rdGroupListLoading,
        rdGroupListData,
        rdGroupListError,

        massMsgTempSaveLoading,
        massMsgTempSaveData,
        massMsgTempSaveError,

        massMsgTempListLoading,
        massMsgTempListData,
        massMsgTempListError,

        massMsgTempDeleteLoading,
        massMsgTempDeleteData,
        massMsgTempDeleteError
    } = useSelector(state => state.brandReducer)

    const chatArea = useRef(null)
    const checkAllRef = useRef(null)
    const dispatch = useDispatch()
    const socket = useContext(SocketContext)

    // ** State
    const [msg, setMsg] = useState("")
    const [questate, setQueState] = useState({inQueFilePreview: "", inQueFile: ""})
    const [selectedFile, setSelectedFile] = useState(null)
    const [isFilePicked, setIsFilePicked] = useState(false)
    const [isConnecting, setIsConnecting] = useState(true)
    const [isOpenTempleteModal, setIsOpenTempleteModal] = useState(false)
    const [templeteTitle, setTempleteTitle] = useState(null)
    const [isOpenBroadcastModal, setIsOpenBroadcastModal] = useState(false)
    const [broadcastMessage, setBroadcastMessage] = useState('')
    const [selectedBroadcastContacts, setSelectedBraodcastContacts] = useState([])
    const [broadCastRDsList, setBroadCastRDsList] = useState([])
    const [broadCastRDsListLoading, setBroadCastRDsListLoading] = useState(false)
    const [fileUploadError, setFileUploadError] = useState(false)
    const [checkAllVal, setCheckAllVal] = useState(false)
    const [showFav, setShowFav] = useState(false)
    const [favFolderList, setFavFolderList] = useState(null)
    const [favFolderId, setFavFolderId] = useState(null)
    const [favFolderTitle, setFavFolderTitle] = useState(null)
    const [backToFav, setBackToFav] = useState(false)
    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [selectedRds, setSelectedRds] = useState([])
    const [selectedRadio, setSelectedRadio] = useState('')
    const [broadCastSendLoader, setBroadCastSendLoader] = useState(false)
    const [broadCastResMsg, setBroadCastResMsg] = useState(null)
    const [broadCastResStatus, setBroadCastResStatus] = useState(null)
    const [sendMsgLoader, setSendMsgLoader] = useState(false)
    const [showGroups, setShowGroups] = useState(false)
    const [groupData, setGroupData] = useState({speciality: [], industry: [], characteristics: []})
    const [openMassTempModal, setOpenMassTempModal] = useState(false)
    const [openMassTempLoadModal, setOpenMassTempLoadModal] = useState(false)
    const [massMsgEditorState, setMassMsgEditorState] = useState(null)
    const [msgEditorState, setMsgEditorState] = useState(null)

    const scrollToBottom = () => {
        const chatContainer = ReactDOM.findDOMNode(chatArea.current)
        chatContainer.scrollTop = Number.MAX_SAFE_INTEGER
    }

    const saveMassTempForm = useFormik({
        initialValues: {
            title: "",
            message: ""
        },
        validationSchema: yup.object().shape({
            title: yup.string().trim().required("This field is required"),
            message: yup.string().trim().required("This field is required")
        }),
        onSubmit: (values) => {
            dispatch(massMessageTempSave({
                title: values.title,
                template: values.message,
                type: 'mass'
            }))
        }
    })

    useEffect(() => {

        socket.on("connect", (error) => {
            if (error) {
                alert(error)
            }
        })

        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        socket.emit("join", user, (error) => {
            // console.log("user joined sgsgsgs srgsgs sgs", user)
        })

        if (selectedUserID) {
            const selectedChatRdContact = JSON.parse(localStorage.getItem('selectedChatRd'))
            dispatch(addBrandContactsInRedux({chat_user: parseInt(selectedUserID), ...selectedChatRdContact}))
        }

        dispatch(getGoalsAttributes())
        dispatch(massMessageTempLoad("?type=mass"))

    }, [])

    useEffect(() => {
        if (selectedContactDetail) {
            scrollToBottom()
        }

        if (!!brandChatStoreSlice?.braodcastContactList) {
            setBroadCastRDsListLoading(false)
            setBroadCastRDsList(brandChatStoreSlice.braodcastContactList.data)
        }

        if (!!brandChatStoreSlice?.broadcastResponse) {
            if (brandChatStoreSlice?.broadcastResponse?.status === true) {
                setBroadCastResMsg(brandChatStoreSlice?.broadcastResponse?.msg)
                setBroadCastResStatus(brandChatStoreSlice?.broadcastResponse?.status)
                setBroadCastSendLoader(false)
                setIsOpenBroadcastModal(false)
                setSelectedBraodcastContacts([])
                setCheckAllVal(false)
                setBroadCastRDsList([])
                dispatch({type: 'CLEAT_BROADCAST_LIST'})
                dispatch({type: 'CLEAT_BROADCAST_SUCCESS_RESPONSE'})
                dispatch({type: 'CLEAR_CHAT_CONTENT_ON_LOAD'})
                dispatch({type: 'CLEAR_CHAT_CONTACT_ON_LOAD'})
                setTimeout(() => {
                    setShowPreviewModal(false)
                    setBroadCastResMsg(null)
                    setBroadCastResStatus(null)
                    setSelectedRds([])
                    setBroadcastMessage("")
                    setMassMsgEditorState(null)
                }, 5000)
            }

            if (brandChatStoreSlice?.broadcastResponse?.config) {
                sweetAlert.fire({
                    title: 'Error!',
                    icon: 'error',
                    html: `<p>Status: ${brandChatStoreSlice?.broadcastResponse?.response?.status || 500}</p><p>${JSON.stringify(brandChatStoreSlice?.broadcastResponse?.response?.data ?? "Internal Server Error")}</p>`,
                    allowOutsideClick: false,
                    showCancelButton: false
                })
                setBroadCastSendLoader(false)
                dispatch({type: 'CLEAT_BROADCAST_SUCCESS_RESPONSE'})
            }
        }

        if (brandChatStoreSlice?.sendMessageResponse) {
            setSendMsgLoader(false)
            if (brandChatStoreSlice?.sendMessageResponse.status) {
                toast.success(
                    <Fragment>
                        <div className='toastify-header'>
                            <div className='title-wrapper'>
                                <Avatar size='sm' color='success' icon={<MessageSquare size={12}/>}/>
                                <h6 className='toast-title font-weight-bold'>Success</h6>
                            </div>
                        </div>
                        <div className='toastify-body'>
                            <span>{brandChatStoreSlice?.sendMessageResponse?.msg}</span>
                        </div>
                    </Fragment>, {transition: Slide, hideProgressBar: true, autoClose: 5000})
            } else {
                toast.error(<Fragment>{brandChatStoreSlice?.sendMessageResponse?.msg}</Fragment>, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 5000
                })
            }
            setMsg("")
            setMsgEditorState(null)
            dispatch({type: "SEND_MSG_NULL_RESPONSE"})
        }

        const error = brandChatStoreSlice?.chatLoadError?.response?.status
        if (error && error !== 401) {
            dispatch({type: "BRAND_CHAT_LOAD_ERROR_RESET"})
            dispatch({type: "SELECTED_CHAT_LOADER_RESET"})
            sweetAlert.fire({
                title: 'Connection Error!',
                icon: 'error',
                html: '<p>Please reload the page.</p>',
                confirmButtonText: 'Reload',
                allowOutsideClick: false,
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }
    }, [brandChatStoreSlice])

    useEffect(() => {
        socket.on('new-user-message', (data) => {
            const receivedMessage = {
                message: data.data.message,
                media: data.data.media,
                from_user_id: data.data.from_user_id,
                to_user_id: data.data.to_user_id
            }
            // console.log('new-user-message data', data)
            // console.log('new-user-message', receivedMessage)
            dispatch(recieveMsg(receivedMessage))
        })
    }, [])

    useEffect(() => {
        if (brandsDashboardStoreSlice?.favoriteFoldersRes) {
            setBroadCastRDsListLoading(false)
            setFavFolderList(brandsDashboardStoreSlice?.favoriteFoldersRes?.data)
        }

        if (brandsDashboardStoreSlice?.favoriteRDProfilesRes) {
            setBroadCastRDsListLoading(false)
            const data = brandsDashboardStoreSlice?.favoriteRDProfilesRes?.data.map((item) => {
                return {
                    user_id: item.id,
                    profile_img: item.profile_img,
                    name: item.name,
                    is_favorite: null,
                    folder_title: null
                }
            })
            setBroadCastRDsList(data)
        }
    }, [brandsDashboardStoreSlice])

    useEffect(() => {
        if (rdGroupListData?.data) {
            setBroadCastRDsList(rdGroupListData?.data)
            setSelectedBraodcastContacts(rdGroupListData?.data.map(item => item?.user_id))
            setSelectedRds(rdGroupListData?.data.map(item => item?.name))
        }
    }, [rdGroupListData])

    useEffect(() => {
        if (massMsgTempSaveData?.success) {
            setOpenMassTempModal(false)
            dispatch(massMessageTempLoad("?type=mass"))
            saveMassTempForm.resetForm()
            setTimeout(() => {
                dispatch(massMessageTempSaveReset())
            }, 3000)
        }

        if (massMsgTempSaveError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (massMsgTempSaveError?.response?.data?.errors?.title) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${massMsgTempSaveError?.response?.data?.errors?.title[0]}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(massMessageTempSaveReset())
        }

        if (massMsgTempDeleteData?.success) {
            sweetAlert.fire({
                title: 'Success',
                icon: 'success',
                html: massMsgTempDeleteData?.msg,
                allowOutsideClick: false
            })
            dispatch(massMessageTempLoad("?type=mass"))
            dispatch(massMessageTempDeleteReset())
        }
    }, [massMsgTempSaveData, massMsgTempSaveError, massMsgTempListData, massMsgTempDeleteData])

    const getBase64 = (img, callback) => {
        if (img) {
            const reader = new FileReader()
            reader.addEventListener('load', () => callback(reader.result))
            reader.readAsDataURL(img)
        }
    }

    const handleSendMsg = (e) => {
        e.preventDefault()
        setSendMsgLoader(true)
        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        const receiver_id = selectedContactDetail.chat_user
        const date = new Date()
        const objTest = {
            chat: [],
            selectedRd: selectedUserID
        }
        const msgObj = {
            message: msg,
            from_user_id: user.chat_id,
            to_user_id: receiver_id,
            timezone: date.toString()
        }
        objTest.chat.push(msgObj)
        dispatch(storeMessagesInRedux(objTest))
        dispatch(sendMsgRD(msgObj))
        socket.emit("new-message", msgObj)
        if (selectedFile) {
            const media = {
                to_user_id: receiver_id,
                file: selectedFile,
                from_user_id: user.chat_id,
                socket
            }
            dispatch(uploadMediaToserver(media))
            setIsFilePicked(false)
            setSelectedFile('')
            setQueState(prevState => ({
                ...prevState,
                inQueFilePreview: '',
                inQueFile: ''
            }))
        }
    }

    const handleKeyPress = (event) => {
        const message = event.target.value.trim()
        if (event.key === "Enter" && !event.shiftKey && message) {

        }
    }

    const changeHandler = (event) => {
        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        const receiver_id = selectedContactDetail.chat_user
        const file = event.target.files[0]
        setFileUploadError(false)
        if (!file) return
        if (allowedFiles().includes(file.name?.split('.').pop().toLowerCase())) {
            setSelectedFile(file)

            getBase64(file, imageUrl => {
                // console.log('inQueFilePreview', imageUrl)
                setQueState(prevState => ({
                    ...prevState,
                    inQueFilePreview: imageUrl,
                    inQueFile: file
                }))
            })
            setIsFilePicked(true)
        } else {
            setSelectedFile(null)
            setQueState({
                inQueFilePreview: '',
                inQueFile: ''
            })
            setFileUploadError(true)
        }
    }

    const handleTitle = (event) => {
        // console.log(event.target.value)
        setTempleteTitle(event.target.value)
    }

    const handleMessageTemplete = () => {
        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        const receiver_id = selectedContactDetail.chat_user
        // e.preventDefault()
        const objTest = {
            chat: [],
            selectedRd: selectedUserID
        }
        if (msg.length !== 0) {
            if (templeteTitle !== null) {
                const msgTemplete = {
                    message: msg,
                    title: templeteTitle
                }
                dispatch(saveTemplete(msgTemplete))
                // setMsg("")
                setTempleteTitle("")
                setIsOpenTempleteModal(false)

            } else {
                alert('Template title is required.')
            }
        } else {
            alert('Message field is required.')
            setIsOpenTempleteModal(false)

        }

    }

    const appendTemplete = (item) => {
        const content = htmlToDraft(item.template)
        const contentState = ContentState.createFromBlockArray(content?.contentBlocks)
        setMsgEditorState(EditorState.createWithContent(contentState))
        setMsg(item.template)
    }

    const getRdList = (option) => {
        setSelectedBraodcastContacts([])
        setBroadCastRDsList([])
        setCheckAllVal(false)
        setFavFolderList(null)
        setBackToFav(false)
        setSelectedRds([])
        dispatch({type: "CLEAR_FAV_FOLDERS_LIST"})
        dispatch({type: "CLEAR_FAV_PROFILES"})
        setSelectedRadio(option)
        if (option === 'my_rds') {
            setBroadCastRDsListLoading(true)
            setShowFav(false)
            setShowGroups(false)
            dispatch(myRdList())
        } else if (option === 'all_rds') {
            setBroadCastRDsListLoading(true)
            setShowFav(false)
            setShowGroups(false)
            dispatch(allRdList())
        } else if (option === 'groups') {
            setBroadCastRDsListLoading(false)
            setShowFav(false)
            setShowGroups(true)
        } else if (option === 'favourites') {
            setBroadCastRDsListLoading(true)
            setShowFav(true)
            setShowGroups(false)
            const API_URL = process.env.REACT_APP_BASEURL
            dispatch(getFavoriteFolders(true, `${API_URL}brand/load-folder-list?list=all`))
        }
    }

    const handleSelectBroadCastingContact = (contact) => {
        setCheckAllVal(false)
        let list = [...selectedBroadcastContacts]
        let names = [...selectedRds]
        if (!list.includes(contact.user_id)) {
            list.push(contact.user_id)
            names.push(contact.name)
        } else {
            list = list.filter(id => id !== contact.user_id)
            names = names.filter(name => name !== contact.name)
        }
        setSelectedBraodcastContacts(list)
        setSelectedRds(names)
    }

    const sendBroadcastMessage = () => {
        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        const date = new Date()
        const msgObj = {
            message: broadcastMessage,
            from_user_id: user.chat_id,
            role: "rd",
            socket,
            to_users: selectedBroadcastContacts,
            timezone: date.toString()
        }
        setBroadCastSendLoader(true)
        dispatch(sendBroadcastMsg(msgObj))
    }

    const showMoreLess = (index) => {
        const chatContainer = ReactDOM.findDOMNode(chatArea.current)
        chatContainer.scrollTop = ps.current[index].offsetTop - 10
    }

    const saveFile = (url, fileName) => {
        saveAs(url, fileName)
    }

    const deleteMassMsgTemp = (data) => {
        sweetAlert.fire({
            title: 'Confirm!',
            icon: 'warning',
            html: '<p>Do you want to delete this template?</p>',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            showCancelButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(massMessageTempDelete({
                    id: data.id
                }))
            }
        })
    }

    const renderChats = () => {
        return selectedUserBrandChat?.data.map((item, index) => {
            return (
                <div
                    ref={(el) => (ps.current[index] = el)}
                    key={index}
                    className={classnames("chat", {
                        "chat-left": item?.to_user_id !== selectedContactDetail?.chat_user
                    })}
                >
                    <div className="chat-avatar">
                        <Link
                            to={item?.to_user_id === selectedContactDetail?.chat_user ? `/dashboard/brands/profile` : `/dashboard/brands/nutritions/${item?.from_user_id}`}>
                            <Avatar className='box-shadow-1 cursor-pointer'
                                    img={item?.to_user_id === selectedContactDetail?.chat_user ? currentUser?.logo || currentUser?.logo : selectedContactDetail?.profile_image}
                            />
                        </Link>
                    </div>
                    {item.media ? (
                        <div className="chat-body">
                            <div className="chat-content media-file">
                                {item.message_type === "img" && (
                                    <div>
                                        <img src={item.media} height="200" title={item.file_name}/>
                                        <p className="mt-2 text-primary font-small-2 text-center cursor-pointer"
                                           style={{textDecoration: "underline"}}>
                                            <a href={item.media} target="_blank">Download</a>
                                        </p>
                                    </div>
                                )}
                                {item.message_type === "pdf" && (
                                    <div>
                                        <File size={100} style={{color: '#56B6FF'}}/>
                                        <p className="mt-2 text-primary font-small-2 text-center cursor-pointer"
                                           style={{textDecoration: "underline"}}>
                                            <a href={item.media} target="_blank">Download</a>
                                        </p>
                                    </div>
                                )}
                                {item.message_type === "doc" && (
                                    <div>
                                        <File size={100} style={{color: '#56B6FF'}}/>
                                        <p className="mt-2 text-primary font-small-2 text-center cursor-pointer"
                                           style={{textDecoration: "underline"}}>
                                            <a href={item.media} target="_blank">Download</a>
                                        </p>
                                    </div>
                                )}
                                {/*<p><a href={item.media} target='_blank'>{item.file_name}</a></p>*/}
                            </div>
                            {item.send_by &&
                                <p className="child"> sent by {item.send_by}</p>
                            }
                        </div>
                    ) : (
                        <>
                            <div className="chat-body">
                                <div className="chat-content text-break" style={{whiteSpace: "break-spaces"}}>
                                    <div dangerouslySetInnerHTML={{__html: item.message}}></div>
                                    <p className="child" style={{marginTop: "10px", textAlign: "right"}}>
                                        {item.timezone}
                                    </p>
                                </div>
                            </div>
                            <div className="child" style={{marginRight: "50px", textAlign: "right"}}>
                                {item.send_by && <p> sent by {item.send_by}</p>}
                            </div>
                        </>
                    )}
                </div>
            )
        })
    }

    // ** Opens right sidebar & handles its data
    const handleAvatarClick = (obj) => {
        handleUserSidebarRight()
        handleUser(obj)
    }

    // ** On mobile screen open left sidebar on Start Conversation Click
    const handleStartConversation = () => {
        if (
            // !Object.keys(selectedUser).length &&
            !userSidebarLeft &&
            window.innerWidth <= 1200
        ) {
            handleSidebar()
        }
    }

    // ** ChatWrapper tag based on chat's length
    const ChatWrapper = selectedContactDetail && selectedUserBrandChat ? PerfectScrollbar : 'div'

    const handleCheckAll = (e) => {
        const checked = e.target.checked
        const list = [...selectedBroadcastContacts]
        const names = [...selectedRds]
        if (checked) {
            setCheckAllVal(true)
            for (const item of broadCastRDsList) {
                if (!list.includes(item.user_id)) {
                    list.push(item.user_id)
                    names.push(item.name)
                }
            }
            setSelectedBraodcastContacts(list)
            setSelectedRds(names)
        } else {
            setCheckAllVal(false)
            setSelectedBraodcastContacts([])
            setSelectedRds([])
        }
    }

    const handleSelectedFolder = (folderId, folderTitle) => {
        setBackToFav(true)
        setShowFav(false)
        setFavFolderId(folderId)
        setFavFolderTitle(folderTitle)
        setBroadCastRDsListLoading(true)
        setSelectedBraodcastContacts([])
        setBroadCastRDsList([])
        setFavFolderList(null)
        dispatch({type: "CLEAR_FAV_FOLDERS_LIST"})
        const API_URL = process.env.REACT_APP_BASEURL
        dispatch(getFavoriteRDProfiles(true, `${API_URL}brand/favorites-profiles?folder_id=${folderId}&list=all`, ''))
    }

    const handleGroupChange = (category, event, value) => {
        dispatch(rdGroupListReset())
        setBroadCastRDsList([])
        setSelectedBraodcastContacts([])
        setGroupData(prevGroupData => {
            const newGroupData = {...prevGroupData}
            const index = newGroupData[category].indexOf(value?.id)
            if (index === -1) {
                newGroupData[category] = [...newGroupData[category], value?.id]
            } else {
                newGroupData[category] = [
                    ...newGroupData[category].slice(0, index),
                    ...newGroupData[category].slice(index + 1)
                ]
            }
            return newGroupData
        })
    }

    useEffect(() => {
        if (groupData.speciality.length > 0 || groupData.industry.length > 0 || groupData.characteristics.length > 0) {
            dispatch(rdGroupList({
                speciality: groupData.speciality,
                industry: groupData.industry,
                personal_attr: groupData.characteristics,
                filter: 'list'
            }))
        }
    }, [groupData])

    return (
        <Fragment>
            <div className="chat-app-window w-100 chat-brand">
                <div className={classnames("start-chatArea-parent", {
                    "d-none": !!selectedContactDetail
                })}>
                    <div className="chat-navbar">
                        <header className="chat-header">
                            <div className="d-flex align-items-center justify-content-lg-end justify-content-between">
                                <div className='sidebar-profile-toggle' onClick={() => {
                                    setIsOpenBroadcastModal(true)
                                    setSelectedBraodcastContacts([])
                                    setCheckAllVal(false)
                                    setSelectedRds([])
                                    getRdList('my_rds')
                                }}>
                                    <Button.Ripple className='btn-icon' color='primary' id="send-mass-msg" title="Send a mass message" size='sm'>
                                        <MessageCircle size={14}/> Mass Message
                                    </Button.Ripple>
                                </div>

                            </div>

                        </header>
                    </div>
                    <div
                        className={classnames("start-chat-area", {
                            "d-none": !!selectedContactDetail
                        })}>
                        {/* <div className='start-chat-area'> */}
                        <div className="start-chat-icon mb-1">
                            <MessageSquare/>
                        </div>
                        <h4
                            className="sidebar-toggle start-chat-text text-center"
                            onClick={handleStartConversation}
                        >
                            <div>Select a RD First to Start Chat.</div>
                            <div style={{textDecoration: 'underline'}} className="text-primary d-sm-none">Tap Here</div>
                        </h4>
                    </div>
                </div>
                <div
                    className={classnames("active-chat", {
                        "d-none": selectedContactDetail === null
                    })}
                >
                    <div className="chat-navbar">
                        <header className="chat-header">
                            <div className="d-flex align-items-center">
                                <div
                                    className="sidebar-toggle d-block d-lg-none mr-1 "
                                    onClick={handleSidebar}
                                >
                                    <ChevronLeft size={21}/>
                                </div>
                            </div>
                            <div className="w-100 d-flex align-items-center justify-content-end">
                                <div className='sidebar-profile-toggle' onClick={() => {
                                    setIsOpenBroadcastModal(true)
                                    setSelectedBraodcastContacts([])
                                    setCheckAllVal(false)
                                    setSelectedRds([])
                                    getRdList('my_rds')
                                }}>
                                    <Button.Ripple className='btn-icon' color='primary' id="send-mass-msg" title="Send a mass message" size='sm'>
                                        <MessageCircle size={14}/> Mass Message
                                    </Button.Ripple>

                                </div>
                            </div>
                        </header>
                    </div>
                    <ChatWrapper
                        ref={chatArea}
                        className="user-chats"
                        options={{wheelPropagation: false}}
                    >
                        <div className="chats">
                            {brandChatStoreSlice?.selectedUserBrandChatLoading &&
                                <div className="text-center mt-4"><Spinner color='primary'/></div>}
                            {!brandChatStoreSlice?.selectedUserBrandChatLoading && <Fragment>{renderChats()}</Fragment>}
                        </div>
                    </ChatWrapper>
                    <div className="bg-white d-block">
                        {fileUploadError &&
                            <div className="validation-err ml-1">{allowedFilesErrorMessages().IMAGE_AND_FILES} </div>}
                        <Form className="chat-app-form" onSubmit={(e) => handleSendMsg(e)}>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <Editor
                                        editorState={msgEditorState}
                                        onEditorStateChange={(e) => {
                                            setMsgEditorState(e)
                                            const contentState = e.getCurrentContent()
                                            if (contentState.hasText()) {
                                                setMsg(draftToHtml(convertToRaw(e.getCurrentContent())))
                                            } else {
                                                setMsg('')
                                            }
                                        }}
                                        toolbar={{
                                            options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'link', 'remove', 'history'],
                                            inline: {
                                                options: ['bold', 'italic', 'underline']
                                            },
                                            list: {
                                                options: ['unordered', 'ordered']
                                            },
                                            blockType: {
                                                options: ['Normal', 'H1', 'H2', 'H3']
                                            },
                                            link: {
                                                popupClassName: 'msg-editor-link-and-img',
                                                showOpenOptionOnHover: false
                                            },
                                            fontSize: {
                                                options: [12, 14, 16, 18, 24, 36]
                                            }
                                        }}
                                        editorClassName='msg-editor'
                                        stripPastedStyles={true}
                                    />
                                </Col>
                                <Col xs={12} lg={12} className="d-flex justify-content-between">
                                    <div className="mt-1">
                                        <InputGroup>
                                            <InputGroupAddon>
                                                <InputGroupText style={{padding: '0.438rem 0.5rem'}}>
                                                    {questate.inQueFilePreview && (
                                                        <Fragment>
                                                            {selectedFile?.type === "application/pdf" ? (
                                                                <span style={{marginRight: '5px'}}><FileText size="20"/></span>
                                                            ) : (
                                                                <img src={questate.inQueFilePreview} height="20" width="20" className="img-thumbnail" style={{marginRight: '5px'}}/>
                                                            )}
                                                        </Fragment>
                                                    )}
                                                    <Label className="attachment-icon mb-0" for="attach-doc">
                                                        <Paperclip className="cursor-pointer text-secondary" size={15}/>
                                                        <input type="file"
                                                               accept=".pdf, .png, .jpg, .jpeg, .doc, .docx, .xlsx, .xls"
                                                               id="attach-doc"
                                                               onChange={changeHandler}
                                                               hidden={true}
                                                               disabled={sendMsgLoader}
                                                        />

                                                    </Label>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                    <div className="mt-1">
                                        <Button className="mr-1"
                                                id="send-message"
                                                color="success"
                                                disabled={sendMsgLoader || msg.length === 0}
                                                size="sm">
                                            {sendMsgLoader ? (
                                                <span><Spinner style={{height: '12px', width: '12px'}}/></span>
                                            ) : (
                                                <span>Send</span>
                                            )}
                                        </Button>
                                        <Button.Ripple className="mr-1"
                                                       color="primary"
                                                       id="save-template"
                                                       onClick={() => setIsOpenTempleteModal(true)}
                                                       disabled={sendMsgLoader || msg.length === 0}
                                                       size="sm">
                                            Save
                                        </Button.Ripple>
                                        <UncontrolledButtonDropdown direction="top" id="templates">
                                            <DropdownToggle color="primary" style={{padding: '0.486rem 1rem', borderRadius: '0.358rem', fontSize: '0.9rem'}}>
                                                Templates
                                            </DropdownToggle>
                                            {brandChatStoreSlice?.brandMessageTempletes?.data && (
                                                <DropdownMenu className='msg-template-dropdown'>
                                                    {brandChatStoreSlice?.brandMessageTempletes?.data.map((item, index) => {
                                                        return (
                                                            <DropdownItem onClick={() => appendTemplete(item)} key={index}>
                                                                {++index} - {item?.title?.length > 30 ? `${item?.title?.substring(0, 30)}...` : item?.title}
                                                            </DropdownItem>)
                                                    })}
                                                </DropdownMenu>
                                            )}
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>

            <Modal isOpen={isOpenTempleteModal} className='modal-dialog-centered'>
                <ModalHeader>Message Template</ModalHeader>
                <ModalBody>
                    <FormGroup className='mb-2'>
                        <Label htmlFor='coupon-code'>Title</Label>
                        <Input type='text' placeholder='Templete Title' id='title'
                               name="title"
                               value={templeteTitle}
                               onChange={e => handleTitle(e)}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' outline onClick={() => setIsOpenTempleteModal(!isOpenTempleteModal)}>
                        Cancel
                    </Button>
                    <Button color='primary' onClick={() => handleMessageTemplete()}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={isOpenBroadcastModal} size='xl'>
                <ModalHeader>Send a mass message</ModalHeader>
                <ModalBody>
                    {massMsgTempSaveData?.success && (
                        <Alert color="success">
                            <div className='alert-body'>{massMsgTempSaveData?.msg}</div>
                        </Alert>
                    )}
                    <FormGroup className='mb-2'>
                        <div className="d-flex justify-content-between flex-wrap">
                            <Label htmlFor='coupon-code'>Write a Message</Label>
                        </div>
                        <Editor editorState={massMsgEditorState} onEditorStateChange={(e) => {
                            setMassMsgEditorState(e)
                            const contentState = e.getCurrentContent()
                            if (contentState.hasText()) {
                                setBroadcastMessage(draftToHtml(convertToRaw(e.getCurrentContent())))
                            } else {
                                setBroadcastMessage('')
                            }
                        }}
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'link', 'image', 'remove', 'history'],
                                    inline: {
                                        options: ['bold', 'italic', 'underline']
                                    },
                                    list: {
                                        options: ['unordered', 'ordered']
                                    },
                                    blockType: {
                                        options: ['Normal', 'H1', 'H2', 'H3']
                                    },
                                    link: {
                                        popupClassName: 'msg-editor-link-and-img',
                                        showOpenOptionOnHover: false
                                    },
                                    image: {
                                        popupClassName: 'msg-editor-link-and-img',
                                        defaultSize: {
                                            height: '500',
                                            width: '500'
                                        }
                                    },
                                    fontSize: {
                                        options: [12, 14, 16, 18, 24, 36]
                                    }
                                }}
                                editorClassName='msg-editor'
                                stripPastedStyles={true}
                        />
                    </FormGroup>
                    <FormGroup className='mb-2'>
                        <div className='text-right border-bottom pb-1'>
                            <button className='btn btn-primary mr-1 btn-sm' onClick={() => {
                                setOpenMassTempModal(true)
                                saveMassTempForm.setFieldValue('message', broadcastMessage)
                            }} disabled={broadcastMessage.length === 0}>
                                Save Templates
                            </button>
                            <button className='btn btn-success btn-sm' onClick={() => setOpenMassTempLoadModal(true)}>
                                View Templates
                            </button>
                        </div>
                    </FormGroup>
                    <FormGroup className='border-bottom pb-1'>
                        <Row>
                            <Col xs={12} lg={2}>
                                {broadCastRDsList.length > 0 && showGroups === false && (
                                    <CustomInput
                                        key="3"
                                        type='checkbox'
                                        id="check-all"
                                        label="Select All"
                                        name="check_all"
                                        onChange={handleCheckAll}
                                        checked={checkAllVal}
                                    />
                                )}
                            </Col>
                            <Col xs={12} lg={2}>
                                <CustomInput
                                    key="1"
                                    type='radio'
                                    id="my-rds"
                                    label="My RDs"
                                    onChange={() => getRdList('my_rds')}
                                    className="mr-2"
                                    name="selectRDs"
                                    checked={selectedRadio === 'my_rds'}
                                    disabled={broadCastRDsListLoading}
                                />
                            </Col>
                            <Col xs={12} lg={2}>
                                <CustomInput
                                    key="2"
                                    type='radio'
                                    id="all-rds"
                                    label="All RDs"
                                    onChange={() => getRdList('all_rds')}
                                    className="mr-2"
                                    name="selectRDs"
                                    checked={selectedRadio === 'all_rds'}
                                    disabled={broadCastRDsListLoading}
                                />
                            </Col>
                            <Col xs={12} lg={2}>
                                <CustomInput
                                    key="3"
                                    type='radio'
                                    id="from-favourites"
                                    label="From Favorites"
                                    onChange={() => getRdList('favourites')}
                                    name="selectRDs"
                                    checked={selectedRadio === 'favourites'}
                                    disabled={broadCastRDsListLoading}
                                />
                            </Col>
                            <Col xs={12} lg={2}>
                                <CustomInput
                                    key="3"
                                    type='radio'
                                    id="groups"
                                    label="Filter"
                                    onChange={() => getRdList('groups')}
                                    name="selectRDs"
                                    checked={selectedRadio === 'groups'}
                                    disabled={broadCastRDsListLoading}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    {showFav && (
                        <div className="p-1 bg-light">
                            <span>
                                <strong>Select Folder</strong>
                            </span>
                        </div>
                    )}
                    {backToFav && (
                        <div className="p-1 bg-light d-flex justify-content-between">
                            <span className="text-primary d-flex align-items-center cursor-pointer ml-1" onClick={() => getRdList('favourites')}>
                                {!broadCastRDsListLoading && (
                                    <>
                                        <ArrowLeftCircle size={15}/> <strong style={{marginLeft: '3px'}}>Back</strong>
                                    </>
                                )}
                            </span>
                            <span><strong>{favFolderTitle}</strong></span>
                        </div>
                    )}
                    <div className="rds-list-message-modal">
                        <Row>
                            {showGroups && (
                                <>
                                    <Col xs={12} md={4}>
                                        <FormGroup>
                                            <h6 className='filter-title bg-light p-1 font-weight-bold'>Speciality:</h6>
                                            <div style={{height: '250px', overflow: 'auto'}}>
                                                {brandsDashboardStoreSlice?.goalsAttributes?.speciality?.map((brand, index) => (
                                                    <CustomInput key={index}
                                                                 type='checkbox'
                                                                 className="mx-2 mb-1"
                                                                 name={`speciality-${index}`}
                                                                 id={`speciality-${index}`}
                                                                 label={brand.label}
                                                                 onChange={(e) => handleGroupChange('speciality', e, brand)}
                                                                 disabled={rdGroupListLoading}
                                                    />
                                                ))}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <FormGroup>
                                            <h6 className='filter-title bg-light p-1 font-weight-bold'>Industry:</h6>
                                            <div style={{height: '250px', overflow: 'auto'}}>
                                                {brandsDashboardStoreSlice?.goalsAttributes?.industry?.map((brand, index) => (
                                                    <CustomInput key={index}
                                                                 type='checkbox'
                                                                 className="mx-2 mb-1"
                                                                 name={`industry-${index}`}
                                                                 id={`industry-${index}`}
                                                                 label={brand.label}
                                                                 onChange={(e) => handleGroupChange('industry', e, brand)}
                                                                 disabled={rdGroupListLoading}
                                                    />
                                                ))}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <FormGroup>
                                            <h6 className='filter-title bg-light p-1 font-weight-bold'>Personal Characteristics:</h6>
                                            <div style={{height: '250px', overflow: 'auto'}}>
                                                {brandsDashboardStoreSlice?.goalsAttributes?.personal_attributes?.map((brand, index) => (
                                                    <CustomInput key={index}
                                                                 type='checkbox'
                                                                 className="mx-2 mb-1"
                                                                 name={`personal-characteristics-${index}`}
                                                                 id={`personal-characteristics-${index}`}
                                                                 label={brand.label}
                                                                 onChange={(e) => handleGroupChange('characteristics', e, brand)}
                                                                 disabled={rdGroupListLoading}
                                                    />
                                                ))}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {broadCastRDsList.length > 0 && (
                                        <Col md={12}>
                                            <div className='bg-light p-1 mt-2 mb-2'>
                                                <strong>Total RD's Found: {broadCastRDsList.length}</strong>
                                            </div>
                                        </Col>
                                    )}
                                </>
                            )}
                            {(broadCastRDsListLoading || rdGroupListLoading) &&
                                <Col md={12} className="text-center">
                                    <Spinner color="primary" style={{width: '16px', height: '16px'}}/> Please wait...
                                </Col>
                            }
                            {!broadCastRDsListLoading && broadCastRDsList?.map((item, index) => (
                                <Col className="col-md-3" key={index}>
                                    <Card className={classnames({
                                        'ecommerce-card': true,
                                        'mb-1': true,
                                        'border-1px-white': (!selectedBroadcastContacts?.includes(item.user_id)),
                                        'cursor-pointer': true,
                                        'border-1px-purple': selectedBroadcastContacts?.includes(item.user_id)
                                    })} key={item.user_id} onClick={() => handleSelectBroadCastingContact(item)}>
                                        <CardBody className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <CustomInput id={`user-checkbox-${index}`}
                                                             type='checkbox'
                                                             checked={selectedBroadcastContacts?.includes(item.user_id)}
                                                             className="mr-1"
                                                             disabled={true}
                                                />
                                                <Avatar img={item.profile_img || BlankProfile}/>
                                                <div>
                                                    <h5 className='mb-0 ml-1 text-break'> {item.name}</h5>
                                                    {item.is_favorite &&
                                                        <p className='mb-0 ml-1 folder-name-at-broadcast-rds'>
                                                            {item?.folder_title?.length > 10 ? ` ${item?.folder_title.substring(0, 10)}...` : item?.folder_title}
                                                        </p>}
                                                </div>
                                            </div>
                                            {item.is_favorite && <Heart color="red" size={16}/>}
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                            {favFolderList && favFolderList.map((item, index) => (
                                <Col xs={12} md={3}>
                                    <Card key={index} className="cursor-pointer" onClick={() => handleSelectedFolder(item.id, item.folder_name)}>
                                        <CardBody className=" fav-folders">
                                            <div className="align-items-center">
                                                <h5 className='mb-0'>
                                                    {item?.folder_name.length > 20 ? `${item?.folder_name.substring(0, 20)}...` : item?.folder_name}
                                                </h5>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        {broadCastRDsListLoading === false && broadCastRDsList.length === 0 && showFav === false && showGroups === false && (
                            <div className="text-center">
                                <strong>Sorry! No data found.</strong>
                            </div>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' outline onClick={() => {
                        setIsOpenBroadcastModal(!isOpenBroadcastModal)
                        setSelectedBraodcastContacts([])
                        setCheckAllVal(false)
                        setBroadCastRDsList([])
                        setMassMsgEditorState(null)
                        setBroadcastMessage('')
                        saveMassTempForm.resetForm()
                        dispatch({type: 'CLEAT_BROADCAST_LIST'})
                    }}>
                        Cancel
                    </Button>
                    <Button color='primary' disabled={selectedBroadcastContacts.length === 0 || broadcastMessage === ''}
                            onClick={() => {
                                setShowPreviewModal(true)
                            }}>
                        Send
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Mass messages preview modal*/}
            <Modal isOpen={showPreviewModal} className='modal-lg'>
                <ModalHeader>Confirmation</ModalHeader>
                <ModalBody>
                    <Alert color="success" isOpen={broadCastResStatus}>
                        <div className='alert-body'>
                            {broadCastResMsg}
                        </div>
                    </Alert>

                    <FormGroup className='mb-2'>
                        <Label htmlFor='coupon-code'><strong>Message</strong></Label>
                        <div dangerouslySetInnerHTML={{__html: broadcastMessage}} style={{minHeight: '150px'}}
                             className="bg-light p-1 border text-break"></div>
                    </FormGroup>
                    <FormGroup className='mb-2'>
                        <Label htmlFor='coupon-code'><strong>To ({selectedRds.length})</strong></Label>
                        <div className="rds-list-message-modal">
                            {selectedRds.map((name) => (
                                <span className="badge badge-primary mb-1 mr-1">{name}</span>
                            ))}
                        </div>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' outline onClick={() => setShowPreviewModal(false)}
                            disabled={broadCastSendLoader}>
                        Cancel
                    </Button>
                    <Button color='primary' onClick={() => sendBroadcastMessage()}
                            disabled={broadCastSendLoader || broadCastResStatus}>
                        {broadCastSendLoader ? <Spinner style={{height: '14px', width: '14px'}}/> : "Send"}
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Mass messages Save Template Modal */}
            <Modal isOpen={openMassTempModal} className='modal-lg'>
                <ModalHeader>Save Mass Message Template</ModalHeader>
                <Form onSubmit={saveMassTempForm.handleSubmit} autoComplete="off">
                    <ModalBody>
                        <FormGroup className='mb-2'>
                            <Label htmlFor='massMsgTempTitle'><strong>Title</strong></Label>
                            <Input type="text"
                                   placeholder='Title'
                                   id="massMsgTempTitle"
                                   name="title"
                                   value={saveMassTempForm.values.title}
                                   onBlur={saveMassTempForm.handleBlur}
                                   onChange={saveMassTempForm.handleChange}
                                   disabled={massMsgTempSaveLoading}
                            />
                            <div className="validation-err">
                                {saveMassTempForm.touched.title && saveMassTempForm.errors.title}
                            </div>
                        </FormGroup>
                        <FormGroup className='mb-2'>
                            <Label htmlFor='massMsgTempMsg'><strong>Message</strong></Label>
                            <div className='bg-light p-1 border'
                                 dangerouslySetInnerHTML={{__html: saveMassTempForm.values.message}}></div>
                            <div className="validation-err">
                                {saveMassTempForm.touched.message && saveMassTempForm.errors.message}
                            </div>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-between'>
                        <Button color='danger' outline onClick={() => {
                            setOpenMassTempModal(false)
                            saveMassTempForm.resetForm()
                        }} disabled={massMsgTempSaveLoading}>
                            Cancel
                        </Button>
                        <Button color='primary' type='submit' disabled={massMsgTempSaveLoading}>
                            {massMsgTempSaveLoading ? (
                                <Spinner style={{height: '14px', width: '14px'}}/>
                            ) : (
                                <span>Save</span>
                            )}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>

            {/* Mass messages Load Template Modal */}
            <Modal isOpen={openMassTempLoadModal} className='modal-lg'>
                <ModalHeader toggle={() => {
                    setOpenMassTempLoadModal(false)
                }}>
                    Mass Message Templates
                </ModalHeader>
                <ModalBody>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {massMsgTempListLoading ? (
                            <tr>
                                <td colSpan='3' className='text-center'><strong>Please wait...</strong></td>
                            </tr>
                        ) : (
                            massMsgTempListData?.data?.length > 0 ? (
                                massMsgTempListData?.data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item?.title?.length > 20 ? `${item?.title?.substring(0, 20)}...` : item?.title}</td>
                                        <td>
                                            <button className='btn btn-primary btn-sm mr-1' onClick={() => {
                                                const content = htmlToDraft(item.template)
                                                const contentState = ContentState.createFromBlockArray(content?.contentBlocks)
                                                setMassMsgEditorState(EditorState.createWithContent(contentState))
                                                setBroadcastMessage(item.template)
                                                setOpenMassTempLoadModal(false)
                                            }} disabled={massMsgTempDeleteLoading}>
                                                <ArrowLeftCircle size={14}/> Apply
                                            </button>
                                            <button className='btn btn-danger btn-sm' onClick={() => {
                                                deleteMassMsgTemp(item)
                                            }} disabled={massMsgTempDeleteLoading}>
                                                <XCircle size={14}/> Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan='3' className='text-center'><strong>No template.</strong></td>
                                </tr>
                            )
                        )}
                        </tbody>
                    </Table>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default memo(ChatLog)