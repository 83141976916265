import { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { sendMsg } from './store/actions'
import Avatar from '@components/avatar'
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { MessageSquare, Menu } from 'react-feather'
import BlankLogo from "@src/assets/images/avatars/Blank-logo.jpg"
import BlankUser from "@src/assets/images/avatars/avatar-blank.png"
import Linkify from "react-linkify"
import {Link} from "react-router-dom"

const ChatLog = props => {
  const { handleUser, handleUserSidebarRight, handleSidebar, store, userSidebarLeft } = props
  const { userProfile, selectedUser } = store

  const chatArea = useRef(null)
  const dispatch = useDispatch()
  const chatSelector = useSelector(store => store.chatAdmin)

  const [msg, setMsg] = useState('')

  const scrollToBottom = () => {
    const chatContainer = ReactDOM.findDOMNode(chatArea.current)
    chatContainer.scrollTop = Number.MAX_SAFE_INTEGER
  }

  useEffect(() => {
    const selectedUserLen = Object.keys(chatSelector?.singleUserChatDetail).length
    if (selectedUserLen) {
      scrollToBottom()
    }
  }, [chatSelector])

  const renderChats = () => {
    const seletedBrandFromSelect = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_SELECTED_BRAND_USER_FOR_ADMIN_CHAT}`))
    console.log("seletedBrandFromSelect")
    console.log(seletedBrandFromSelect)
    return chatSelector?.singleUserChatDetail.map((item, index) => {
      return (
        <div
          key={index}
          className={classnames('chat', {
            'chat-left': item.from_user_id === seletedBrandFromSelect?.value
          })}
        >
          <div className='chat-avatar'>
          {item.from_user_id === seletedBrandFromSelect?.value && (
              <Link to={`/admin/manage-brands/${item.from_user_id}`}>
                <Avatar className='box-shadow-1 cursor-pointer' img={item.profile_pic || BlankUser}/>
              </Link>
          )}
            {item.from_user_id !== seletedBrandFromSelect?.value && (
                <Link to={`/admin/manage-rd/${item.from_user_id}`}>
                  <Avatar className='box-shadow-1 cursor-pointer' img={item.profile_pic || BlankLogo}/>
                </Link>
            )}
          </div>

          {item.media ? (
            <div className="chat-body">
              <div className="chat-content">
                <p><a className="font-weight-bolder text-dark" href={item.media} target='_blank'>{item.file_name}</a></p>
              </div>
            </div>

          ) : (
            <div className="chat-body">
              <div className="chat-content text-break" style={{whiteSpace: "break-spaces", textAlign: "justify"}}>
                <div dangerouslySetInnerHTML={{__html: item.message}}></div>
                <p className="child" style={{marginTop: "10px", textAlign: "right"}}>
                  {item.timezone}
                </p>
              </div>
            </div>
          )}
        </div>
      )
    })
  }

  // ** Opens right sidebar & handles its data
  const handleAvatarClick = obj => {
    handleUserSidebarRight()
    handleUser(obj)
  }

  // ** On mobile screen open left sidebar on Start Conversation Click
  const handleStartConversation = () => {
    if (!Object.keys(chatSelector?.singleUserChatDetail).length && !userSidebarLeft && window.innerWidth <= 1200) {
      handleSidebar()
    }
  }

  const handleSendMsg = e => {
    e.preventDefault()
    if (msg.length) {
      dispatch(sendMsg({ ...chatSelector?.singleUserChatDetail, message: msg }))
      setMsg('')
    }
  }

  const ChatWrapper = Object.keys(chatSelector?.singleUserChatDetail).length && chatSelector?.singleUserChatDetail ? PerfectScrollbar : 'div'

  return (
    <div className='chat-app-window w-100'>
      <div className={classnames('start-chat-area', { 'd-none': Object.keys(chatSelector?.singleUserChatDetail).length })}>
        <div className='start-chat-icon mb-1'>
          <MessageSquare />
        </div>
        <h4 className='sidebar-toggle start-chat-text' onClick={handleStartConversation}>
          Start Conversation
        </h4>
      </div>
      {Object.keys(chatSelector?.singleUserChatDetail).length ? (
        <div className={classnames('active-chat', { 'd-none': chatSelector?.singleUserChatDetail === null })}>
          <div className='chat-navbar'>
            <header className='chat-header'>
              <div className='d-flex align-items-center'>
                <div className='sidebar-toggle d-block d-lg-none mr-1' onClick={handleSidebar}>
                  <Menu size={21} />
                </div>
                <h6 className='mb-0'>{chatSelector?.singleUserChatDetail.name}</h6>
              </div>
            </header>
          </div>

          <ChatWrapper ref={chatArea} className='user-chats' options={{ wheelPropagation: false }}>
            {chatSelector?.singleUserChatDetail ? <div className='chats'>{renderChats()}</div> : null}
          </ChatWrapper>
        </div>
      ) : null}
    </div>
  )
}

export default ChatLog
