import {useState, Fragment, useEffect} from 'react'
import ProductCards from './ProductCards'
import ProductsSearchbar from './ProductsSearchbar'
import classnames from 'classnames'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import Flatpickr from 'react-flatpickr'
import {Filter, Folder, X} from 'react-feather'
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    FormGroup,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Label
} from 'reactstrap'
import Select from 'react-select'
import {useDispatch, useSelector} from 'react-redux'
import {
    getOpportunitiesListing,
    searchOpportunities
} from '@src/views/brands/myCampaigns/store/actions'
import {
    getFavoriteFolders,
    nullResponse,
    createFavoriteFolder,
    addToFavorite,
    getCountries,
    getRegionsByCountryId,
    getStatesByRegionId,
    getCitiesByStateId
} from '@src/redux/actions/brandsDashboard'
import {NULL} from '@src/redux/actionTypes'
import queryString from 'query-string'
import {getEventTypes, getAudienceType} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import {useHistory} from "react-router-dom"

const RdOpportunities = () => {
    const history = useHistory()
    const [paginationInfo, setPaginationInfo] = useState(null)
    const [isSelectFoldersModalOpen, setIsSelectFoldersModalOpen] = useState(false)
    const [isFoldersLoading, setIsFoldersLoading] = useState(false)
    const [folderName, setFolderName] = useState('')
    const [folderNameError, setFolderNameError] = useState(false)
    const [isFolderCreating, setIsFolderCreating] = useState(false)
    const [isAddingToFavorite, setIsAddingToFavorite] = useState(false)
    const [selectedProfile, setSelectedProfile] = useState(null)
    const [selectedFolder, setSelectedFolder] = useState(null)
    const [isListLoading, setIsListLoading] = useState(false)

    const [showFilters, setShowFilters] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [industryValue, setIndustryValue] = useState('')
    const [specialityValues, setSpecialityValues] = useState([])
    const [personalAttrValues, setPersonalAttrValues] = useState([])
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const [countryOptions, setCountryOptions] = useState(null)
    const [regionOptions, setRegionOptions] = useState(null)
    const [stateOptions, setStateOptions] = useState(null)
    const [cityOptions, setCityOptions] = useState(null)
    const [eventTypeOptions, setEventTypeOptions] = useState(null)
    const [audienceTypeOptions, setAudienceTypeOptions] = useState(null)

    const [isRegionsLoading, setIsRegionsLoading] = useState(false)
    const [isStatesLoading, setIsStatesLoading] = useState(false)
    const [isCitiesLoading, setIsCitiesLoading] = useState(false)
    const [isEventTypeLoading, setIsEventTypeLoading] = useState(false)
    const [isAudienceTypeLoading, setIsAudienceTypeLoading] = useState(false)

    const [selectedCountry, setSelectedCountry] = useState(null)
    const [selectedRegion, setSelectedRegion] = useState(null)
    const [selectedState, setSelectedState] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)
    const [selectedEventType, setSelectedEventType] = useState(null)
    const [selectedAudienceType, setSelectedAudienceType] = useState(null)

    const opportunitySlice = useSelector(state => state.brandsOppoprunities)
    const brandsDashboardStoreSlice = useSelector(store => store.brandsDashboardSlice)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!!opportunitySlice?.opportunitiesData) {
            setIsListLoading(false)
            const {links, meta, pages} = opportunitySlice.opportunitiesData
            setPaginationInfo({...links, ...meta, pages})
        }

        if (!!brandsDashboardStoreSlice?.addToFavroiteRes) {
            setIsAddingToFavorite(false)
            setIsListLoading(true)
            setIsSelectFoldersModalOpen(false)
            dispatch(nullResponse(NULL.BRANDS_NUTRITION_EXPERTS))
            dispatch(getOpportunitiesListing(false))
            dispatch(nullResponse(NULL.BRANDS_ADD_TO_FAVORITE))
        }
        if (!!brandsDashboardStoreSlice?.favoriteFoldersRes) {
            setIsFoldersLoading(false)
        }
        if (!!brandsDashboardStoreSlice?.createfavoriteFolderRes) {
            setFolderName('')
            setIsFolderCreating(false)
            dispatch(nullResponse(NULL.BRANDS_GET_FOLDERS))
            dispatch(nullResponse(NULL.BRANDS_CREATE_FOLDER))
            dispatch(getFavoriteFolders(false))
            setIsFoldersLoading(true)
        }

        if (!!brandsDashboardStoreSlice?.countries) {
            setCountryOptions(brandsDashboardStoreSlice.countries)
        }

        if (!!brandsDashboardStoreSlice?.regionsByCountryID) {
            setRegionOptions(brandsDashboardStoreSlice?.regionsByCountryID)
            setIsRegionsLoading(false)
        }

        if (!!brandsDashboardStoreSlice?.statesByRegionID) {
            setStateOptions(brandsDashboardStoreSlice?.statesByRegionID)
            setIsStatesLoading(false)
        }

        if (!!brandsDashboardStoreSlice?.citiesByStateID) {
            setCityOptions(brandsDashboardStoreSlice?.citiesByStateID)
            setIsCitiesLoading(false)
        }

        if (!!opportunitySlice?.eventTypes) {
            setEventTypeOptions(opportunitySlice?.eventTypes)
            setIsEventTypeLoading(false)
        }

        if (!!opportunitySlice?.audienceType) {
            setAudienceTypeOptions(opportunitySlice?.audienceType)
            setIsAudienceTypeLoading(false)
        }

    }, [opportunitySlice, brandsDashboardStoreSlice])

    useEffect(() => {
        setIsListLoading(true)
        const parsed = queryString.parseUrl(window.location.href)
        const pageNo = parsed?.query?.page
        const API_URL = process.env.REACT_APP_BASEURL
        const path = `${API_URL}brand/upcoming-events`
        pageNo ? dispatch(getOpportunitiesListing(true, `${path}?page=${pageNo}`)) : dispatch(getOpportunitiesListing(false))
        dispatch(getFavoriteFolders(false))
        dispatch(getCountries())
        dispatch(getEventTypes())
        dispatch(getAudienceType())
        dispatch(nullResponse(NULL.BRANDS_REGIONS_BY_COUNTRY_ID))
        dispatch(nullResponse(NULL.BRANDS_STATES_BY_REGIONS_ID))
        dispatch(nullResponse(NULL.BRANDS_CITIES_BY_STATES_ID))
    }, [])


    const handleCustomPagination = pageUrl => {
        const fromDate = new Date(startDate)
        const finalFromDate = `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`
        const toDate = new Date(endDate)
        const finalToDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`
        setIsListLoading(true)
        dispatch(searchOpportunities(true, {
            from_date: finalFromDate === "NaN-NaN-NaN" ? "" : finalFromDate,
            to_date: finalToDate === "NaN-NaN-NaN" ? "" : finalToDate,
            country: selectedCountry?.id || null,
            region: selectedRegion?.id || null,
            state: selectedState?.id || null,
            city: selectedCity?.id || null,
            event_type: selectedEventType?.value || null,
            audience_type: selectedAudienceType?.value || null,
            name: searchValue
        }, pageUrl))
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        history.push({
            pathname: window.location.pathname,
            search: `?page=${pageNo}`
        })
    }

    const handleSelectFolder = (folder) => {
        setSelectedFolder(folder)
    }

    const handleCreateFolder = () => {
        if (!folderName) {
            setFolderNameError(true)
            return
        }
        setFolderNameError(false)
        setIsFolderCreating(true)
        dispatch(createFavoriteFolder({folderName}))
    }

    const getAddToFavoriteProfile = (profile) => {
        setSelectedProfile(profile)
        if (!profile.is_favorite) setIsSelectFoldersModalOpen(true)
        if (profile.is_favorite) {
            setIsListLoading(true)
            dispatch(addToFavorite({id: profile.id, folder_id: profile.folder_id, type: 'event'}))
        }
    }

    const handleAddToFavorite = () => {
        setIsAddingToFavorite(true)
        dispatch(addToFavorite({id: selectedProfile?.id, folder_id: selectedFolder?.id, type: 'event'}))
    }

    const updateLocation = (location, locationValue) => {
        switch (location) {
            case 'Regions':
                dispatch(getRegionsByCountryId(locationValue))
                break
            case 'States':
                dispatch(getStatesByRegionId(locationValue))
                break
            case 'Cities':
                dispatch(getCitiesByStateId(locationValue))
                break
        }
    }

    const handleSelect = (select, value) => {
        switch (select) {
            case 'CountrySelect':
                setIsRegionsLoading(true)
                updateLocation('Regions', value)
                setSelectedCountry(value)
                break
            case 'RegionSelect':
                setIsStatesLoading(true)
                updateLocation('States', value)
                setSelectedRegion(value)
                break
            case 'StateSelect':
                setIsCitiesLoading(true)
                updateLocation('Cities', value)
                setSelectedState(value)
                break
            case 'CitySelect':
                setSelectedCity(value)
                break
        }
    }

    const handleFiltersSearch = () => {
        const fromDate = new Date(startDate)
        const finalFromDate = `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`
        const toDate = new Date(endDate)
        const finalToDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`

        setIsListLoading(true)
        dispatch(searchOpportunities(false, {
            from_date: finalFromDate === "NaN-NaN-NaN" ? "" : finalFromDate,
            to_date: finalToDate === "NaN-NaN-NaN" ? "" : finalToDate,
            country: selectedCountry?.id || null,
            region: selectedRegion?.id || null,
            state: selectedState?.id || null,
            city: selectedCity?.id || null,
            event_type: selectedEventType?.value || null,
            audience_type: selectedAudienceType?.value || null,
            name: searchValue
        }))
    }

    const handleSearchByName = (searchValue) => {
        setIsListLoading(true)
        setSearchValue(searchValue)
        dispatch(searchOpportunities(false, {
            from_date: "",
            to_date: "",
            country: "",
            region: "",
            state: "",
            city: "",
            event_type: "",
            audience_type: "",
            name: searchValue
        }))
    }

    const handleResetSearch = () => {
        setIsListLoading(true)
        setSearchValue('')
        setStartDate("")
        setEndDate("")
        setSelectedCountry(null)
        setSelectedRegion(null)
        setSelectedState(null)
        setSelectedCity(null)
        setSelectedEventType(null)
        setSelectedAudienceType(null)
        dispatch(getOpportunitiesListing())
    }

    return (
        <Fragment>
            <div className='content-detached content-right'>
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className='filter-heading'>All Opportunities</h4>
                    <Button.Ripple className='btn-icon' outline color='primary' onClick={() => setShowFilters(!showFilters)}>
                        <Filter size={16}/>
                    </Button.Ripple>
                </div>
                {showFilters &&
                    <Card className='my-0 filters-right-sidebar'>
                        <CardHeader className="card-header-c">
                            <h3 className='filter-heading my-0'>Filters</h3>
                            <Button.Ripple className='btn-icon' color='danger' onClick={() => setShowFilters(false)}>
                                <X size={16}/>
                            </Button.Ripple>
                        </CardHeader>
                        <CardBody className="pt-2 overflow-y-auto">
                            <FormGroup>
                                <h6 className='filter-title mt-1'>Start Date</h6>
                                <Flatpickr
                                    value={startDate}
                                    id='range-picker'
                                    className='form-control'
                                    onChange={date => setStartDate(date)}

                                />
                            </FormGroup>
                            <FormGroup>
                                <h6 className='filter-title mt-1'>End Date</h6>
                                <Flatpickr
                                    value={endDate}
                                    id='range-picker'
                                    className='form-control'
                                    onChange={date => setEndDate(date)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <h6 className='filter-title mt-1'>Country</h6>
                                <Select
                                    options={countryOptions}
                                    className='react-select'
                                    classNamePrefix='select'
                                    value={selectedCountry}
                                    onChange={(value) => handleSelect("CountrySelect", value)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <h6 className='filter-title mt-1'>Region</h6>
                                <div className="overlay-select-parent">
                                    {isRegionsLoading && <div className="overlay-select"></div>}
                                    <Select
                                        options={regionOptions}
                                        className='react-select'
                                        classNamePrefix='select'
                                        value={selectedRegion}
                                        onChange={(value) => handleSelect("RegionSelect", value)}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <h6 className='filter-title mt-1'>State</h6>
                                <div className="overlay-select-parent">
                                    {isStatesLoading && <div className="overlay-select"></div>}
                                    <Select
                                        options={stateOptions}
                                        className='react-select'
                                        classNamePrefix='select'
                                        value={selectedState}
                                        onChange={(value) => handleSelect("StateSelect", value)}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <h6 className='filter-title mt-1'>City</h6>
                                <div className="overlay-select-parent">
                                    {isCitiesLoading && <div className="overlay-select"></div>}
                                    <Select
                                        options={cityOptions}
                                        className='react-select'
                                        classNamePrefix='select'
                                        value={selectedCity}
                                        onChange={(value) => handleSelect("CitySelect", value)}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <h6 className='filter-title mt-1'>Type of Event</h6>
                                <div className="overlay-select-parent">
                                    {isEventTypeLoading && <div className="overlay-select"></div>}
                                    <Select
                                        options={eventTypeOptions}
                                        className='react-select'
                                        classNamePrefix='select'
                                        value={selectedEventType}
                                        onChange={(value) => setSelectedEventType(value)}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <h6 className='filter-title mt-1'>Type of Audience</h6>
                                <div className="overlay-select-parent">
                                    {isAudienceTypeLoading && <div className="overlay-select"></div>}
                                    <Select
                                        options={audienceTypeOptions}
                                        className='react-select'
                                        classNamePrefix='select'
                                        value={selectedAudienceType}
                                        onChange={(value) => setSelectedAudienceType(value)}
                                    />
                                </div>
                            </FormGroup>
                        </CardBody>
                        <CardFooter>
                            <Button.Ripple color='primary' className="mb-1" onClick={handleFiltersSearch} block>
                                Apply Filters
                            </Button.Ripple>
                            <Button.Ripple color='danger' outline onClick={handleResetSearch} block>
                                Clear All Filters
                            </Button.Ripple>
                        </CardFooter>
                    </Card>
                }
                <ProductsSearchbar handleSearch={handleSearchByName} handleReset={handleResetSearch} searchValue={searchValue}/>

                {isListLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!isListLoading && (
                    <Fragment>
                        <ProductCards
                            opportunitySlice={opportunitySlice}
                            handleAddToFavorite={handleAddToFavorite}
                            handleAddToFavorite={getAddToFavoriteProfile}
                        />
                        <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
                    </Fragment>
                )}

                {!isListLoading && opportunitySlice?.opportunitiesData?.data?.length < 1 && (
                    <div className='d-flex justify-content-center mt-2'>
                        <p>Record doesn't exists</p>
                    </div>)}
            </div>

            <Modal scrollable isOpen={isSelectFoldersModalOpen} className='modal-dialog-centered'>
                <ModalHeader>Select Folder</ModalHeader>
                <ModalBody className="py-2" style={{maxHeight: '50vh', overflowY: 'auto'}}>
                    {isFoldersLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                    {!isFoldersLoading && (
                        <Fragment>
                            {brandsDashboardStoreSlice?.favoriteFoldersRes?.data.map(item => (
                                <Card className={classnames({
                                    'ecommerce-card': true,
                                    'mb-1': true,
                                    'border-1px-white': selectedFolder?.id !== item.id,
                                    'cursor-pointer': true,
                                    'border-1px-purple': selectedFolder?.id === item.id
                                })} key={item.folder_name} onClick={() => handleSelectFolder(item)}>
                                    <CardBody className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <Folder
                                                className='mr-1 text-secondary'
                                                size={24}
                                            />
                                            <h5 className='mb-0'>{item.folder_name}</h5>
                                        </div>
                                    </CardBody>
                                </Card>
                            ))}
                        </Fragment>
                    )}

                    <p className="text-center">or</p>
                    <div className='form-group'>
                        <Label className={classnames({
                            'form-label': true
                        })} htmlFor={`folderName`}>
                            Folder Name<span className="is-invalid">*</span>
                        </Label>
                        <Input
                            type='text'
                            onChange={(e) => {
                                setFolderName(e.target.value)
                                setFolderNameError(false)
                            }}
                            value={folderName}
                            name={`folderName`}
                            id={`folderName`}
                            className={classnames({'is-invalid': folderNameError})}
                        />
                        {folderNameError && <div className="validation-err">This field is required</div>}
                    </div>
                    <div className="text-center">
                        <Button color='primary' outline disabled={isFolderCreating} onClick={handleCreateFolder}>
                            {isFolderCreating ? 'Creating...' : 'Create Folder'}
                        </Button>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' hidden={isAddingToFavorite} onClick={() => setIsSelectFoldersModalOpen(!isSelectFoldersModalOpen)}>
                        Cancel
                    </Button>
                    <Button color='primary' disabled={isAddingToFavorite} onClick={() => handleAddToFavorite()}>
                        {isAddingToFavorite ? 'Adding...' : 'Add to Favorite'}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default RdOpportunities
