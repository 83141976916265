import { Fragment, useEffect, useState } from 'react'
// import Avatar from '@components/avatar'
import { getBrandDashboardNotifications, getBrandUnreadNotifications } from '../../../../../src/redux/actions/brandsDashboard/index'
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Bell } from 'react-feather'
import {
  Button,
  Badge,
  Media,
  Spinner,
  CustomInput,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

const NotificationDropdown = () => {
  const history = useHistory()

  const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))

  const [isLoading, setIsLoading] = useState(false)

  const brandsDashboardSlice = useSelector(store => store.brandsDashboardSlice)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!brandsDashboardSlice?.brandNotifications) {
      setIsLoading(false)
    }
  }, [brandsDashboardSlice])

  useEffect(() => {
    setIsLoading(false)
    //dispatch(getBrandUnreadNotifications())

    // setInterval(function () {
    //   dispatch(getBrandUnreadNotifications())
    // }, 300000)
    if (userData) {
      dispatch(getBrandUnreadNotifications())
      dispatch(getBrandDashboardNotifications())
    }
  }, [])

  const clickHandler = () => {
    setIsLoading(true)
    dispatch(getBrandDashboardNotifications())
    dispatch({type: "REMOVE_NOTIFICATION_COUNT_ON_CLICK_BRAND"})
  }

  const handleRedirectMessage = (user) => {
    localStorage.setItem('selectedChatRd', JSON.stringify({
      id: user.user_id,
      name: user.name,
      profile_img: user.profile_img
    }))
    window.location.href = `/brands/apps/chat/chatBrands/${user.user_id}`
  }

  const renderNotificationItems = () => {
    return (
      <PerfectScrollbar
        component='li'
        className='media-list scrollable-container'
        options={{
          wheelPropagation: false
        }}
      >
        {isLoading && (
          <div className="w-100 my-2 text-center">
            <Spinner color='primary' />
          </div>)}
        {!isLoading && brandsDashboardSlice?.brandNotifications?.data?.length === 0 && (
          <div className="w-100 my-2 text-center">
            <p>There is no new notification</p>
          </div>)}
        {!isLoading && brandsDashboardSlice?.brandNotifications?.data?.map((item, index) => {
          return (
            <Fragment key={index}>
              {item.type === 'message' &&
                <a key={index} className='d-flex' onClick={(e) => {
                  e.preventDefault()
                  handleRedirectMessage(item)
                }}>
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    <small className='text-black-50' dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Media>
                </a>
              }
              {item.type === 'request' &&
                <a key={index} className='d-flex' href={`/dashboard/brands/nutrition-detail/${item.event_id}`}>
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    <small className='text-black-50' dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Media>
                </a>
              }
              {item.type === 'invoice_sent' &&
                <a key={index} className='d-flex' href={`/dashboard/brands/nutrition-detail/${item.event_id}`} >
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    <small className='text-black-50' dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Media>
                </a>
              }
              {item.type === 'subscription' &&
                <a key={index} className='d-flex' href='/' onClick={e => e.preventDefault()}>
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    <small className='text-black-50' dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Media>
                </a>
              }
              {item.type === 'new_invoice_sent' &&
                  <a key={index} className='d-flex' href='/dashboard/brands/manage-partnerships'>
                    <Media
                        className={classnames('d-flex', {
                          'align-items-start': !item.switch,
                          'align-items-center': item.switch
                        })}
                    >
                      <small className='text-black-50' dangerouslySetInnerHTML={{ __html: item.message }} />
                    </Media>
                  </a>
              }
            </Fragment>
          )
        })}
      </PerfectScrollbar>
    )
  }

  return (
    <UncontrolledDropdown tag='li' className='dropdown-notification nav-item mr-25'>
      {/* <DropdownToggle tag='a' className='nav-link' href='/' onClick={e => e.preventDefault()}> */}
      <DropdownToggle tag='a' className='nav-link' onClick={clickHandler}>
        <Bell size={21} />
        {brandsDashboardSlice?.brandUnreadNotifications?.data > 0 &&
            <Badge pill color='danger' className='badge-up'>
              {brandsDashboardSlice?.brandUnreadNotifications?.data > 0 ? brandsDashboardSlice?.brandUnreadNotifications?.data : '0'}
            </Badge>
        }
      </DropdownToggle>
      <DropdownMenu tag='ul' right className='dropdown-menu-media mt-0'>

        <li className='dropdown-menu-header'>
          <DropdownItem className='d-flex' tag='div' header>
            <h4 className='notification-title mb-0 mr-auto'>Notifications</h4>
            {/* <Badge tag='div' color='light-primary' pill>
              { 0 || brandsDashboardSlice?.brandNotifications?.data.length} New
            </Badge> */}
          </DropdownItem>
        </li>
        {renderNotificationItems()}
        <li className='dropdown-menu-footer'>
          <Button.Ripple color='primary' block>
            <Link className="btn-primary" to="/dashboard/brands/notifications" >View all notifications</Link>
          </Button.Ripple>
        </li>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default NotificationDropdown
