import React, {useState, useEffect, Fragment, useRef} from "react"
import ReactToPrint from "react-to-print"
import {useParams, useHistory} from "react-router-dom"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Table,
    Spinner,
    Button
} from "reactstrap"
import {useDispatch, useSelector} from "react-redux"
import {getRdInvoiceDetail} from "@src/views/apps/invoice/store/actions/index"

const ComponentToTwoPrint = React.forwardRef((props, ref) => (
    <div ref={ref}>
        <Card>
            <CardBody>
                <h1 className="text-center mb-4 mt-2">Invoice</h1>
                <p>
                    <h3>Order :</h3>#
                    {props?.myProps?.data[0]?.id}
                </p>
                <p>
                    <h3>Issue Date :</h3>
                    {props?.myProps?.data[0]?.payment_date}
                </p>
                <Table className="mb-4">
                    <thead>
                    <tr>
                        <th>Item</th>
                        <th>Original Price</th>
                        <th>Discount</th>
                        <th>Coupon</th>
                        <th>Total Paid</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td scope="row">{props?.myProps?.data[0]?.event}</td>
                        <td>
                            {`${props?.myProps?.data[0]?.amount} $`}
                        </td>
                        <td>
                            {props?.myProps?.data[0]?.discount || "-"}
                        </td>
                        <td>
                            {props?.myProps?.data[0]?.coupon || "-"}
                        </td>
                        <td>
                            {`${props?.myProps?.data[0]?.amount} $`}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <b>Total Amount</b>
                        </td>
                        <td>
                            <b>
                                {`${props?.myProps?.data[0]?.amount} $`}
                            </b>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </CardBody>
            <CardFooter style={{backgroundColor: "#1b2033"}}>
                <h5 className="text-center text-white">
                    @ Link by GCG. All rights reserved.
                </h5>
            </CardFooter>
        </Card>
    </div>
))

const ViewRdPaymentInvoice = () => {
    const componentRef = useRef()
    const {id} = useParams()
    const [isTableLoading, setIsTableLoading] = useState(false)

    const paymentHistorySlice = useSelector((store) => store.invoice)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (!!paymentHistorySlice?.rdInvoiceDetailData) {
            setIsTableLoading(false)
        }
    }, [paymentHistorySlice])

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        if (!!user && user?.subscriptionexp) history.push('/subscription-plans')

        setIsTableLoading(true)
        dispatch(getRdInvoiceDetail(id))
    }, [])

    return (
        <div>
            {isTableLoading && ((<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>))}
            {!isTableLoading && (<Fragment>
                <Row className="d-flex justify-content-center">
                    <Col sm="5" md="5" lg="5">
                        <ReactToPrint
                            trigger={() => <Button className="mb-2">Print Invoice</Button>}
                            content={() => componentRef.current}
                        />
                        <ComponentToTwoPrint ref={componentRef} myProps={paymentHistorySlice?.rdInvoiceDetailData}/>
                    </Col>
                </Row>
            </Fragment>)}
        </div>
    )
}

export default ViewRdPaymentInvoice
