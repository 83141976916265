import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Avatar from '@components/avatar'
import {
    ChevronDown,
    Eye,
    MoreVertical
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Label,
    Input,
    CustomInput,
    Row,
    Col,
    Card,
    CardTitle,
    CardHeader,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    Spinner
} from 'reactstrap'
import {getManageRdsGeneralInfo} from '@src/views/apps/invoice/store/actions'
import {useDispatch, useSelector} from 'react-redux'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import '@styles/react/apps/app-invoice.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'

const CustomHeader = ({handleFilter, value, handleStatusValue, statusValue, handlePerPage, rowsPerPage}) => {
    return (
        <div className='invoice-list-table-header w-100 py-2'>
            <Row>
                <Col lg='6' className='d-flex align-items-center px-0 px-lg-1'>
                    <div className='d-flex align-items-center mr-2 Bold text'>
                        <Label className="text-lg Bold text" for='rows-per-page'>Entries</Label>
                        <CustomInput
                            className='form-control ml-50 pr-3'
                            type='select'
                            id='rows-per-page'
                            value={rowsPerPage}
                            onChange={handlePerPage}
                        >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                        </CustomInput>
                    </div>
                    {/* <Button.Ripple tag={Link} to='/apps/invoice/add' color='primary'>
            Add Record
          </Button.Ripple> */}
                </Col>
                <Col
                    lg='6'
                    className='actions-right d-flex align-items-center justify-content-lg-end flex-lg-nowrap flex-wrap mt-lg-0 mt-1 pr-lg-1 p-0'
                >
                    {/* <div className='d-flex align-items-center'>
            <Label for='search-invoice'>Search</Label>
            <Input
              id='search-invoice'
              className='ml-50 mr-2 w-100'
              type='text'
              value={value}
              onChange={e => handleFilter(e.target.value)}
              placeholder='Search Invoice'
            />
          </div> */}
                    <Input className='w-auto ' type='select' value={statusValue} onChange={handleStatusValue}>
                        <option value=''>Search By</option>
                        <option value='downloaded'>Downloaded</option>
                        <option value='draft'>Draft</option>
                        <option value='paid'>Paid</option>
                        <option value='partial payment'>Partial Payment</option>
                        <option value='past due'>Past Due</option>
                        <option value='partial payment'>Partial Payment</option>
                    </Input>
                </Col>
            </Row>
        </div>
    )
}

const renderClient = row => {
    const stateNum = Math.floor(Math.random() * 6),
        states = ['light-success', 'light-danger', 'light-warning', 'light-info', 'light-primary', 'light-secondary'],
        color = states[stateNum]

    if (row?.avatar?.length) {
        return <Avatar className='mr-50' img={row.avatar} width='32' height='32'/>
    } else {
        return <Avatar color={color} className='mr-50' content={row?.name} initials/>
    }
}

const ManageRDs = () => {
    const dispatch = useDispatch()
    const invoiceStoreSlice = useSelector(state => state.invoice)

    const [isTableLoading, setIsTableLoading] = useState(false)
    const [value, setValue] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [statusValue, setStatusValue] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [paginationInfo, setPaginationInfo] = useState(null)

    const [columns, setColumns] = useState([
        {
            name: 'Rd name',
            minWidth: '250px',
            selector: 'name',
            sortable: true,
            cell: row => {
                return (
                    <div className='d-flex justify-content-left align-items-center'>
                        {renderClient(row)}
                        <div className='d-flex flex-column'>
                            <h6 className='user-name text-truncate mb-0'>{row?.name}</h6>
                        </div>
                    </div>
                )
            }
        },
        {
            name: 'Registration Date',
            selector: 'reg_date',
            sortable: true,
            minWidth: '180px',
            cell: row => row.reg_date
        },
        {
            name: 'Partnerships',
            selector: 'partnerships',
            sortable: true,
            minWidth: '130px',
            cell: row => <span>{row?.partnerships || 0}</span>
        },
        {
            name: 'Monthly Logins',
            selector: 'monthly_logins',
            sortable: true,
            minWidth: '162px',
            cell: row => <span>{row?.monthly_logins || 0}</span>
        },
        {
            name: 'Login Time',
            selector: 'login_time',
            sortable: true,
            minWidth: '152px',
            cell: row => <span>{row?.login_time || 0}</span>
        },
        {
            name: 'Action',
            minWidth: '110px',
            selector: '',
            sortable: true,
            justifyContent: 'center',
            cell: row => (
                <div className='column-action d-flex align-items-center'>
                    <UncontrolledDropdown>
                        <DropdownToggle tag='span'>
                            <MoreVertical size={17} className='cursor-pointer'/>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem tag={Link} to={`/admin/manage-rd/${row.id}`} className='w-100'>
                                <Eye size={14} className='mr-50'/>
                                <span className='align-middle'>View Profile</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            )
        }
    ])

    useEffect(() => {
        if (!!invoiceStoreSlice?.manageRdsGeneralInfo) {
            const {links, meta, pages} = invoiceStoreSlice.manageRdsGeneralInfo
            setPaginationInfo({...links, ...meta, pages})
            setIsTableLoading(false)
        }
    }, [invoiceStoreSlice])

    useEffect(() => {
        setIsTableLoading(true)
        dispatch(getManageRdsGeneralInfo(false))
    }, [])

    const handleCustomPagination = pageUrl => {
        setIsTableLoading(true)
        dispatch(getManageRdsGeneralInfo(true, pageUrl))
    }

    const dataToRender = () => {
        return invoiceStoreSlice?.manageRdsGeneralInfo?.data
    }

    return (
        <div className='invoice-list-wrapper manage-rds'>
            <Card className="pt-0">
                <CardHeader><CardTitle>Manage RDs</CardTitle></CardHeader>
                {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!isTableLoading && (
                    <div className='invoice-list-dataTable'>
                        <DataTable
                            noHeader
                            paginationServer
                            subHeader={false}
                            columns={columns}
                            responsive={true}
                            sortIcon={<ChevronDown/>}
                            className='react-dataTable'
                            defaultSortField='reg_date'
                            paginationDefaultPage={currentPage}
                            data={dataToRender()}
                            subHeaderComponent={
                                <CustomHeader
                                    value={value}
                                    statusValue={statusValue}
                                    rowsPerPage={rowsPerPage}
                                />
                            }
                        />
                    </div>)}
                <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
            </Card>
        </div>
    )
}

export default ManageRDs
