import { Fragment, useEffect, useState } from 'react'
// import Avatar from '@components/avatar'
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Bell, X, Check, AlertTriangle } from 'react-feather'
import {
  Button,
  Badge,
  Media,
  CustomInput,
  Spinner,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getDashboardNotifications, getadminUnreadNotifications } from '../../../../../src/redux/actions/adminDashboard/index'

const NotificationDropdown = () => {
  const notificationsSlice = useSelector(store => store.adminDashboardSlice)
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (!!notificationsSlice?.notifications) {
      setIsLoading(false)
    }
  }, [notificationsSlice])

  useEffect(() => {
    setInterval(function () {
      dispatch(getadminUnreadNotifications())
    }, 300000)

    dispatch(getDashboardNotifications(false))
    dispatch(getadminUnreadNotifications())
  }, [])

  const clickHandler = () => {
    setIsLoading(true)
    dispatch(getDashboardNotifications(false))
    dispatch(getadminUnreadNotifications())
  }

  const handleRedirectMessage = (user) => {
    localStorage.setItem('selectedChatUserForAdmin', JSON.stringify({
        user_id: user.user_id,
        to_user_id: user.to_user_id,
        user_role: user.user_role,
        name: user.name,
        brand_name: user.brand_name,
        brand_logo: user.brand_logo,
        profile_img: user.profile_img
    }))
    window.location.href = '/admin/apps/chat/chatAdmin'
}

  const renderNotificationItems = () => {
    return (
      <PerfectScrollbar
        component='li'
        className='media-list scrollable-container'
        options={{
          wheelPropagation: false
        }}
      >
        {isLoading && (
          <div className="w-100 my-2 text-center">
            <Spinner color='primary' />
          </div>)}
        {!isLoading && notificationsSlice?.notifications?.data.length === 0 && (
          <div className="w-100 my-2 text-center">
            <p>There is no new notification</p>
          </div>)}
        {!isLoading && notificationsSlice?.notifications?.data.map((item, index) => {
          return (
            <Fragment>
              {item.type === 'rd_signup' && item.user_role === 'rd' &&
                <a key={index} className='d-flex' href={`/admin/manage-rd-pending/${item.user_id}`} >
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    <small className='notification-text' dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Media>
                </a>
              }
              {item.type === 'message' &&
                <a key={index} className='d-flex' onClick={e => {
                  e.preventDefault()
                  handleRedirectMessage(item)
              }}>
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    <small className='notification-text' dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Media>
                </a>
              }
              {item.type === 'contact' &&
                <a key={index} className='d-flex'>
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    <small className='notification-text' dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Media>
                </a>
              }
              {item.type === 'subscription' && item.user_role === 'brand' &&
                <a key={index} className='d-flex' href={`/admin/manage-brands/${item.user_id}`}>
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    <small className='notification-text' dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Media>
                </a>
              }
              {item.type === 'signup' && item.user_role === 'brand' &&
                <a key={index} className='d-flex' href={`/admin/manage-brands/${item.user_id}`}>
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    <small className='notification-text' dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Media>
                </a>
              }
              {item.type === 'request' &&
                <a key={index} className='d-flex' href={`/admin/manage-events/${item?.event_id}`}>
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    <small className='notification-text' dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Media>
                </a>
              }
            </Fragment>
          )
        })}
      </PerfectScrollbar>
    )
  }
  /*eslint-enable */

  return (
    <UncontrolledDropdown tag='li' className='dropdown-notification nav-item mr-25'>
      <DropdownToggle tag='a' className='nav-link' onClick={clickHandler}>
        <Bell size={21} />
        {notificationsSlice?.adminUnreadNotifications?.data > 0 &&
          <Badge pill color='danger' className='badge-up'>
            {notificationsSlice?.adminUnreadNotifications?.data > 0 ? notificationsSlice?.adminUnreadNotifications?.data : '0'
            }
          </Badge>
        }

      </DropdownToggle>
      <DropdownMenu tag='ul' right className='dropdown-menu-media mt-0'>
        <li className='dropdown-menu-header'>
          <DropdownItem className='d-flex' tag='div' header>
            <h4 className='notification-title mb-0 mr-auto'>Notifications</h4>
            {/* <Badge tag='div' color='light-primary' pill>
              {notificationsSlice?.notifications?.data?.length} New
            </Badge> */}
          </DropdownItem>
        </li>
        {renderNotificationItems()}
        <li className='dropdown-menu-footer'>
          <Button.Ripple color='primary' block>
            <Link to="/dashboard/admin/notifications" style={{ color: 'white' }}>
              Read all notifications
            </Link>
          </Button.Ripple>
        </li>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default NotificationDropdown
