import { useEffect, useState, Fragment } from "react"
import { Link, useParams, useHistory } from "react-router-dom"
import Avatar from "@components/avatar"
import Tiktok from "@src/assets/images/icons/tik-tok.svg"
import Pinterest from "@src/assets/images/icons/pinterest.svg"
import PDF from "@src/assets/images/icons/pdf1.png"
import TXT from "@src/assets/images/icons/txt1.png"
import CSV from "@src/assets/images/icons/csv.png"
import WORD from "@src/assets/images/icons/word.png"
import XLSX from "@src/assets/images/icons/xlsx.png"
import classnames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import {
    Facebook,
    Twitter,
    Linkedin,
    Instagram,
    Download,
    Folder,
    Heart,
    List,
    Video,
    XCircle,
    CheckCircle, X, Eye
} from "react-feather"
import {
    Card,
    CardBody,
    CardText,
    Row,
    Col,
    Button,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Input,
    UncontrolledTooltip,
    FormGroup
} from "reactstrap"
import "@styles/base/pages/page-auth.scss"
import {
    getBrandDetailsAtRD,
    getFavoriteFolders,
    createFavoriteFolder,
    addToFavorite, nullResponse
} from '@src/redux/actions/rdDashboard'
import {
    profileLinks
} from '@src/redux/actions/brandsDashboard'
import { NULL } from '@src/redux/actionTypes'
import BlankLogo from "@src/assets/images/avatars/Blank-logo.jpg"
import Linkify from "react-linkify"
import { getAppliedCampaignDetail } from "@src/views/rds/brandCampaigns/store/actions"
import ImageViewer from "@src/views/components/ImageViewer"
import PdfViewer from "@src/views/components/PdfViewer"
import VideoPlayer from "@src/views/components/VideoPlayer"

const BrandProfile = () => {
    const [isSelectFoldersModalOpen, setIsSelectFoldersModalOpen] = useState(false)
    const [folderName, setFolderName] = useState('')
    const [selectedFolder, setSelectedFolder] = useState(null)
    const [folderNameError, setFolderNameError] = useState(false)
    const [isFolderCreating, setIsFolderCreating] = useState(false)
    const [isFoldersLoading, setIsFoldersLoading] = useState(false)
    const [isAddingToFavorite, setIsAddingToFavorite] = useState(false)

    const [isPageLoading, setIsPageLoading] = useState(false)
    const [brandDetails, setBrandDetails] = useState(null)
    const [appliedDetailModal, setAppliedDetailModal] = useState(false)
    const [imageViewer, setImageViewer] = useState({show: false, src: ''})
    const [pdfViewer, setPdfViewer] = useState({show: false, url: '', filename: ''})
    const [videoPlayer, setVideoPlayer] = useState({show: false, url: '', filename: ''})

    const rdDashboardStoreSlice = useSelector((store) => store.rd)

    const {
        rdRecommendedCampaignsDetailLoading,
        rdRecommendedCampaignsDetailFullFill
    } = useSelector((store) => store.rdCampaigns)

    const rdCampaignSelector = useSelector((store) => store.rdCampaigns)

    const dispatch = useDispatch()
    const history = useHistory()
    const { id: brandId } = useParams()

    useEffect(() => {

        if (!!rdDashboardStoreSlice?.brandProfileAtRd) {
            setIsPageLoading(false)
            setBrandDetails({
                ...rdDashboardStoreSlice?.brandProfileAtRd.data,
                ...rdDashboardStoreSlice?.brandProfileAtRd
            })
        }

        if (!!rdDashboardStoreSlice?.addToFavroiteRes) {
            setIsPageLoading(true)
            dispatch(nullResponse(NULL.RD_ADD_TO_FAVORITE))
            dispatch(getBrandDetailsAtRD(brandId))
            setIsAddingToFavorite(false)
            setIsSelectFoldersModalOpen(false)
        }

        if (!!rdDashboardStoreSlice?.favoriteFoldersRes) {
            setIsFoldersLoading(false)
        }

        if (!!rdDashboardStoreSlice?.createfavoriteFolderRes) {
            setFolderName('')
            setIsFolderCreating(false)
            dispatch(nullResponse(NULL.RD_GET_FOLDERS))
            dispatch(nullResponse(NULL.RD_CREATE_FOLDER))
            dispatch(getFavoriteFolders(false))
            setIsFoldersLoading(true)
        }

    }, [rdDashboardStoreSlice])

    useEffect(() => {
        setIsPageLoading(true)
        dispatch(getFavoriteFolders(false))
        dispatch(getBrandDetailsAtRD(brandId))
    }, [])

    const handleRedirectMessage = (user) => {
        localStorage.setItem('selectedChatBrand', JSON.stringify(user))
        history.push(`/rd/apps/chat/chatRd/${user.id}`)
    }

    const handleCreateFolder = () => {
        if (!folderName) {
            setFolderNameError(true)
            return
        }
        setFolderNameError(false)
        setIsFolderCreating(true)
        dispatch(createFavoriteFolder({ folderName }))
    }

    const handleSelectFolder = (folder) => {
        setSelectedFolder(folder)
    }

    const handleAddToFavorite = (profile, isModal) => {
        if (profile.is_favorite && !isModal) {
            setIsPageLoading(true)
            dispatch(addToFavorite({ id: brandDetails.id, folder_id: profile.folder_id, type: 'profile' }))
        }

        if (!profile.is_favorite) setIsSelectFoldersModalOpen(true)

        if (!profile.is_favorite && isModal) {
            setIsAddingToFavorite(true)
            dispatch(addToFavorite({ id: brandDetails.id, folder_id: selectedFolder.id, type: 'profile' }))
        }
    }

    const getAppliedCampaignData = (id) => {
        dispatch(getAppliedCampaignDetail(id))
        setAppliedDetailModal(true)
    }

    return (
        <Fragment>
            {isPageLoading && (
                <div className="w-100 my-4 text-center">
                    <Spinner color='primary' />
                </div>)}
            {!isPageLoading && !!brandDetails && (
                <Fragment>
                    <Row className="match-height">
                        <Col md="12" lg="12" sm="12">
                            <h2 className="mb-2">Brand Details</h2>
                        </Col>
                        <Col md="5" lg="3" sm="12">
                            <Card className="ecommerce-card view-card text-center">
                                <div className="col-auto ml-auto pr-2 pt-1">
                                    {brandDetails?.is_favorite && (
                                        <Heart
                                            className="text-muted cursor-pointer"
                                            color="red"
                                            size={17}
                                            onClick={() => handleAddToFavorite(brandDetails, false)}
                                        />
                                    )}
                                    {!brandDetails?.is_favorite && (
                                        <Heart
                                            className="text-muted cursor-pointer"
                                            size={17}
                                            onClick={() => handleAddToFavorite(brandDetails, false)}
                                        />
                                    )}
                                </div>
                                <div className="item-img text-center mx-auto mt-1">
                                    <Avatar className="avatar-img-fit-contain" img={brandDetails?.company_logo || BlankLogo} imgHeight="110" imgWidth="110" />
                                </div>
                                <CardBody className="text-center">
                                    <h4 className="item-name font-weight-bolder">{brandDetails?.brand_name}</h4>
                                    <CardText className="item-description d-flex justify-content-center font-weight-bolder mt-1">
                    <span className="text-truncate">
                      <a href={brandDetails?.website || '#'} target="_blank" onClick={() => {
                          dispatch(profileLinks({
                              id: brandId,
                              link: brandDetails?.website,
                              type: "web"
                          }))
                      }}>
                        View Website
                      </a>
                    </span>
                                    </CardText>
                                    {brandDetails?.attributes?.special_diet.length !== 0 &&
                                        brandDetails?.attributes?.values.length !== 0 &&
                                        brandDetails?.attributes?.category.length !== 0 &&
                                        <div className="healthcare blue-bg-500">
                                            <p className="general-wellness font-weight-bolder blue-700">
                                                {brandDetails?.attributes?.special_diet[0]?.value || ''}
                                                {" "}|{" "}
                                                {brandDetails?.attributes?.values[0]?.value || ''}
                                                {" "}|{" "}
                                                {brandDetails?.attributes?.category[0]?.value || ''}
                                            </p>
                                        </div>}
                                    <div className="mb-1 d-flex justify-content-center flex-wrap">
                                        {!!brandDetails.instagram_url && <a
                                            className="mx-1 text-truncate mb-1"
                                            href={brandDetails.instagram_url || '#'}
                                            target="_blank"
                                            onClick={() => {
                                                dispatch(profileLinks({
                                                    id: brandId,
                                                    link: brandDetails?.instagram_url,
                                                    type: "social"
                                                }))
                                            }}
                                        >
                                            <Instagram />{" "}
                                        </a>}
                                        {!!brandDetails.facebook_url && <a
                                            className="mx-1 text-truncate mb-1"
                                            href={brandDetails.facebook_url || '#'}
                                            target="_blank"
                                            onClick={() => {
                                                dispatch(profileLinks({
                                                    id: brandId,
                                                    link: brandDetails?.facebook_url,
                                                    type: "social"
                                                }))
                                            }}
                                        >
                                            <Facebook />{" "}
                                        </a>}
                                        {!!brandDetails.twitter_url && <a
                                            className="mx-1 text-truncate mb-1"
                                            href={brandDetails.twitter_url || '#'}
                                            target="_blank"
                                            onClick={() => {
                                                dispatch(profileLinks({
                                                    id: brandId,
                                                    link: brandDetails?.twitter_url,
                                                    type: "social"
                                                }))
                                            }}
                                        >
                                            <Twitter />{" "}
                                        </a>}
                                        {!!brandDetails.linkedin_url && <a
                                            className="mx-1 text-truncate mb-1"
                                            href={brandDetails.linkedin_url || '#'}
                                            target="_blank"
                                            onClick={() => {
                                                dispatch(profileLinks({
                                                    id: brandId,
                                                    link: brandDetails?.linkedin_url,
                                                    type: "social"
                                                }))
                                            }}
                                        >
                                            <Linkedin />{" "}
                                        </a>}
                                        {!!brandDetails.tiktok_url && <a
                                            className="mx-1 text-truncate mb-1"
                                            href={brandDetails.tiktok_url || '#'}
                                            target="_blank"
                                            onClick={() => {
                                                dispatch(profileLinks({
                                                    id: brandId,
                                                    link: brandDetails?.tiktok_url,
                                                    type: "social"
                                                }))
                                            }}
                                        >
                                            <img src={Tiktok} width="24" height="26" />{" "}
                                        </a>}
                                        {!!brandDetails.pinterest_url && <a
                                            className="mx-1 text-truncate mb-1"
                                            href={brandDetails.pinterest_url || '#'}
                                            target="_blank"
                                            onClick={() => {
                                                dispatch(profileLinks({
                                                    id: brandId,
                                                    link: brandDetails?.pinterest_url,
                                                    type: "social"
                                                }))
                                            }}
                                        >
                                            <img src={Pinterest} width="24" height="24" />{" "}
                                        </a>}
                                    </div>
                                </CardBody>
                            </Card>
                            {/*<Card*/}
                            {/*  className="ecommerce-card view-card">*/}
                            {/*  <CardBody className="">*/}
                            {/*    <div className="d-flex justify-content-start align-items-center">*/}
                            {/*      <div className="avatar mr-1">*/}
                            {/*        <Avatar img={brandDetails?.profile_image || BlankUser} imgHeight="50" imgWidth="50" />*/}
                            {/*      </div>*/}
                            {/*      <div className="profile-user-info item-name">*/}
                            {/*        <h5 className="font-weight-bolder mb-0">{brandDetails?.name}</h5>*/}
                            {/*        <p className="font-weight-normal mb-0">{brandDetails.person_title || 'N/A'}</p>*/}
                            {/*      </div>*/}
                            {/*    </div>*/}
                            {/*  </CardBody>*/}
                            {/*</Card>*/}
                        </Col>
                        <Col md="7" lg="9" sm="12">
                            <Card className="d-flex">
                                <div className="">
                                    <CardBody>
                                        <div>
                                            <div className="d-flex justify-content-between">
                                                <h4 className="font-weight-bolder">About</h4>
                                                <Button.Ripple onClick={() => handleRedirectMessage(brandDetails)} className="m-25">
                                                    Message
                                                </Button.Ripple>
                                            </div>
                                            <div dangerouslySetInnerHTML={{__html: brandDetails?.about_company || 'N/A'}}/>
                                            {brandDetails?.attributes?.category?.length > 0 && (
                                                <div className='card-text'>
                                                    <h6 className="text-primary font-weight-bolder">Category</h6>
                                                    {brandDetails?.attributes?.category?.map((val, i) => (
                                                        <span key={i}>
                                  {val.value}
                                                            {brandDetails?.attributes?.category?.length - 1 !== i && ", "}
                                </span>
                                                    ))}
                                                </div>
                                            )}

                                            {brandDetails?.attributes?.special_diet?.length > 0 && (
                                                <div className='card-text'>
                                                    <h6 className="text-primary font-weight-bolder">Special Diet</h6>
                                                    {brandDetails?.attributes?.special_diet?.map((val, i) => (
                                                        <span key={i}>
                                  {val.value}
                                                            {brandDetails?.attributes?.special_diet?.length - 1 !== i && ", "}
                                </span>
                                                    ))}
                                                </div>
                                            )}

                                            {brandDetails?.attributes?.values?.length > 0 && (
                                                <div className='card-text'>
                                                    <h6 className="text-primary font-weight-bolder">Product Attributes</h6>
                                                    {brandDetails?.attributes?.values?.map((val, i) => (
                                                        <span key={i}>
                                  {val.value}
                                                            {brandDetails?.attributes?.values?.length - 1 !== i && ", "}
                                </span>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    {brandDetails?.media?.length > 0 && (
                        <Row>
                            <FormGroup tag={Col} sm="12" className="d-flex flex-wrap">
                                {brandDetails?.media?.map((media, i) => {
                                    const type = media.image_type.toLowerCase()
                                    return (
                                        <Fragment key={i}>
                                            {(type === 'png' || type === 'jpg' || type === 'jpeg') && (
                                                <div className='media-img media-file'>
                                                    <div className="download-icon cursor-pointer">
                                                        <a href='javascript:;'
                                                           onClick={() => setImageViewer({show: true, src: media.image_url})}>
                                                            <Eye size={16}/>
                                                        </a>
                                                    </div>
                                                    <div className="name-wrap">
                                                        <img className='rounded img-fit-contain' src={media?.image_url} alt='Card image' style={{height: '100px', width: '100px'}}/>
                                                        <p className="text-truncate mt-1">
                                                            <strong>{media.product_image}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                            {(type === 'pdf') && (
                                                <div className='media-img media-file'>
                                                    <div className="download-icon cursor-pointer">
                                                        <a href='javascript:;'
                                                           onClick={() => setPdfViewer({show: true, url: media.image_url, filename: media.product_image})}>
                                                            <Eye size={16}/>
                                                        </a>
                                                    </div>
                                                    <div className="name-wrap">
                                                        <img className='rounded img-fit-contain png-icon-img' src={PDF} alt='Card image'/>
                                                        <p className="text-truncate mt-1">
                                                            <strong>{media.product_image}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                            {(type === 'mp4') && (
                                                <div className='media-img media-file'>
                                                    <div className="download-icon cursor-pointer">
                                                        <a href='javascript:;'
                                                           onClick={() => setVideoPlayer({show: true, url: media.image_url, filename: media.product_image})}>
                                                            <Eye size={16}/>
                                                        </a>
                                                    </div>
                                                    <div className="name-wrap">
                                                        <Video className="text-primary text-center" size={90}/>
                                                        <p className="text-truncate mt-1">
                                                            <strong>{media.product_image}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </Fragment>)
                                })}
                            </FormGroup>
                        </Row>
                    )}

                    {brandDetails?.campaigns.length > 0 && (
                        <>
                            <Row>
                                <Col md="12" lg="12" sm="12">
                                    <h4 className="bg-white p-1 mb-2">Brand Campaigns</h4>
                                </Col>
                            </Row>
                            <Fragment>
                                <div className="brandSlides mb-3">
                                    <Row>
                                        {brandDetails?.campaigns.map((rdCamps, index) => (
                                            <Fragment key={index}>
                                                <Col md="3" className="mb-4" key={index}>
                                                    <div className="brandContent">
                                                        {/*<div className="row justify-content-between">*/}
                                                        {/*  <div className="col-auto ml-auto">*/}
                                                        {/*    <small className="border border-1 px-75 py-25 border-muted text-muted">*/}
                                                        {/*      {rdCamps?.opportunity_for}*/}
                                                        {/*    </small>*/}
                                                        {/*  </div>*/}
                                                        {/*</div>*/}
                                                        <Avatar img={rdCamps?.brand_img || BlankLogo} imgHeight="80" imgWidth="80"/>
                                                        <h4 className="mt-2 text-break font-weight-bold" title={rdCamps?.campaign_title || "N/A"} style={{height: "50px"}}>
                                                            {rdCamps?.campaign_title.length > 40 ? `${rdCamps?.campaign_title.substring(0, 40)}...` : rdCamps?.campaign_title}
                                                        </h4>
                                                        <div
                                                            className="healthcare"
                                                            style={{backgroundColor: "#f8f8f8"}}
                                                        >
                                                            <p
                                                                className="general-wellness cursor-pointer"
                                                                style={{color: "#161d31"}}
                                                            >
                                                                {rdCamps?.brand_name || "N/A"}
                                                            </p>
                                                        </div>
                                                        {!rdCamps?.is_applied && rdCamps?.can_apply && (
                                                            <Button.Ripple
                                                                tag={Link}
                                                                to={{
                                                                    pathname: `/dashboard/rd/campaign-application/${rdCamps?.id}`,
                                                                    state: {
                                                                        brandName: rdCamps?.brand_name,
                                                                        campName: rdCamps?.campaign_title,
                                                                        brandImage: rdCamps?.brand_img,
                                                                        campDetail: rdCamps?.campaign_details,
                                                                        formTitle: rdCamps?.form_title,
                                                                        formAttributes: rdCamps?.form_attributes,
                                                                        opportunityFor: rdCamps?.opportunity_for,
                                                                        campId: rdCamps?.id,
                                                                        campFormId: rdCamps?.campaign_form_id,
                                                                        rdDetails: rdCampaignSelector?.allCampaignsListing?.rd_details
                                                                    }
                                                                }}
                                                                color="secondary"
                                                                className="m-25 healthcare"
                                                                id={`UncontrolledTooltip_${rdCamps?.id}`}
                                                            >
                                                                <CheckCircle size={14}/> Learn More &amp; Apply
                                                                {/*<UncontrolledTooltip placement='top' target={`UncontrolledTooltip_${rdCamps?.id}`}>*/}
                                                                {/*Learn More &amp; Apply.*/}
                                                                {/*</UncontrolledTooltip>*/}
                                                            </Button.Ripple>
                                                        )}
                                                        {rdCamps?.is_applied ? (
                                                            <Button.Ripple
                                                                onClick={() => getAppliedCampaignData(rdCamps?.id)}
                                                                color="secondary"
                                                                className="m-25 healthcare"
                                                                id={`UncontrolledTooltip_${rdCamps?.id}`}
                                                            >
                                                                <List size={14}/> Applied
                                                                <UncontrolledTooltip placement='top' target={`UncontrolledTooltip_${rdCamps?.id}`}>
                                                                    Applied
                                                                </UncontrolledTooltip>
                                                            </Button.Ripple>
                                                        ) : rdCamps?.can_apply === false ? (
                                                            <Button.Ripple color="danger"
                                                                           className="m-25 healthcare"
                                                                           disabled={true}
                                                            >
                                                                <XCircle size={14}/> Limit exceeded.
                                                            </Button.Ripple>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Fragment>
                                        ))}
                                    </Row>
                                </div>

                                <Modal size="lg" isOpen={appliedDetailModal} className="modal-dialog-centered">
                                    <ModalHeader toggle={() => setAppliedDetailModal(false)}>Applied Campaign Detail</ModalHeader>
                                    {rdRecommendedCampaignsDetailLoading && (
                                        <div className="w-100 my-4 text-center">
                                            <Spinner color="primary"/>
                                        </div>
                                    )}
                                    {!rdRecommendedCampaignsDetailLoading && (
                                        <ModalBody>
                                            <CardText className="bg-light p-1 text-break">
                                                <b>{rdRecommendedCampaignsDetailFullFill?.data?.campaign_title}</b>
                                            </CardText>
                                            <div className='card-text text-break pl-1 mb-2' style={{whiteSpace: "break-spaces", textAlign: "justify"}}>
                                                <div dangerouslySetInnerHTML={{__html: rdRecommendedCampaignsDetailFullFill?.data?.campaign_details}}></div>
                                            </div>
                                            <CardText className="text-capitalize bg-light p-1">
                                                <b>{rdRecommendedCampaignsDetailFullFill?.data?.opportunity_for}</b>
                                            </CardText>
                                            <CardText className="bg-light p-1">
                                                <b>{rdRecommendedCampaignsDetailFullFill?.data?.form_title}</b>
                                            </CardText>
                                            {rdRecommendedCampaignsDetailFullFill?.data?.form_attributes?.length &&
                                                rdRecommendedCampaignsDetailFullFill?.data?.form_attributes.map((dat, index) => (
                                                    <Fragment key={index}>
                                                        <CardText className="p-1 text-break" style={{whiteSpace: "break-spaces", textAlign: "justify"}}>
                                                            <b>Question #{index + 1}</b>
                                                            <br/>
                                                            {dat?.question}
                                                        </CardText>
                                                        <CardText className="p-1 text-break" style={{whiteSpace: "break-spaces", textAlign: "justify"}}>
                                                            <b>Answer</b><br/>
                                                            {dat?.answer}
                                                        </CardText>
                                                    </Fragment>
                                                ))}
                                        </ModalBody>
                                    )}
                                    <ModalFooter>
                                        <Button.Ripple type="button" color="primary" onClick={() => setAppliedDetailModal(!appliedDetailModal)}>
                                            Close
                                        </Button.Ripple>
                                    </ModalFooter>
                                </Modal>
                            </Fragment>
                        </>
                    )}
                    <Modal scrollable isOpen={isSelectFoldersModalOpen} className='modal-dialog-centered'>
                        <ModalHeader>Select Folder</ModalHeader>
                        <ModalBody className="py-2" style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                            {isFoldersLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary' /></div>)}
                            {!isFoldersLoading && (
                                <Fragment>
                                    {rdDashboardStoreSlice?.favoriteFoldersRes?.data.map(item => (
                                        <Card className={classnames({
                                            'ecommerce-card': true,
                                            'mb-1': true,
                                            'border-1px-white': selectedFolder?.id !== item.id,
                                            'cursor-pointer': true,
                                            'border-1px-purple': selectedFolder?.id === item.id
                                        })} key={item.folder_name} onClick={() => handleSelectFolder(item)}>
                                            <CardBody className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <Folder
                                                        className='mr-1 text-secondary'
                                                        size={24}
                                                    />
                                                    <h5 className='mb-0'>{item.folder_name}</h5>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    ))}
                                </Fragment>
                            )}

                            <p className="text-center">or</p>
                            <div className='form-group'>
                                <Label className={classnames({
                                    'form-label': true
                                })} htmlFor={`folderName`}>
                                    Folder Name<span className="is-invalid">*</span>
                                </Label>
                                <Input
                                    type='text'
                                    onChange={(e) => { setFolderName(e.target.value); setFolderNameError(false) }}
                                    value={folderName}
                                    name={`folderName`}
                                    id={`folderName`}
                                    className={classnames({ 'is-invalid': folderNameError })}
                                />
                                {folderNameError && <div className="validation-err">This field is required</div>}
                            </div>
                            <div className="text-center">
                                <Button color='primary' outline disabled={isFolderCreating} onClick={handleCreateFolder}>
                                    {isFolderCreating ? 'Creating...' : 'Create Folder'}
                                </Button>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='danger' hidden={isAddingToFavorite} onClick={() => setIsSelectFoldersModalOpen(!isSelectFoldersModalOpen)}>
                                Cancel
                            </Button>
                            <Button color='primary' disabled={isAddingToFavorite} onClick={() => handleAddToFavorite(brandDetails, true)}>
                                {isAddingToFavorite ? 'Adding...' : 'Add to Favorite'}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Fragment>)}
            <ImageViewer
                show={imageViewer.show}
                hide={() => setImageViewer({show: false, src: ''})}
                images={[{src: imageViewer.src}]}
            />
            <PdfViewer show={pdfViewer.show}
                       url={pdfViewer.url}
                       filename={pdfViewer.filename}
                       hide={() => setPdfViewer({show: false, url: '', filename: ''})}
            />
            <VideoPlayer show={videoPlayer.show}
                         url={videoPlayer.url}
                         filename={videoPlayer.filename}
                         hide={() => setVideoPlayer({show: false, url: '', filename: ''})}
            />
        </Fragment>
    )
}

export default BrandProfile