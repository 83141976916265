import React, {useState, Fragment, useEffect} from "react"
import {useSelector, useDispatch} from "react-redux"
import {useParams} from "react-router-dom"
import {
    Instagram,
    Facebook,
    Twitter,
    Linkedin,
    FileText,
    Folder,
    MessageSquare,
    Send,
    Delete
} from "react-feather"
import Tiktok from "@src/assets/images/icons/tik-tok.svg"
import Pinterest from "@src/assets/images/icons/pinterest.svg"
import Web from "@src/assets/images/icons/web.svg"
import {
    Card,
    CardBody,
    Input,
    Label,
    Row,
    Col,
    Button,
    Modal,
    FormGroup,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    InputGroup,
    InputGroupAddon,
    Form,
    Alert,
    CustomInput,
    Table
} from "reactstrap"
import {
    campaignAddToFavAction,
    campaignAddToFavResetAction,
    campaignMsgAction,
    campaignMsgResetAction,
    getCampaignsDetail,
    getCampaignsDetailForExport
} from "./store/actions/index"
import "@styles/react/libs/flatpickr/flatpickr.scss"
import CustomPagination from "@src/views/components/pagination/CustomPagination"
import classnames from "classnames"
import * as yup from "yup"
import {Formik} from "formik"
import {createFavoriteFolder, getFavoriteFolders, nullResponse} from '@src/redux/actions/brandsDashboard'
import {NULL} from '@src/redux/actionTypes'
import queryString from 'query-string'
import Avatar from "@components/avatar"
import blankImage from "@src/assets/images/avatars/avatar-blank.png"

const CampaignDetails = () => {
    const {id} = useParams()

    const [searchInputValue, setSearchInputValue] = useState("")
    const [campaignExportData, setCampaignExportData] = useState([])
    const [campaignExportLoading, setCampaignExportLoading] = useState(false)
    const [rdIds, setRdIds] = useState([])
    const [showMsgModal, setShowMsgModal] = useState(false)
    const [showMsgAlert, setShowMsgAlert] = useState(false)
    const [showMsgRes, setShowMsgRes] = useState("")
    const [isSelectFoldersModalOpen, setIsSelectFoldersModalOpen] = useState(false)
    const [isFoldersLoading, setIsFoldersLoading] = useState(false)
    const [selectedFolder, setSelectedFolder] = useState(null)
    const [folderName, setFolderName] = useState('')
    const [folderNameError, setFolderNameError] = useState(false)
    const [isFolderCreating, setIsFolderCreating] = useState(false)
    const [isAddingToFavorite, setIsAddingToFavorite] = useState(false)
    const [showAddToFavAlert, setAddToFavAlert] = useState(false)
    const [showAddToFavRes, setAddToFavRes] = useState(false)
    const [selectAllApplicants, setSelectAllApplicants] = useState(false)
    const [readMoreData, setReadMoreData] = useState(null)
    const [readMoreModal, setReadMoreModal] = useState(false)
    const [queReadMoreModal, setQueReadMoreModal] = useState(false)
    const [queReadMoreData, setQueReadMoreData] = useState('')

    const dispatch = useDispatch()

    const campaignDetailSlice = useSelector((store) => store.brandsOppoprunities)

    const brandsDashboardStoreSlice = useSelector(store => store.brandsDashboardSlice)

    const {
        campaignApplicantsLoading,
        campaignApplicantsFullFill
        // campaignApplicantsError
    } = useSelector((store) => store.brandsOppoprunities)

    const {
        campaignMsgLoading,
        campaignMsgData
        // campaignMsgError
    } = useSelector((store) => store.campaignMsgReducer)
    const {
        campaignAddToFavLoading,
        campaignAddToFavMsgData
        // campaignAddToFavError
    } = useSelector((store) => store.campaignAddToFavReducer)

    useEffect(() => {
        if (campaignDetailSlice?.campaignDetailExport) {
            setCampaignExportData(campaignDetailSlice?.campaignDetailExport?.data)
            setCampaignExportLoading(false)
        }

    }, [campaignDetailSlice])

    useEffect(() => {
        if (campaignMsgData?.status) {
            setShowMsgAlert(true)
            setShowMsgModal(false)
            setShowMsgRes(campaignMsgData?.msg)
            setRdIds([])
            setSelectAllApplicants(false)
            dispatch(campaignMsgResetAction())
            setTimeout(() => {
                setShowMsgAlert(false)
            }, 3000)
        }
    }, [campaignMsgData])

    useEffect(() => {
        if (!!brandsDashboardStoreSlice?.addToFavroiteRes) {
            setIsAddingToFavorite(false)
            setIsSelectFoldersModalOpen(false)
            dispatch(nullResponse(NULL.BRANDS_ADD_TO_FAVORITE))
        }

        if (!!brandsDashboardStoreSlice?.favoriteFoldersRes) {
            setIsFoldersLoading(false)
        }

        if (!!brandsDashboardStoreSlice?.createfavoriteFolderRes) {
            setFolderName('')
            setIsFolderCreating(false)
            dispatch(nullResponse(NULL.BRANDS_GET_FOLDERS))
            dispatch(nullResponse(NULL.BRANDS_CREATE_FOLDER))
            setIsFoldersLoading(true)
            dispatch(getFavoriteFolders(false))
        }
    }, [brandsDashboardStoreSlice])

    useEffect(() => {
        if (campaignAddToFavMsgData?.status) {
            setAddToFavAlert(true)
            setAddToFavRes(campaignAddToFavMsgData?.msg)
            setIsSelectFoldersModalOpen(false)
            setSelectedFolder(null)
            setSelectAllApplicants(false)
            setRdIds([])
            dispatch(campaignAddToFavResetAction())
            setTimeout(() => {
                setAddToFavAlert(false)
            }, 3000)
        }
    }, [campaignAddToFavMsgData])

    useEffect(() => {
        dispatch({type: "CLEAR_CAMPAIGN_EXPORTED_DATA"})
        setCampaignExportData([])
        setCampaignExportLoading(true)
        dispatch(getCampaignsDetail(`?campaign_id=${id}`))
        dispatch(getCampaignsDetailForExport(id))
        dispatch(getFavoriteFolders(false))
    }, [])

    const handleCheckBox = (e) => {
        const {value} = e.target
        const find = rdIds.find(item => item.id === value)
        if (find) {
            setRdIds(prevState => (prevState.filter(item => item.id !== value)))
        } else {
            setRdIds(prevState => [...prevState, {id: value}])
        }
        setSelectAllApplicants(false)
    }

    const handleSelectAllCheckBox = (e) => {
        const {checked} = e.target
        if (checked) {
            const data = campaignExportData.map((val) => {
                return {id: `${val?.nutrition_details?.id}`}
            })
            setRdIds(data)
        } else {
            setRdIds([])
        }
        setSelectAllApplicants(!selectAllApplicants)
    }

    const handleCustomPagination = (pageUrl) => {
        const parsed = queryString.parseUrl(pageUrl)
        dispatch(getCampaignsDetail(`?campaign_id=${id}&search=${searchInputValue}&page=${parsed?.query?.page}`))
    }

    const handeSearchCampaignDetailData = () => {
        dispatch(getCampaignsDetail(`?campaign_id=${id}&search=${searchInputValue}`))
    }

    const handeResetSearch = () => {
        setSearchInputValue("")
        dispatch(getCampaignsDetail(`?campaign_id=${id}`))
    }

    function convertArrayOfObjectsToCSV(array) {
        let result = ''
        result += "Submission Date, Dietitian Name, Country, Region, States, Cities, Industry, Speciality , Website, Twitter, Tiktok, Linkedin, Facebook, Campaign Title"
        const questions = array[0].form_data
        const formLen = array[0].form_data.length
        if (formLen) {
            for (let index = 0; index < formLen; index++) {
                result += `,${questions[index].question?.replaceAll(',', ' ')}`
            }
        }
        result += "\n"
        array.forEach(item => {
            result += `${item?.submitted_at?.replace(',', '-')}, ${item?.nutrition_details?.name?.replaceAll('\n', '').replaceAll(',', ' ')},`
            result += item?.nutrition_details?.location?.country?.label ? `${item?.nutrition_details?.location?.country?.label},` : ","
            result += item?.nutrition_details?.location?.region?.label ? `${item?.nutrition_details?.location?.region?.label},` : ","
            result += item?.nutrition_details?.location?.state ? `${item?.nutrition_details?.location?.state.map(e => e?.label).join("-").replaceAll(',', ' ')},` : ","
            result += item?.nutrition_details?.location?.city ? `${item?.nutrition_details?.location?.city.map(e => e?.label).join("-").replaceAll(',', ' ')},` : ","
            result += item?.nutrition_details?.attributes?.industry ? `${item?.nutrition_details?.attributes?.industry.map(e => e?.label).join("-").replaceAll(',', ' ')},` : ","
            result += item?.nutrition_details?.attributes?.speciality ? `${item?.nutrition_details?.attributes?.speciality.map(e => e?.label).join("-").replaceAll(',', ' ')},` : ","
            result += `${item?.nutrition_details?.website}, ${item?.nutrition_details?.twitter}, ${item?.nutrition_details?.tiktok}, ${item?.nutrition_details?.linkedin}, ${item?.nutrition_details?.facebook},`
            result += `${item?.campaign_title?.replaceAll('\n', '').replaceAll(',', ' ').replaceAll('#', 'No.')}`
            item.form_data.forEach((form) => {
                result += `,${form?.answer?.replaceAll('\n', '').replaceAll(',', ' ').replaceAll('#', 'No.')}`
            })
            result += "\n"
        })
        return result
    }

    function downloadCSV(array) {
        const csv = convertArrayOfObjectsToCSV(array)
        if (csv) {
            const filename = 'List-Of-Applied-Users-On-Campaign.csv'
            const csvContent = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(csv)}`
            const link = document.createElement('a')
            link.setAttribute('href', csvContent)
            link.setAttribute('download', filename)
            link.click()
        }
    }

    const sendMessage = (data) => {
        const date = new Date()
        const localData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))
        const rds = []
        const obj = {
            from_user_id: localData?.chat_id,
            message: data.message_content,
            timezone: date.toString(),
            target: 'campaign'
        }
        if (rdIds.length > 0) {
            rdIds.forEach((value, index) => {
                rds.push(value.id)
            })
            const data = {...obj, to_users: rds}
            dispatch(campaignMsgAction(data))
        }
    }

    const handleCreateFolder = () => {
        if (!folderName) {
            setFolderNameError(true)
            return
        }
        setFolderNameError(false)
        setIsFolderCreating(true)
        dispatch(createFavoriteFolder({folderName}))
    }

    const handleSelectFolder = (folder) => {
        setSelectedFolder(folder)
    }

    const handleAddToFavorite = () => {
        const obj = []
        rdIds.forEach((value, index) => {
            obj.push({id: value.id, folder_id: selectedFolder?.id})
        })
        dispatch(campaignAddToFavAction({data: obj}))
    }

    const readMore = (data, camp) => {
        setReadMoreData({data, camp: camp?.nutrition_details})
        setReadMoreModal(true)
    }

    const queReadMore = (data) => {
        setQueReadMoreData(data)
        setQueReadMoreModal(true)
    }

    return (
        <Fragment>
            <h2 className="text-break">
                {campaignApplicantsFullFill?.campaign?.title}
            </h2>
            <Card className="mt-2">
                <CardBody>
                    <Row>
                        <Col lg="5" md="6">
                            <InputGroup size="lg" className="">
                                <Input
                                    placeholder="Search By Name"
                                    value={searchInputValue}
                                    onChange={(e) => setSearchInputValue(e.target.value)}
                                />
                                <InputGroupAddon addonType="append">
                                    <Button color="primary" onClick={handeSearchCampaignDetailData} outline>
                                        Search
                                    </Button>
                                </InputGroupAddon>
                                <InputGroupAddon addonType="append">
                                    <Button color="primary" onClick={handeResetSearch} outline>
                                        Reset
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                        <Col lg="7" md="6">
                            <Button.Ripple
                                className="float-right h-100 ml-1 btn-sm"
                                color="success"
                                onClick={() => downloadCSV(campaignExportData)}
                                title="Download CSV"
                                disabled={campaignExportLoading || campaignExportData.length === 0}
                            >
                                {campaignExportLoading ? (
                                    <>
                                        <Spinner style={{height: '14px', width: '14px'}}/> Download CSV
                                    </>
                                ) : (
                                    <>
                                        <FileText size={14}/> Download CSV
                                    </>
                                )}
                            </Button.Ripple>
                            <Button.Ripple
                                className="float-right h-100 ml-1 btn-sm"
                                color="primary"
                                onClick={() => setShowMsgModal(!showMsgModal)}
                                title="Send Message"
                                disabled={campaignExportLoading || campaignExportData.length === 0}
                            >
                                {campaignExportLoading ? (
                                    <>
                                        <Spinner style={{height: '14px', width: '14px'}}/> Send Message
                                    </>
                                ) : (
                                    <>
                                        <MessageSquare size={14}/> Send Message
                                    </>
                                )}
                            </Button.Ripple>
                            <Button.Ripple
                                className="float-right h-100 btn-sm"
                                color="warning"
                                onClick={() => setIsSelectFoldersModalOpen(!isSelectFoldersModalOpen)}
                                title="Add to favourite"
                                disabled={campaignExportLoading || campaignExportData.length === 0}
                            >
                                {campaignExportLoading ? (
                                    <>
                                        <Spinner style={{height: '14px', width: '14px'}}/> Add to Folder
                                    </>
                                ) : (
                                    <>
                                        <Folder size={14}/> Add to Folder
                                    </>
                                )}
                            </Button.Ripple>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Alert color="success" isOpen={showMsgAlert} toggle={() => setShowMsgAlert(!showMsgAlert)}>
                <div className='alert-body'>
                    {showMsgRes}
                </div>
            </Alert>

            <Alert color="success" isOpen={showAddToFavAlert} toggle={() => setAddToFavAlert(!showAddToFavAlert)}>
                <div className='alert-body'>
                    {showAddToFavRes}
                </div>
            </Alert>

            <Card>
                <CardBody className="overflow-auto">
                    {campaignApplicantsLoading ? (
                        <div className="w-100 my-4 text-center">
                            <Spinner color="primary"/>
                        </div>
                    ) : (
                        <>
                            <Table className="text-nowrap">
                                <thead>
                                <tr>
                                    <th>Submission Date</th>
                                    <th>RD Name</th>
                                    <th>Location</th>
                                    <th>Industry</th>
                                    <th>Speciality</th>
                                    <th>Social Media</th>
                                    {campaignApplicantsFullFill?.data?.length > 0 && campaignApplicantsFullFill?.data[0]?.form_data?.map((item, i) => (
                                        <th key={i}>
                                            {item?.title?.length > 50 ? (
                                                <>
                                                    <span>{item?.title?.substring(0, 50)}</span>
                                                    <span className='text-primary cursor-pointer' onClick={() => queReadMore(item?.title)}>...Read more</span>
                                                </>
                                            ) : (
                                                <span>{item?.title}</span>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {campaignApplicantsFullFill?.data?.length > 0 ? (
                                    campaignApplicantsFullFill?.data?.map((item, i) => (
                                        <tr key={i}>
                                            <td>{item?.submitted_at}</td>
                                            <td>{item?.nutrition_details?.name}</td>
                                            <td>
                                                {item?.nutrition_details?.location?.state ? item?.nutrition_details?.location?.state?.map((locate, i) => (
                                                    <span key={i} className="badge badge-primary mr-1">{locate?.label}</span>
                                                )) : (
                                                    <span>-</span>
                                                )}
                                            </td>
                                            <td className="text-wrap">
                                                {item?.nutrition_details?.attributes?.industry ? (
                                                    item?.nutrition_details?.attributes?.industry?.map((industry, i) => (
                                                        i === 0 ? (
                                                            <div key={i} className="badge badge-primary">{industry?.label}</div>
                                                        ) : (
                                                            <div key={i} className="badge badge-primary mr-1 mt-1">{industry?.label}</div>
                                                        )
                                                    ))
                                                ) : (
                                                    <span>-</span>
                                                )}
                                            </td>
                                            <td className="text-wrap">
                                                {item?.nutrition_details?.attributes?.speciality ? (
                                                    item?.nutrition_details?.attributes?.speciality.map((special, i) => (
                                                        i === 0 ? (
                                                            <span key={i} className="badge badge-primary">{special?.label}</span>
                                                        ) : (
                                                            <span key={i} className="badge badge-primary mr-1 mt-1">{special?.label}</span>
                                                        )
                                                    ))
                                                ) : (
                                                    <span>-</span>
                                                )}
                                            </td>
                                            <td>

                                                {item?.nutrition_details?.website &&
                                                    <a target="_blank" href={item?.nutrition_details?.website || "#"} className="mr-1">
                                                        <img src={Web} width="20" height="20"/>
                                                    </a>}
                                                {item?.nutrition_details?.tiktok &&
                                                    <a target="_blank" href={item?.nutrition_details?.tiktok || "#"} className="mr-1">
                                                        <img src={Tiktok} width="20" height="20"/>
                                                    </a>}
                                                {item?.nutrition_details?.pinterest &&
                                                    <a target="_blank" href={item?.nutrition_details?.pinterest || "#"} className="mr-1">
                                                        <img src={Pinterest} width="20" height="20"/>
                                                    </a>}
                                                {item?.nutrition_details?.instagram &&
                                                    <a target="_blank" href={item?.nutrition_details?.instagram || "#"} className="mr-1">
                                                        <Instagram size="20"/>
                                                    </a>}
                                                {item?.nutrition_details?.facebook &&
                                                    <a target="_blank" href={item?.nutrition_details?.facebook || "#"} className="mr-1">
                                                        <Facebook size="20"/>
                                                    </a>}
                                                {item?.nutrition_details?.twitter &&
                                                    <a target="_blank" href={item?.nutrition_details?.twitter || "#"} className="mr-1">
                                                        <Twitter size="20"/>
                                                    </a>}
                                                {item?.nutrition_details?.linkedin &&
                                                    <a target="_blank" href={item?.nutrition_details?.linkedin || "#"}>
                                                        <Linkedin size="20"/>
                                                    </a>}
                                            </td>
                                            {item?.form_data && item?.form_data.map((q, i) => (
                                                <td className="text-wrap text-capitalize" key={i}>
                                                    {q?.answer.length > 30 ? (
                                                        <>
                                                            <span>{q?.answer.substring(0, 30)}</span>
                                                            <span className='text-primary cursor-pointer' onClick={() => readMore(q, item)}>
                                                                ...Read more
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <span>
                                                            {q?.answer}
                                                        </span>
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td className="text-center" colSpan="12">
                                            Sorry! No views found.
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                            <CustomPagination pageLinks={campaignApplicantsFullFill?.pages} onPaginationClick={handleCustomPagination}/>
                        </>
                    )}
                </CardBody>
            </Card>
            {/* --- Add To Folder Modal --- */}
            <Modal scrollable isOpen={isSelectFoldersModalOpen} className='modal-dialog-centered' size="lg">
                <ModalHeader>Add To Favourite Folder</ModalHeader>
                <ModalBody>
                    <FormGroup tag={Col} md="12" className="d-flex justify-content-between">
                        <CustomInput
                            key="select-all-applicants"
                            type='checkbox'
                            className="font-weight-bold"
                            name="select-all-applicants"
                            id="select-all-applicants"
                            label="Select All"
                            value={selectAllApplicants}
                            checked={selectAllApplicants}
                            onChange={handleSelectAllCheckBox}
                        />
                        <span className="font-weight-bold badge badge-success badge-sm">
                            Count: {rdIds.length}
                        </span>
                    </FormGroup>
                    <FormGroup tag={Col} md="12">
                        <Row style={{maxHeight: '400px', overflow: 'auto'}} className="bg-light pt-1">
                            {campaignExportData.length > 0 && campaignExportData.map((val, index) => (
                                <Col md="4" key={index}>
                                    <CustomInput
                                        key={index}
                                        type='checkbox'
                                        className="mb-1"
                                        name={`check-${val?.nutrition_details?.id}`}
                                        id={`check-${val?.nutrition_details?.id}`}
                                        label={val?.nutrition_details?.name}
                                        value={val?.nutrition_details?.id}
                                        onChange={handleCheckBox}
                                        checked={rdIds.find(item => item.id === `${val?.nutrition_details?.id}`)}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </FormGroup>
                    <FormGroup tag={Col} md="12">
                        <Label className="form-label"> Please select a folder<span
                            className="is-invalid">*</span></Label>
                    </FormGroup>
                    {isFoldersLoading ? (
                        <div className="w-100 my-4 text-center"><Spinner color='primary'/></div>
                    ) : (
                        <div className="p-1" style={{maxHeight: '40vh', overflowY: 'auto'}}>
                            {brandsDashboardStoreSlice?.favoriteFoldersRes?.data.map(item => (
                                <Card className={classnames({
                                    'ecommerce-card': true,
                                    'mb-1': true,
                                    'border-1px-white': selectedFolder?.id !== item.id,
                                    'cursor-pointer': true,
                                    'border-1px-purple': selectedFolder?.id === item.id
                                })} key={item.folder_name} onClick={() => handleSelectFolder(item)}>
                                    <CardBody className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <Folder
                                                className='mr-1 text-secondary'
                                                size={24}
                                            />
                                            <h5 className='mb-0'>{item.folder_name}</h5>
                                        </div>
                                    </CardBody>
                                </Card>
                            ))}
                        </div>
                    )}
                    <p className="text-center">OR</p>
                    <div className='form-group'>
                        <Label className={classnames({
                            'form-label': true
                        })} htmlFor={`folderName`}>
                            Folder Name<span className="is-invalid">*</span>
                        </Label>
                        <div className="d-flex justify-content-between">
                            <Input
                                type='text'
                                onChange={(e) => {
                                    setFolderName(e.target.value)
                                    setFolderNameError(false)
                                }}
                                value={folderName}
                                name={`folderName`}
                                id={`folderName`}
                                className={classnames('mr-1', {'is-invalid': folderNameError})}
                            />
                            <Button color='primary' disabled={isFolderCreating} onClick={handleCreateFolder}
                                    className="text-nowrap">
                                {isFolderCreating ? (
                                    <>
                                        <Spinner style={{height: '14px', width: '14px'}}/> Create Folder
                                    </>
                                ) : (
                                    <>
                                        <Folder size={14}/> Create Folder
                                    </>
                                )}
                            </Button>
                        </div>

                        {folderNameError && <div className="validation-err">This field is required</div>}
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between">
                    <Button color='danger' onClick={() => {
                        setSelectAllApplicants(false)
                        setSelectedFolder(null)
                        setRdIds([])
                        setIsSelectFoldersModalOpen(false)
                    }}>
                        <Delete size={14}/> Cancel
                    </Button>
                    <Button color='primary' disabled={isAddingToFavorite || !selectedFolder?.id || rdIds.length === 0}
                            onClick={() => handleAddToFavorite()}>
                        {campaignAddToFavLoading ? (
                            <>
                                <Spinner style={{height: '14px', width: '14px'}}/> Add to Favorite
                            </>
                        ) : (
                            <>
                                <Folder size={14}/> Add to Favorite
                            </>
                        )}
                    </Button>
                </ModalFooter>
            </Modal>

            {/* --- Send Message Modal --- */}
            <Modal size="lg" isOpen={showMsgModal} className="modal-dialog-centered">
                <ModalHeader>Send Message</ModalHeader>
                <Formik
                    initialValues={{message_content: ""}}
                    validationSchema={yup.object().shape({message_content: yup.string().required("This field is required")})}
                    onSubmit={(values) => {
                        sendMessage(values)
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          touched, values
                      }) => (
                        <Form className='auth-reset-password-form mt-2' onSubmit={handleSubmit}>
                            <ModalBody>
                                <FormGroup tag={Col} md="12" className="d-flex justify-content-between">
                                    <CustomInput
                                        key="select-all-applicants"
                                        type='checkbox'
                                        className="font-weight-bold"
                                        name="select-all-applicants"
                                        id="select-all-applicants"
                                        label="Select All"
                                        value={selectAllApplicants}
                                        checked={selectAllApplicants}
                                        onChange={handleSelectAllCheckBox}
                                    />
                                    <span className="font-weight-bold badge badge-success badge-sm">
                                                Count: {rdIds.length}
                                            </span>
                                </FormGroup>
                                <FormGroup tag={Col} md="12">
                                    <Row className="bg-light pt-1" style={{maxHeight: '400px', overflow: 'auto'}}>
                                        {campaignExportData.length > 0 && campaignExportData.map((val, index) => (
                                            <Col md="4" key={index}>
                                                <CustomInput
                                                    key={index}
                                                    type='checkbox'
                                                    className="mb-1"
                                                    name={`check-${val?.nutrition_details?.id}`}
                                                    id={`check-${val?.nutrition_details?.id}`}
                                                    label={val?.nutrition_details?.name}
                                                    value={val?.nutrition_details?.id}
                                                    onChange={handleCheckBox}
                                                    checked={rdIds.find(item => item.id === `${val?.nutrition_details?.id}`)}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </FormGroup>
                                <FormGroup tag={Col} md="12">
                                    <Label className={classnames({
                                        'is-invalid': touched.message_content && errors.message_content,
                                        'form-label': true
                                    })} htmlFor='message_content'> Message<span className="is-invalid">*</span></Label>
                                    <Input rows="5" type="textarea" name={`message_content`}
                                           id="message_content"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           className={classnames({
                                               'is-invalid': touched.message_content && errors.message_content
                                           })}
                                           disabled={campaignMsgLoading}
                                    />
                                    <div
                                        className="validation-err">{touched.message_content && errors.message_content}</div>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter className="d-flex justify-content-between">
                                <Button color="danger" onClick={() => {
                                    setRdIds([])
                                    setSelectAllApplicants(false)
                                    setShowMsgModal(false)
                                }}>
                                    <Delete size={14}/> Close
                                </Button>
                                <Button color='primary' type='submit'
                                        disabled={campaignMsgLoading || rdIds.length === 0}>
                                    {campaignMsgLoading ? (
                                        <>
                                            <Spinner style={{height: '14px', width: '14px'}}/> Send
                                        </>
                                    ) : (
                                        <>
                                            <Send size={14}/> Send
                                        </>
                                    )}
                                </Button>
                            </ModalFooter>
                        </Form>)}
                </Formik>
            </Modal>

            {/* --- Read More Modal --- */}
            <Modal size="lg" isOpen={readMoreModal} className="modal-dialog-centered">
                <ModalHeader toggle={() => {
                    setReadMoreModal(false)
                    setReadMoreData(null)
                }}>
                    Campaign Question
                </ModalHeader>
                <ModalBody>
                    <div className="item-img text-center mx-auto">
                        <Avatar img={readMoreData?.camp?.profile_img || blankImage} imgHeight="120" imgWidth="120" className="avatar-img-fit-contain"/>
                    </div>
                    <p className='text-break p-1 text-center text-primary'>
                        <strong>{readMoreData?.camp?.name}</strong>
                    </p>
                    <p className='text-break bg-light p-1'>
                        <strong>{readMoreData?.data?.question}</strong>
                    </p>
                    <p className='text-break p-1'>
                        {readMoreData?.data?.answer}
                    </p>
                </ModalBody>
            </Modal>

            {/* --- Que Read More Modal --- */}
            <Modal size="lg" isOpen={queReadMoreModal} className="modal-dialog-centered">
                <ModalHeader toggle={() => {
                    setQueReadMoreData('')
                    setQueReadMoreModal(false)
                }}>
                    Campaign Question
                </ModalHeader>
                <ModalBody>
                    <p className='text-break p-1 text-center'>
                        <strong>{queReadMoreData}</strong>
                    </p>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default CampaignDetails
