import React, {useState, useEffect} from "react"
import {Link} from "react-router-dom"
import {
    Trash2
} from "react-feather"
import {
    FaUserFriends,
    FaCalendarAlt,
    FaMapMarkerAlt,
    FaDollarSign,
    FaHandshake
} from "react-icons/fa"
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Button,
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap"
import {useSelector, useDispatch} from "react-redux"
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import queryString from "query-string"
import * as ICON from 'react-feather'
import {
    getNewEvents,
    deleteEvent,
    deleteEventReset,
    getPastEvents,
    getEventBrands,
    copyEvent,
    copyEventReset
} from "@src/redux/actions/RDActions"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const TabsBasic = () => {

    const sweetAlert = withReactContent(Swal)

    const dispatch = useDispatch()

    const [active, setActive] = useState("1")
    const [participateModal, setParticipateModal] = useState(false)
    const [eventId, setEventId] = useState('')

    const {
        getNewEventsLoading,
        getNewEventsData,
        getNewEventsError,

        deleteEventLoading,
        deleteEventData,
        deleteEventError,

        getPastEventsLoading,
        getPastEventsData,
        getPastEventsError,

        getEventBrandsLoading,
        getEventBrandsData,
        getEventBrandsError,

        copyEventLoading,
        copyEventData,
        copyEventError
    } = useSelector((store) => store?.rdReducer)


    useEffect(() => {
        if (deleteEventData?.success) {
            sweetAlert.fire('Congratulations!', deleteEventData?.msg, 'success')
            dispatch(deleteEventReset())
            dispatch(getNewEvents(``))
            dispatch(getPastEvents(``))
        }
        if (copyEventData?.success) {
            sweetAlert.fire('Congratulations!', copyEventData?.msg, 'success')
            dispatch(copyEventReset())
            dispatch(getNewEvents(``))
            dispatch(getPastEvents(``))
        }
    }, [
        deleteEventData,
        copyEventData
    ])

    useEffect(() => {
        dispatch(getNewEvents(``))
        dispatch(getPastEvents(``))
    }, [])

    const getBrands = (id) => {
        setParticipateModal(true)
        dispatch(getEventBrands(`?event_id=${id}`))
        setEventId(id)
    }

    const upcomingEventPagination = (pageUrl) => {
        const parsed = queryString.parseUrl(pageUrl)
        if (parsed?.query?.page) {
            dispatch(getNewEvents(`?page=${parsed.query.page}`))
        }
    }

    const pastEventPagination = (pageUrl) => {
        const parsed = queryString.parseUrl(pageUrl)
        if (parsed?.query?.page) {
            dispatch(getPastEvents(`?page=${parsed.query.page}`))
        }
    }

    const deleteSelectedEvent = (id) => {
        sweetAlert.fire({
            title: 'Confirm!',
            icon: 'warning',
            html: '<p>Do you want to delete this opportunity.</p>',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonText: 'No',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteEvent({
                    event_id: id
                }))
            }
        })
    }

    const copyOpportunity = (id) => {
        sweetAlert.fire({
            title: 'Confirm!',
            icon: 'warning',
            html: '<p>Do you want to copy this opportunity.</p>',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonText: 'No',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(copyEvent({
                    event_id: id
                }))
            }
        })
    }

    return (
        <React.Fragment>
            <h4>My Opportunities</h4>
            <hr/>
            <Nav tabs className="my-2">
                <NavItem>
                    <NavLink active={active === "1"} onClick={() => setActive('1')}>
                        Upcoming Opportunities
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink active={active === "2"} onClick={() => setActive('2')}>
                        Past Opportunities
                    </NavLink>
                </NavItem>
                <NavItem className="ml-auto">
                    <Button.Ripple color="primary" tag={Link} to="/dashboard/rd/opportunities-rdform">
                        <span className="align-middle"><ICON.PlusSquare size={14}/> Add Opportunity</span>
                    </Button.Ripple>
                </NavItem>
            </Nav>

            <TabContent className="py-50" activeTab={active}>
                <TabPane tabId="1">
                    {(getNewEventsLoading || deleteEventLoading || copyEventLoading) ? (
                        <div className="text-center bg-white p-1 font-weight-bold font-medium-1">
                            <Spinner color="primary" style={{height: '18px', width: '18px'}}/> Loading...
                        </div>
                    ) : (
                        getNewEventsData?.data?.length > 0 ? (
                            <Row className="match-height">
                                {getNewEventsData?.data?.map((item, index) => (
                                    <Col sm="6" md="6" lg="4" key={index}>
                                        <Card>
                                            <CardHeader className='p-1'>
                                                <CardTitle className='font-small-4 text-primary'>{item?.title}</CardTitle>
                                                {item.can_delete && (
                                                    <CardTitle onClick={() => deleteSelectedEvent(item.id)}>
                                                        <Trash2 size={16} className="cursor-pointer text-danger"/>
                                                    </CardTitle>
                                                )}
                                            </CardHeader>
                                            <CardBody>
                                                <p><FaMapMarkerAlt size={17} className="mr-1"/>{item.country || 'N/A'}</p>
                                                <p><FaHandshake size={18} className="mr-1"/>{item.event_type || 'N/A'}</p>
                                                <p><FaUserFriends size={17} className="mr-1"/>{item?.expected_reach || 'N/A'}</p>
                                                <p><FaDollarSign size={17} className="mr-1"/>{item?.cost_of_partnership || 'N/A'}</p>
                                                <p><FaCalendarAlt size={17} className="mr-1"/>{item.start_datetime || 'N/A'} - {item.end_datetime || 'N/A'}</p>
                                                <div className="mt-1">
                                                    <Button.Ripple tag={Link} size='sm' color="secondary" className="round m-25"
                                                                   to={`/dashboard/rd/edit-opportunities-rdform/${item?.id}`}>
                                                        <ICON.Edit size={14}/> <span className="align-middle ml-50">Manage</span>
                                                    </Button.Ripple>
                                                    <Button.Ripple color="secondary" size='sm' className="round m-25" type='button' onClick={() => copyOpportunity(item?.id)}>
                                                        <ICON.Copy size={14}/> <span className="align-middle ml-50">Copy</span>
                                                    </Button.Ripple>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                                <CustomPagination pageLinks={getNewEventsData?.pages} onPaginationClick={upcomingEventPagination}/>
                            </Row>
                        ) : (
                            <Row className="match-height">
                                <Col sm="12" md="12" lg="12">
                                    <div className='text-center bg-white p-1'>
                                        Sorry! No upcoming Opportunities found
                                    </div>
                                </Col>
                            </Row>
                        ))}
                </TabPane>

                <TabPane tabId="2">
                    {(getPastEventsLoading || deleteEventLoading || copyEventLoading) ? (
                        <div className="text-center bg-white p-1 font-weight-bold font-medium-1">
                            <Spinner color="primary" style={{height: '18px', width: '18px'}}/> Loading...
                        </div>
                    ) : (
                        getPastEventsData?.data?.length > 0 ? (
                            <Row className="match-height">
                                {getPastEventsData?.data?.map((item, index) => (
                                    <Col sm="6" md="4" lg="4" key={index}>
                                        <Card>
                                            <CardHeader className='p-1'>
                                                <CardTitle className='font-small-4 text-primary'>{item?.title}</CardTitle>
                                                {item.can_delete && (
                                                    <CardTitle onClick={() => deleteSelectedEvent(item.id)}>
                                                        <Trash2 size={16} className="cursor-pointer text-danger"/>
                                                    </CardTitle>
                                                )}
                                            </CardHeader>
                                            <CardBody>
                                                <p><FaMapMarkerAlt size={17} className="mr-1"/>{item.country || 'N/A'}</p>
                                                <p><FaHandshake size={18} className="mr-1"/>{item.event_type || 'N/A'}</p>
                                                <p><FaUserFriends size={17} className="mr-1"/>{item?.expected_reach || 'N/A'}</p>
                                                <p><FaDollarSign size={17} className="mr-1"/>{item?.cost_of_partnership || 'N/A'}</p>
                                                <p><FaCalendarAlt size={17} className="mr-1"/>{item.start_datetime || 'N/A'} - {item.end_datetime || 'N/A'}</p>
                                                <div className="mt-1">
                                                    {item?.copied === 0 && (
                                                        <Button.Ripple color="secondary" className="round m-25" onClick={() => getBrands(item?.id)} size='sm'>
                                                            <ICON.User size={14}/>
                                                            <span className="align-middle ml-50">Feedback</span>
                                                        </Button.Ripple>
                                                    )}
                                                    {item?.copied === 1 && (
                                                        <Button.Ripple tag={Link} color="secondary" className="round m-25" size='sm'
                                                                       to={`/dashboard/rd/edit-opportunities-rdform/${item?.id}`}>
                                                            <ICON.Edit size={14}/> <span className="align-middle ml-50">Manage</span>
                                                        </Button.Ripple>
                                                    )}
                                                    <Button.Ripple color="secondary" size='sm' className="round m-25" type='button' onClick={() => copyOpportunity(item?.id)}>
                                                        <ICON.Copy size={14}/> <span className="align-middle ml-50">Copy</span>
                                                    </Button.Ripple>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                                <CustomPagination pageLinks={getPastEventsData?.pages} onPaginationClick={pastEventPagination}/>
                            </Row>
                        ) : (
                            <Row className="match-height">
                                <Col sm="12" md="12" lg="12">
                                    <div className='text-center bg-white p-1'>
                                        Sorry! No past Opportunities found
                                    </div>
                                </Col>
                            </Row>
                        ))}
                </TabPane>
            </TabContent>

            <Modal isOpen={participateModal} className="modal-dialog-centered" size='lg'>
                <ModalHeader toggle={() => setParticipateModal(false)}>
                    Select Brand
                </ModalHeader>
                <ModalBody>
                    <Row>
                        {getEventBrandsLoading ? (
                            <div className="w-100 my-4 text-center">
                                <Spinner color="primary"/>
                            </div>
                        ) : (
                            getEventBrandsData?.data?.length > 0 ? (
                                <Col lg="4">
                                    <Card>
                                        <CardBody>
                                            <div>
                                                {getEventBrandsData?.data?.map((item, index) => (
                                                    <Link key={index} to={{pathname: `/dashboard/rd/partnerships/${eventId}/provide-event-feedback/${item?.user_id}`}}>
                                                        <img className="img-fluid card-img-top mb-1" src={item?.brand_logo ?? defaultAvatar}/>
                                                        <h5 className="text-break text-center">{item?.brand_name}</h5>
                                                    </Link>
                                                ))}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ) : (
                                <Col sm="12" md="12" lg="12">
                                    <div className='text-center bg-white p-1'>
                                        Sorry! No brand found
                                    </div>
                                </Col>
                            ))}
                    </Row>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}
export default TabsBasic
