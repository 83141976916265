// ** React Imports
import { Fragment, useEffect, useState } from 'react'

// ** Custom Components
// import Avatar from '@components/avatar'
import { getRDashboardNotifications, getRdUnreadNotifications } from '../../../../../src/redux/actions/rdDashboard/index'

// ** Third Party Components
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Bell, X, Check, AlertTriangle } from 'react-feather'
import {
  Button,
  Badge,
  Media,
  Spinner,
  CustomInput,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
const NotificationDropdown = () => {
  const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))

  const rdStoreSlice = useSelector(store => store.rd)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (!!rdStoreSlice?.rdNotifications) {
      setIsLoading(false)
    }
  }, [rdStoreSlice])

  useEffect(() => {
    // setInterval(function () {
    //   dispatch(getRdUnreadNotifications())
    // }, 300000)
    if (userData) {
      dispatch(getRdUnreadNotifications())
    }
  }, [])

  const clickHandler = () => {
    setIsLoading(true)
    dispatch(getRDashboardNotifications(false))
    dispatch({type: "REMOVE_NOTIFICATION_COUNT_ON_CLICK"})
  }

  const handleRedirectMessage = (user) => {
    localStorage.setItem('selectedChatBrand', JSON.stringify({
      user_id: user.user_id,
      brand_logo: user.brand_logo,
      brand_name: user.brand_name
    }))
    window.location.href = `/rd/apps/chat/chatRd/${user.user_id}`
  }

  const renderNotificationItems = () => {
    return (
      <PerfectScrollbar
        component='li'
        className='media-list scrollable-container'
        options={{
          wheelPropagation: false
        }}
      >
        {isLoading && (
          <div className="w-100 my-2 text-center">
            <Spinner color='primary' />
          </div>)}
        {!isLoading && rdStoreSlice?.rdNotifications?.data?.length === 0 && (
          <div className="w-100 my-2 text-center">
            <p>There is no new notification</p>
          </div>)}
        {!isLoading && rdStoreSlice?.rdNotifications?.data?.map((item, index) => {
          return (
            <Fragment>
              {item.type === 'message' &&
                <a key={index} className='d-flex' onClick={(e) => {
                  e.preventDefault()
                  handleRedirectMessage(item)
                }}>
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    <small className='text-black-50' dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Media>
                </a>
              }
              {item.type === 'request' &&
                <a key={index} className='d-flex' href='/dashboard/rd/partnerships/manage' >
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    <small className='text-black-50' dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Media>
                </a>
              }
              {item.type === 'invoice_paid' &&
                <a key={index} className='d-flex' href='/dashboard/rd/partnerships/payment' >
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    <small className='text-black-50' dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Media>
                </a>
              }
              {item.type === 'subscription' &&
                <Link key={index} className='d-flex' to='/dashboard/rd/subscription-plan'>
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    <small className='text-black-50' dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Media>
                </Link>
              }
              {item.type === 'profile' &&
                <a key={index} className='d-flex' href='/dashboard/rd/view-profile'  >
                  <Media
                    className={classnames('d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    <small className='text-black-50' dangerouslySetInnerHTML={{ __html: item.message }} />
                  </Media>
                </a>
              }
              {item.type === 'new_campaign' &&
                  <a key={index} className='d-flex' href={`/dashboard/rd/campaign-application/${item.campaign_id}`}>
                    <Media
                        className={classnames('d-flex', {
                          'align-items-start': !item.switch,
                          'align-items-center': item.switch
                        })}
                    >
                      <small className='text-black-50' dangerouslySetInnerHTML={{ __html: item.message }} />
                    </Media>
                  </a>
              }
              {item.type === 'resource' &&
                  <a key={index} className='d-flex' href={`/dashboard/rd/resources-content/${item.folder_id}?title=${item.res_title}`}>
                    <Media
                        className={classnames('d-flex', {
                          'align-items-start': !item.switch,
                          'align-items-center': item.switch
                        })}
                    >
                      <small className='text-black-50' dangerouslySetInnerHTML={{ __html: item.message }} />
                    </Media>
                  </a>
              }
              {item.type === 'new_invoice_paid' &&
                  <a key={index} className='d-flex' href='/rd/invoice'>
                    <Media
                        className={classnames('d-flex', {
                          'align-items-start': !item.switch,
                          'align-items-center': item.switch
                        })}
                    >
                      <small className='text-black-50' dangerouslySetInnerHTML={{ __html: item.message }} />
                    </Media>
                  </a>
              }
            </Fragment>
          )
        })}
      </PerfectScrollbar>
    )
  }
  /*eslint-enable */

  return (
    <UncontrolledDropdown tag='li' className='dropdown-notification nav-item mr-25'>
      <DropdownToggle tag='a' className='nav-link' onClick={clickHandler}>
        <Bell size={21} />
        {rdStoreSlice?.rdUnreadNotifications?.data > 0 && (
            <Badge pill color='danger' className='badge-up'>
              {rdStoreSlice?.rdUnreadNotifications?.data > 0 ? rdStoreSlice?.rdUnreadNotifications?.data : '0'}
            </Badge>
        )}
      </DropdownToggle>
      <DropdownMenu tag='ul' right className='dropdown-menu-media mt-0'>
        <li className='dropdown-menu-header'>
          <DropdownItem className='d-flex' tag='div' header>
            <h4 className='notification-title mb-0 mr-auto'>Notifications</h4>
          </DropdownItem>
        </li>
        {renderNotificationItems()}
        <li className='dropdown-menu-footer'>
          <Button.Ripple color='primary' block>
            <Link className="btn-primary" to="/dashboard/rd/notifications">
              View all notifications
            </Link>
          </Button.Ripple>
        </li>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default NotificationDropdown
