import {Fragment, useState, useEffect} from 'react'
import '@styles/base/pages/page-faq.scss'
import {Link, useHistory} from 'react-router-dom'
import {Row, Col, TabContent, Card, Spinner} from 'reactstrap'
import CardBody from 'reactstrap/lib/CardBody'
import {getDashboardNotifications} from '@store/actions/adminDashboard'
import {useSelector, useDispatch} from 'react-redux'
import CustomPagination from '@src/views/components/pagination/CustomPagination'

const AdminNotifications = () => {
    const [isPageLoading, setIsPageLoading] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const adminnotificationsSlice = useSelector(store => store.adminDashboardSlice)

    useEffect(() => {
        if (!!adminnotificationsSlice?.notifications) {
            setIsPageLoading(false)
        }
    }, [adminnotificationsSlice])

    useEffect(() => {
        setIsPageLoading(true)
        dispatch(getDashboardNotifications(false))
    }, [])

    const handleCustomPagination = pageUrl => {
        setIsPageLoading(true)
        dispatch(getDashboardNotifications(true, pageUrl))
    }

    const handleRedirectMessage = (user) => {
        localStorage.setItem('selectedChatUserForAdmin', JSON.stringify({
            user_id: user.user_id,
            to_user_id: user.to_user_id,
            user_role: user.user_role,
            name: user.name,
            brand_name: user.brand_name,
            brand_logo: user.brand_logo,
            profile_img: user.profile_img
        }))
        history.push(`/admin/apps/chat/chatAdmin`)
    }

    const renderTabContent = () => {
        return adminnotificationsSlice?.notifications?.data.map((item, index) => {
            return (
                <Fragment>
                    {item.type === 'rd_signup' && item.user_role === 'rd' &&
                        <Card>
                            <Link to={`/admin/manage-rd-pending/${item.user_id}`}>
                                <CardBody>
                                    {item.message}
                                </CardBody>
                            </Link>
                        </Card>
                    }
                    {item.type === 'message' &&
                        <Card>
                            <Link onClick={e => {
                                e.preventDefault()
                                handleRedirectMessage(item)
                            }}>
                                <CardBody>
                                    {item.message}
                                </CardBody>
                            </Link>
                        </Card>
                    }
                    {item.type === 'contact' &&
                        <Card>
                            <a>
                                <CardBody>
                                    {item.message}
                                </CardBody>
                            </a>
                        </Card>
                    }
                    {item.type === 'subscription' && item.user_role === 'brand' &&
                        <Card>
                            <Link to={`/admin/manage-brands/${item.user_id}`}>
                                <CardBody>
                                    {item.message}
                                </CardBody>
                            </Link>
                        </Card>
                    }
                    {item.type === 'signup' && item.user_role === 'brand' &&
                        <Card>
                            <Link to={`/admin/manage-brands/${item.user_id}`}>
                                <CardBody>
                                    {item.message}
                                </CardBody>
                            </Link>
                        </Card>
                    }
                    {item.type === 'request' &&
                        <Card>
                            <Link to={`/admin/manage-events/${item?.event_id}`}>
                                <CardBody>
                                    {item.message}
                                </CardBody>
                            </Link>
                        </Card>
                    }
                </Fragment>
            )
        })
    }

    return (
        <Fragment>
            {isPageLoading && (<div className="w-100 my-4 text-center">
                <Spinner color='primary'/>
            </div>)}
            {!isPageLoading && (<Fragment>
                <Row>
                    <Col sm='12'>
                        <h3 className="mb-2">All Notifications</h3>
                        <TabContent activeTab={1}>{renderTabContent()}</TabContent>
                    </Col>
                </Row>
                <CustomPagination pageLinks={adminnotificationsSlice?.notifications?.pages} onPaginationClick={handleCustomPagination}/>
            </Fragment>)}

        </Fragment>
    )
}

export default AdminNotifications
