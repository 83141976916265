import {useEffect, useState, Fragment} from "react"
import {Link, useParams, useHistory} from "react-router-dom"
import Avatar from "@components/avatar"
import img4 from "@src/assets/images/banner/banner-44.jpg"
import PDF from "@src/assets/images/icons/pdf1.png"
import Tiktok from "@src/assets/images/icons/tik-tok.svg"
import Pinterest from "@src/assets/images/icons/pinterest.svg"
import {useDispatch, useSelector} from "react-redux"
import classnames from 'classnames'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import {
    Facebook,
    Twitter,
    Linkedin,
    Instagram,
    MapPin,
    Calendar,
    Gift,
    Heart,
    Folder,
    Eye,
    Video
} from "react-feather"
import {
    Card,
    CardTitle,
    CardBody,
    CardText,
    Row,
    Col,
    Button,
    Spinner,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardHeader,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Input
} from "reactstrap"
import "@styles/base/pages/page-auth.scss"
import {
    getRdDetailsAtBrand,
    getFavoriteFolders,
    createFavoriteFolder,
    addToFavorite,
    nullResponse
} from '@src/redux/actions/brandsDashboard'
import {
    getBrandCampaignsAtProfile,
    getBrandsByEventId
} from '@src/redux/actions/rdDashboard'
import {NULL} from '@src/redux/actionTypes'
import {
    loadUpcomingEventsRDAction,
    loadPastEventsRDAction
} from "@src/views/apps/opportunitiesBrands/store/actions"
import queryString from "query-string"
import {rdIgData} from "@src/redux/actions/BrandActions"
import ImageViewer from "@src/views/components/ImageViewer"
import PdfViewer from "@src/views/components/PdfViewer"
import VideoPlayer from "@src/views/components/VideoPlayer"

const ViewProfile = () => {
    const [isSelectFoldersModalOpen, setIsSelectFoldersModalOpen] = useState(false)
    const [folderName, setFolderName] = useState('')
    const [selectedFolder, setSelectedFolder] = useState(null)
    const [selectedProfile, setSelectedProfile] = useState(null)
    const [folderNameError, setFolderNameError] = useState(false)
    const [isFolderCreating, setIsFolderCreating] = useState(false)

    const [isPageLoading, setIsPageLoading] = useState(false)
    const [isListLoading, setIsListLoading] = useState(false)
    const [isFoldersLoading, setIsFoldersLoading] = useState(false)
    const [isAddingToFavorite, setIsAddingToFavorite] = useState(false)

    const [active, setActive] = useState("1")

    const [rdDetails, setRdDetails] = useState(null)
    const [participateModal, setParticipateModal] = useState(false)
    const [brandCampaignDetailsModal, setBrandCampaignDetailsModal] = useState(false)
    const [eventId, setEventId] = useState('')

    const [upcommingOpportunities, setUpcommingOpportunities] = useState(null)
    const [pastOpportunities, setPastOpportunities] = useState(null)
    const [brandCampaigns, setBrandCampaigns] = useState(null)
    const [brandCampaignDetails, setBrandCampaignDetails] = useState(null)
    const [brandsByPastEventId, setBrandsByPastEventId] = useState(null)

    const [upcommingOpportunitiesPaginationInfo, setUpcommingOpportunitiesPaginationInfo] = useState(null)
    const [pastOpportunitiesPaginationInfo, setPastOpportunitiesPaginationInfo] = useState(null)
    const [brandCampaignsOpportunitiesPaginationInfo, setBrandCampaignsOpportunitiesPaginationInfo] = useState(null)

    // const [isPageLoading, setIsPageLoading] = useState(false)
    const [isBrandsListLoading, setIsBrandsListLoading] = useState(false)
    const [isBrandCampaignsLoading, setIsBrandCampaignsLoading] = useState(false)
    const [isUpcommingOpportunitiesLoading, setIsUpcommingOpportunitiesLoading] = useState(false)
    const [isPastOpportunitiesLoading, setIsPastOpportunitiesLoading] = useState(false)
    const [imageViewer, setImageViewer] = useState({show: false, src: ''})
    const [pdfViewer, setPdfViewer] = useState({show: false, url: '', filename: ''})
    const [videoPlayer, setVideoPlayer] = useState({show: false, url: '', filename: ''})

    const rdDashboardStoreSlice = useSelector((store) => store.rd)
    const brandsDashboardStoreSlice = useSelector((store) => store.brandsDashboardSlice)

    const {loadUpcomingEventsRDLoading, loadUpcomingEventsRDData, loadUpcomingEventsRDError} = useSelector((store) => store.loadUpcomingEventsRDReducer)
    const {loadPastEventsRDLoading, loadPastEventsRDData, loadPastEventsRDError} = useSelector((store) => store.loadPastEventsRDReducer)

    const {
        rdIgDataLoading,
        rdIgDataData,
        rdIgDataError
    } = useSelector((store) => store.brandReducer)

    const dispatch = useDispatch()
    const history = useHistory()
    const {id: rdId} = useParams()

    useEffect(() => {

        if (!!brandsDashboardStoreSlice?.rdDetailsAtBrand) {
            setIsPageLoading(false)
            setRdDetails(brandsDashboardStoreSlice?.rdDetailsAtBrand.data)
        }
        if (!!brandsDashboardStoreSlice?.addToFavroiteRes) {
            dispatch(nullResponse(NULL.BRANDS_MY_RDS))
            // dispatch(getMyRDsAtBrands())
            // setRdDetails(brandsDashboardStoreSlice?.rdDetailsAtBrand.data)
            dispatch(getRdDetailsAtBrand(rdId))

            dispatch(nullResponse(NULL.BRANDS_ADD_TO_FAVORITE))
            setIsAddingToFavorite(false)
            setIsSelectFoldersModalOpen(false)
            setIsListLoading(true)
        }
        if (!!brandsDashboardStoreSlice?.favoriteFoldersRes) {
            setIsFoldersLoading(false)
        }
        if (!!brandsDashboardStoreSlice?.createfavoriteFolderRes) {
            setFolderName('')
            setIsFolderCreating(false)
            dispatch(nullResponse(NULL.BRANDS_GET_FOLDERS))
            dispatch(nullResponse(NULL.BRANDS_CREATE_FOLDER))
            dispatch(getFavoriteFolders(false))
            setIsFoldersLoading(true)
        }

    }, [brandsDashboardStoreSlice])

    useEffect(() => {
        if (!!rdDashboardStoreSlice?.upcommingOpportunitiesAtProfile) {
            const {links, meta, pages} = rdDashboardStoreSlice?.upcommingOpportunitiesAtProfile
            setUpcommingOpportunitiesPaginationInfo({...links, ...meta, pages})
            setUpcommingOpportunities(rdDashboardStoreSlice?.upcommingOpportunitiesAtProfile.data)
            setIsUpcommingOpportunitiesLoading(false)
        }

        if (!!rdDashboardStoreSlice?.pastOpportunitiesAtProfile) {
            const {links, meta, pages} = rdDashboardStoreSlice?.pastOpportunitiesAtProfile
            setPastOpportunitiesPaginationInfo({...links, ...meta, pages})
            setPastOpportunities(rdDashboardStoreSlice?.pastOpportunitiesAtProfile.data)
            setIsPastOpportunitiesLoading(false)
        }

        if (!!rdDashboardStoreSlice?.brandCampaignsAtProfile) {
            const {links, meta, pages} = rdDashboardStoreSlice?.brandCampaignsAtProfile
            setBrandCampaignsOpportunitiesPaginationInfo({...links, ...meta, pages})
            setBrandCampaigns(rdDashboardStoreSlice?.brandCampaignsAtProfile.data)
            setIsBrandCampaignsLoading(false)
        }

        if (!!rdDashboardStoreSlice?.brandsByPastEventId) {
            setBrandsByPastEventId(rdDashboardStoreSlice?.brandsByPastEventId.data)
            setIsBrandsListLoading(false)
        }

    }, [rdDashboardStoreSlice])

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        if (!!user && user?.subscriptionexp) history.push('/subscription-plans')

        setIsPageLoading(true)
        setIsUpcommingOpportunitiesLoading(true)
        setIsPastOpportunitiesLoading(true)
        setIsBrandCampaignsLoading(true)
        dispatch(getRdDetailsAtBrand(rdId))
        dispatch(loadUpcomingEventsRDAction({
            user_id: rdId
        }))
        dispatch(loadPastEventsRDAction({
            user_id: rdId
        }))
        dispatch(rdIgData({
            user_id: rdId
        }))
    }, [])

    const handleUpcommingOpportunitiesPagination = (pageUrl) => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        dispatch(loadUpcomingEventsRDAction({
            user_id: rdId,
            page: pageNo
        }))
    }

    const handlePastOpportunitiesPagination = (pageUrl) => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        dispatch(loadPastEventsRDAction({
            user_id: rdId,
            page: pageNo
        }))
    }

    const handleBrandCampaignsPagination = (pageUrl) => {
        setIsBrandCampaignsLoading(true)
        dispatch(getBrandCampaignsAtProfile(true, pageUrl))
    }

    const getBrandsByPastOpportunity = (id) => {
        setEventId(id)
        setIsBrandsListLoading(true)
        dispatch(getBrandsByEventId(id))
        setParticipateModal(true)
    }

    const toggle = (tab) => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const handleRedirectMessage = (user) => {
        localStorage.setItem('selectedChatRd', JSON.stringify(user))
        history.push(`/brands/apps/chat/chatBrands/${user.id}`)
    }

    const handleCreateFolder = () => {
        if (!folderName) {
            setFolderNameError(true)
            return
        }
        setFolderNameError(false)
        setIsFolderCreating(true)
        dispatch(createFavoriteFolder({folderName}))
    }

    const handleSelectFolder = (folder) => {
        setSelectedFolder(folder)
    }

    const getAddToFavoriteProfile = (profile) => {
        setSelectedProfile(profile)
        if (!profile.is_favorite) setIsSelectFoldersModalOpen(true)
        if (profile.is_favorite) {
            setIsListLoading(true)
            dispatch(addToFavorite({id: profile.id, folder_id: profile.folder_id, type: 'profile'}))
        }
    }

    const handleAddToFavorite = () => {
        setIsAddingToFavorite(true)
        dispatch(addToFavorite({id: selectedProfile.id, folder_id: selectedFolder.id, type: 'profile'}))
    }

    const handleFavorite = (profile) => {
        // handleAddToFavorite(profile)
        getAddToFavoriteProfile(profile)
    }

    const openURL = (url) => {
        ((url.includes("https://") || url.includes("http://"))) ? window.open(url, "_blank").focus() : window.open(`//${window.encodeURIComponent(url)}`, "_blank")
    }

    return (
        <Fragment>
            {isPageLoading && (
                <div className="w-100 my-4 text-center">
                    <Spinner color='primary'/>
                </div>)}
            {!isPageLoading && !!rdDetails && (
                <Fragment>
                    <Row className="match-height">
                        <Col md="5" lg="3" sm="12">
                            <Card className="ecommerce-card view-card text-center">
                                <div className="ml-auto mt-1 mr-1">
                                    {rdDetails.is_favorite && <Heart
                                        className='text-muted cursor-pointer'
                                        color="red"
                                        size={14}
                                        onClick={() => handleFavorite(rdDetails)}
                                    />}
                                    {!rdDetails.is_favorite && <Heart
                                        className='text-muted cursor-pointer'
                                        size={14}
                                        onClick={() => handleFavorite(rdDetails)}
                                    />}
                                </div>
                                <div className="item-img text-center mx-auto mt-1">
                                    <Avatar img={rdDetails.profile_img} imgHeight="110" imgWidth="110"/>
                                </div>
                                <CardBody className="text-center">
                                    <h5 className="item-name font-weight-bolder">{rdDetails.name}</h5>
                                    <CardText className="my-1">
                                        {rdDetails.location.city[0]?.value || 'N/A'},{" "}
                                        {rdDetails.location.state[0]?.value || 'N/A'}
                                    </CardText>
                                    <CardText className="item-description d-flex justify-content-center font-weight-bolder">
                    <span className="text-truncate">
                      {rdDetails.website && (
                          <a href='javascript:;' onClick={() => openURL(rdDetails.website)}>
                              View Website
                          </a>
                      )}
                    </span>
                                    </CardText>
                                    <hr/>
                                    {rdIgDataData?.profile_picture_url && (
                                        <div className='p-1'>
                                            <div className='mb-2'><strong>Instagram Insights</strong></div>
                                            <div className='text-center'>
                                                <Avatar img={rdIgDataData?.profile_picture_url} imgHeight="120" imgWidth="120"/>
                                            </div>
                                            <div className='text-center mt-1 mb-2'>
                                                <strong>{rdIgDataData?.name}</strong>
                                            </div>
                                            <div className='text-center mt-1 mb-2'>
                                                <span>{rdIgDataData?.biography}</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <div className='text-center'>
                                                    <p className='badge badge-primary'>{rdIgDataData?.followers_count}</p>
                                                    <p>Followers</p>
                                                </div>
                                                <div className='text-center'>
                                                    <p className='badge badge-primary'>{rdIgDataData?.follows_count}</p>
                                                    <p>Follows</p>
                                                </div>
                                                <div className='text-center'>
                                                    <p className='badge badge-primary'>{rdIgDataData?.media_count}</p>
                                                    <p>Media</p>
                                                </div>
                                            </div>
                                            <hr/>
                                        </div>
                                    )}
                                    {/* <div className="healthcare blue-bg-500">
                    <p className="general-wellness font-weight-bolder blue-700 text-capitalize">
                      {rdDetails.profile_status}
                    </p>
                  </div> */}
                                    <div className="d-flex justify-content-center flex-wrap">
                                        {!!rdDetails.instagram && <a
                                            className="mx-1 text-truncate mb-1"
                                            href='javascript:;'
                                            onClick={() => openURL(rdDetails.instagram)}
                                        >
                                            <Instagram/>{" "}
                                        </a>}
                                        {!!rdDetails.secondary_instagram_url && <a
                                            className="mx-1 text-truncate mb-1"
                                            href='javascript:;'
                                            onClick={() => openURL(rdDetails.secondary_instagram_url)}
                                        >
                                            <Instagram/>{" "}
                                        </a>}
                                        {!!rdDetails.facebook && <a
                                            className="mx-1 text-truncate mb-1"
                                            href='javascript:;'
                                            onClick={() => openURL(rdDetails.facebook)}
                                        >
                                            <Facebook/>{" "}
                                        </a>}
                                        {!!rdDetails.secondary_facebook_url && <a
                                            className="mx-1 text-truncate mb-1"
                                            href='javascript:;'
                                            onClick={() => openURL(rdDetails.secondary_facebook_url)}
                                        >
                                            <Facebook/>{" "}
                                        </a>}
                                        {!!rdDetails.twitter && <a
                                            className="mx-1 text-truncate mb-1"
                                            href='javascript:;'
                                            onClick={() => openURL(rdDetails.twitter)}
                                        >
                                            <Twitter/>{" "}
                                        </a>}
                                        {!!rdDetails.linkedin && <a
                                            className="mx-1 text-truncate mb-1"
                                            href='javascript:;'
                                            onClick={() => openURL(rdDetails.linkedin)}
                                        >
                                            <Linkedin/>{" "}
                                        </a>}
                                        {!!rdDetails.tiktok && <a
                                            className="mx-1 text-truncate mb-1"
                                            href='javascript:;'
                                            onClick={() => openURL(rdDetails.tiktok)}
                                        >
                                            <img src={Tiktok} width="24" height="26"/>{" "}
                                        </a>}
                                        {!!rdDetails.pinterest && <a
                                            className="mx-1 text-truncate mb-1"
                                            href='javascript:;'
                                            onClick={() => openURL(rdDetails.pinterest)}
                                        >
                                            <img src={Pinterest} width="24" height="24"/>{" "}
                                        </a>}
                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                        <Col md="7" lg="9" sm="12">
                            <Card>
                                <CardBody>
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <h4 className="font-weight-bolder">About me!</h4>
                                            <Button.Ripple onClick={() => handleRedirectMessage(rdDetails)} className="m-25">
                                                Message
                                            </Button.Ripple>
                                        </div>
                                        <div dangerouslySetInnerHTML={{__html: rdDetails?.about_me || 'N/A'}}/>
                                        {rdDetails.attributes.industry?.length > 0 && (
                                            <CardText>
                                                <h5 className="text-primary font-weight-bolder">Industry</h5>
                                                {rdDetails.attributes.industry?.map((val, i) => (
                                                    <span>
                                {val.value}
                                                        {rdDetails.attributes.industry?.length - 1 !== i && ", "}
                              </span>
                                                ))}
                                            </CardText>
                                        )}

                                        {rdDetails.attributes.speciality?.length > 0 && (
                                            <CardText>
                                                <h5 className="text-primary font-weight-bolder">Speciality</h5>
                                                {rdDetails.attributes.speciality?.map((val, i) => (
                                                    <span>
                                {val.value}
                                                        {rdDetails.attributes.speciality?.length - 1 !== i && ", "}
                              </span>
                                                ))}
                                            </CardText>
                                        )}

                                        {rdDetails.attributes.personal_attributes?.length > 0 && (
                                            <CardText>
                                                <h6 className="text-primary font-weight-bolder">Personal Attributes</h6>
                                                {rdDetails.attributes.personal_attributes?.map((val, i) => (
                                                    <span>
                                {val.value}
                                                        {rdDetails.attributes.personal_attributes?.length - 1 !== i && ", "}
                              </span>
                                                ))}
                                            </CardText>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Fragment>
                                <h3 className="mt-2">Opportunities</h3>
                                <hr/>
                                <Nav tabs className="my-2">
                                    <NavItem>
                                        <NavLink
                                            active={active === "1"}
                                            onClick={() => {
                                                toggle("1")
                                            }}
                                        >
                                            Upcoming Opportunities
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            active={active === "2"}
                                            onClick={() => {
                                                toggle("2")
                                            }}
                                        >
                                            Past Opportunities
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                    <NavLink
                      active={active === "3"}
                      onClick={() => {
                        toggle("3")
                      }}
                    >
                      Brand Campaigns
                    </NavLink>
                  </NavItem> */}
                                    <NavItem>
                                        <NavLink
                                            active={active === "4"}
                                            onClick={() => {
                                                toggle("4")
                                            }}
                                        >
                                            Media Kit
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem className="ml-auto">
                    <Button.Ripple
                      color="primary"
                      block
                      tag={Link}
                      to="/dashboard/rd/opportunities-rdform"
                    >
                      <span className="align-middle">Add Opportunity</span>
                    </Button.Ripple>
                  </NavItem> */}
                                </Nav>
                                <TabContent className="py-50" activeTab={active}>
                                    <TabPane tabId="1">
                                        <Row className="match-height">
                                            {!loadUpcomingEventsRDLoading && loadUpcomingEventsRDData?.data.length === 0 && (
                                                <div className="w-100 my-4 text-center">
                                                    <p>Record doesn't exists</p>
                                                </div>)}
                                            {loadUpcomingEventsRDLoading ? (
                                                <div className="w-100 my-4 text-center">
                                                    <Spinner color='primary'/>
                                                </div>
                                            ) : loadUpcomingEventsRDData?.data.map((item, index) => (
                                                <Col sm="6" md="4" lg="4" key={index}>
                                                    <Card className="event-card-at-profile">
                                                        <CardHeader>
                                                            <CardTitle>
                                                                <Link to={`/dashboard/brands/nutrition-detail/${item.id}`}>
                                                                    {item.event_title}
                                                                </Link>
                                                            </CardTitle>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <div className="d-flex flex-wrap">
                                                                {item.country && (<p className="item"><MapPin size={17} className="mr-1"/>{item.country}</p>)}
                                                                {item.type_of_event && (<p className="item"><Gift size={17} className="mr-1"/>{item.type_of_event}</p>)}
                                                                {item.start_date && (<p className="item"><Calendar size={17} className="mr-1"/>{item.start_date}</p>)}
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                        <CustomPagination pageLinks={loadUpcomingEventsRDData?.pages} onPaginationClick={handleUpcommingOpportunitiesPagination}/>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row className="match-height">
                                            {!loadPastEventsRDLoading && loadPastEventsRDData?.data.length === 0 && (
                                                <div className="w-100 my-4 text-center">
                                                    <p>Record doesn't exists</p>
                                                </div>)}
                                            {loadPastEventsRDLoading ? (
                                                <div className="w-100 my-4 text-center">
                                                    <Spinner color='primary'/>
                                                </div>
                                            ) : loadPastEventsRDData?.data.map((item, index) => (
                                                <Col sm="6" md="4" lg="4" key={index}>
                                                    <Card className="event-card-at-profile">
                                                        <CardHeader>
                                                            <CardTitle>{item.event_title}</CardTitle>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <div className="d-flex flex-wrap">
                                                                {item.country && (<p className="item"><MapPin size={17} className="mr-1"/>{item.country}</p>)}
                                                                {item.type_of_event && (<p className="item"><Gift size={17} className="mr-1"/>{item.type_of_event}</p>)}
                                                                {item.start_date && (<p className="item"><Calendar size={17} className="mr-1"/>{item.start_date}</p>)}
                                                            </div>
                                                            {/*<div className="mt-1">*/}
                                                            {/*  <Button.Ripple*/}
                                                            {/*      color="secondary"*/}
                                                            {/*      className="round m-25"*/}
                                                            {/*      onClick={() => getBrandsByPastOpportunity(item.id)}*/}
                                                            {/*  >*/}
                                                            {/*    <Trello size={14} />*/}
                                                            {/*    <span className="align-middle ml-50">Feedback</span>*/}
                                                            {/*  </Button.Ripple>*/}
                                                            {/*</div>*/}
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                        <CustomPagination pageLinks={loadPastEventsRDData?.pages} onPaginationClick={handlePastOpportunitiesPagination}/>
                                    </TabPane>

                                    {/* <TabPane tabId="3">
                    <Row className="match-height brandSlides">
                      {isBrandCampaignsLoading && (
                        <div className="w-100 my-4 text-center">
                          <Spinner color='primary' />
                        </div>)}
                      {!isBrandCampaignsLoading && brandCampaigns.length === 0 && (
                        <div className="w-100 my-4 text-center">
                          <p>Record doesn't exists</p>
                        </div>)}
                      {!isBrandCampaignsLoading && brandCampaigns.map(item => (
                        <Col sm="6" md="4" lg="4" key={item.id}>
                          <div className="brandContent mb-3">
                            <Avatar
                              img={item?.brand_img || img4}
                              imgHeight="91"
                              imgWidth="91"
                            />
                            <h2 className="brandSlidesRd mt-2">
                              {item?.brand_name || "N/A"}
                            </h2>
                            <div
                              className="healthcare"
                              style={{ backgroundColor: "#ffe2e6" }}
                            >
                              <p
                                className="general-wellness"
                                style={{ color: "#f63f5d" }}
                              >
                                {item?.campaign_title || "N/A"}
                              </p>
                            </div>
                            <Button.Ripple
                              color="secondary"
                              className="m-25"
                              onClick={() => {
                                setBrandCampaignDetails(item)
                                setBrandCampaignDetailsModal(true)
                              }}
                            >
                              <List size={14} />
                              <span className="align-middle ml-50">Details</span>
                            </Button.Ripple>
                            <Button.Ripple
                              tag={Link}
                              to={{
                                pathname: `/dashboard/rd/campaign-application/${item?.id}`,
                                state: {
                                  brandName: item?.brand_name,
                                  campName: item?.campaign_title,
                                  brandImage: item?.brand_img,
                                  campDetail: item?.campaign_details,
                                  formTitle: item?.form_title,
                                  formAttributes: item?.form_attributes,
                                  opportunityFor: item?.opportunity_for,
                                  campId: item?.id,
                                  campFormId: item?.campaign_form_id
                                }
                              }}
                              color="secondary"
                              className="m-25"
                            >
                              <Mail size={14} />
                              <span className="align-middle ml-50">Apply</span>
                            </Button.Ripple>
                          </div>
                        </Col>))}
                    </Row>
                    <CustomPagination pageLinks={brandCampaignsOpportunitiesPaginationInfo?.pages} onPaginationClick={handleBrandCampaignsPagination} />
                  </TabPane> */}

                                    <TabPane tabId="4">
                                        {rdDetails.docs?.length === 0 && (
                                            <div className="w-100 my-4 text-center">
                                                <p>Media doesn't exists</p>
                                            </div>
                                        )}
                                        <div className="d-flex flex-wrap mt-2 -mi-10px">
                                            {rdDetails?.docs?.map((item, index) => (
                                                <div key={index}>
                                                    {(item?.document_type === 'png' || item?.document_type === 'jpg' || item?.document_type === 'jpeg') && (
                                                        <div className='media-img media-file'>
                                                            <div className="download-icon cursor-pointer">
                                                                <a href='javascript:;' onClick={() => setImageViewer({show: true, src: item.document_url})}>
                                                                    <Eye size={16}/>
                                                                </a>
                                                            </div>
                                                            <div className="name-wrap">
                                                                <img className='rounded img-fit-contain' src={item.document_url} alt='Card image'
                                                                     style={{height: '100px', width: '100px'}}/>
                                                                <p className="text-truncate mt-1">
                                                                    <strong>{item.document_name}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {item?.document_type === 'pdf' && (
                                                        <div className='media-img media-file'>
                                                            <div className="download-icon cursor-pointer">
                                                                <a href='javascript:;'
                                                                   onClick={() => setPdfViewer({show: true, url: item.document_url, filename: item.document_name})}>
                                                                    <Eye size={16}/>
                                                                </a>
                                                            </div>
                                                            <div className="name-wrap">
                                                                <img className='rounded png-icon-img' src={PDF} alt='PDF Icon'/>
                                                                <p className="text-truncate mt-1">
                                                                    <strong>{item.document_name}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {(item?.document_type === 'mp4') && (
                                                        <div className='media-img media-file'>
                                                            <div className="download-icon cursor-pointer">
                                                                <a href='javascript:;'
                                                                   onClick={() => setVideoPlayer({show: true, url: item.document_url, filename: item.document_name})}>
                                                                    <Eye size={16}/>
                                                                </a>
                                                            </div>
                                                            <div className="name-wrap text-center">
                                                                <Video className="text-primary" size={90}/>
                                                                <p className="text-truncate">
                                                                    <strong>{item.document_name}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </TabPane>
                                </TabContent>

                                <Modal
                                    size="lg"
                                    isOpen={brandCampaignDetailsModal}
                                    toggle={() => setBrandCampaignDetailsModal(false)}
                                    className="modal-dialog-centered"
                                >
                                    <ModalHeader toggle={() => setBrandCampaignDetailsModal(false)}>
                                        Campaign Detail
                                    </ModalHeader>
                                    <ModalBody>
                                        <CardText>
                                            <b>{brandCampaignDetails?.campaign_title}</b>
                                        </CardText>
                                        <CardText>
                                            <p>{brandCampaignDetails?.campaign_details}</p>
                                        </CardText>
                                        <CardText>
                                            <b>{brandCampaignDetails?.opportunity_for}</b>
                                        </CardText>
                                        <CardText>
                                            <b>{brandCampaignDetails?.form_title}</b>
                                        </CardText>
                                        {brandCampaignDetails?.form_attributes.map((item) => (
                                            <CardText>
                                                {item?.id}. {item?.question}
                                            </CardText>
                                        ))}
                                    </ModalBody>
                                </Modal>

                                <Modal
                                    isOpen={participateModal}
                                    toggle={() => setParticipateModal(!participateModal)}
                                    className="modal-dialog-centered"
                                >
                                    <ModalHeader toggle={() => setParticipateModal(!participateModal)}>
                                        Select Brand
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            {isBrandsListLoading && (
                                                <div className="w-100 my-4 text-center">
                                                    <Spinner color="primary"/>
                                                </div>
                                            )}
                                            {!isBrandsListLoading && brandsByPastEventId?.length === 0 && (
                                                <div className="w-100 my-4 text-center">
                                                    <p>Record doesn't exists</p>
                                                </div>
                                            )}
                                            {!isBrandsListLoading && (
                                                <Fragment>
                                                    {brandsByPastEventId?.map((brand) => (
                                                        <Col lg="6">
                                                            <Link to={{pathname: `/dashboard/rd/partnerships/${eventId}/provide-event-feedback/${brand.id}`}}>
                                                                <Card className="my-1">
                                                                    <CardBody className="d-flex align-items-center">
                                                                        <img
                                                                            className="mr-1"
                                                                            src={brand?.brand_logo || img4}
                                                                            height="60"
                                                                            width="60"
                                                                        />
                                                                        <h5>{brand?.brand_name}</h5>
                                                                    </CardBody>
                                                                </Card>
                                                            </Link>
                                                        </Col>
                                                    ))}
                                                </Fragment>
                                            )}
                                        </Row>
                                    </ModalBody>
                                </Modal>
                            </Fragment>
                        </Col>
                    </Row>
                </Fragment>)}
            <Modal scrollable isOpen={isSelectFoldersModalOpen} className='modal-dialog-centered'>
                <ModalHeader>Select Folder</ModalHeader>
                <ModalBody className="py-2" style={{maxHeight: '50vh', overflowY: 'auto'}}>
                    {isFoldersLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                    {!isFoldersLoading && (
                        <Fragment>
                            {brandsDashboardStoreSlice?.favoriteFoldersRes?.data.map(item => (
                                <Card className={classnames({
                                    'ecommerce-card': true,
                                    'mb-1': true,
                                    'border-1px-white': selectedFolder?.id !== item.id,
                                    'cursor-pointer': true,
                                    'border-1px-purple': selectedFolder?.id === item.id
                                })} key={item.folder_name} onClick={() => handleSelectFolder(item)}>
                                    <CardBody className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <Folder
                                                className='mr-1 text-secondary'
                                                size={24}
                                            />
                                            <h5 className='mb-0'>{item.folder_name}</h5>
                                        </div>
                                    </CardBody>
                                </Card>
                            ))}
                        </Fragment>
                    )}
                    {brandsDashboardStoreSlice?.favoriteFoldersRes?.data?.length !== 0 && <p className="text-center">or</p>}
                    <div className='form-group'>
                        <Label className={classnames({
                            'form-label': true
                        })} htmlFor={`folderName`}>
                            Folder Name<span className="is-invalid">*</span>
                        </Label>
                        <Input
                            type='text'
                            onChange={(e) => {
                                setFolderName(e.target.value)
                                setFolderNameError(false)
                            }}
                            value={folderName}
                            name={`folderName`}
                            id={`folderName`}
                            className={classnames({'is-invalid': folderNameError})}
                        />
                        {folderNameError && <div className="validation-err">This field is required</div>}
                    </div>
                    <div className="text-center">
                        <Button color='primary' outline disabled={isFolderCreating} onClick={handleCreateFolder}>
                            {isFolderCreating ? 'Creating...' : 'Create Folder'}
                        </Button>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' hidden={isAddingToFavorite} onClick={() => setIsSelectFoldersModalOpen(!isSelectFoldersModalOpen)}>
                        Cancel
                    </Button>
                    <Button color='primary' disabled={isAddingToFavorite} onClick={() => handleAddToFavorite()}>
                        {isAddingToFavorite ? 'Adding...' : 'Add to Favorite'}
                    </Button>
                </ModalFooter>
            </Modal>
            <ImageViewer
                show={imageViewer.show}
                hide={() => setImageViewer({show: false, src: ''})}
                images={[{src: imageViewer.src}]}
            />
            <PdfViewer show={pdfViewer.show}
                       url={pdfViewer.url}
                       filename={pdfViewer.filename}
                       hide={() => setPdfViewer({show: false, url: '', filename: ''})}
            />
            <VideoPlayer show={videoPlayer.show}
                         url={videoPlayer.url}
                         filename={videoPlayer.filename}
                         hide={() => setVideoPlayer({show: false, url: '', filename: ''})}
            />
        </Fragment>
    )
}

export default ViewProfile
