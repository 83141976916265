import {useState, Fragment, useEffect} from 'react'
import {
    ChevronDown,
    Search,
    RefreshCcw,
    Trash,
    MoreHorizontal
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CustomInput,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Spinner,
    Alert
} from 'reactstrap'
import {
    addNewCoupons,
    getCouponsListing,
    searchCouponsListing,
    changeCouponStatus,
    deleteCouponData,
    nullResponse
} from '@src/views/tables/data-tables-admin-manage-coupons/store/actions'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import {useSelector, useDispatch} from 'react-redux'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import {NULL} from '@src/views/tables/data-tables-admin-manage-coupons/store/actionsTypes/index'

const ManageCoupons = () => {
    const [searchCouponCode, setSearchCouponCode] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [centeredModal, setCenteredModal] = useState(false)
    const [code, setCode] = useState('')
    const [value, setValue] = useState('')
    const [codeType, setCodeType] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [deleteFAQModal, setDeleteFAQModal] = useState(false)
    const [toastHeaderContent, setToastHeaderContent] = useState('')
    const [toastMessageContent, setToastMessageContent] = useState('')
    const [toastClass, setToastClass] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [paginationInfo, setPaginationInfo] = useState(null)
    const [errorMessageOne, setErrorMessageOne] = useState()
    const [errorMessageTwo, setErrorMessageTwo] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [visible, setVisible] = useState(false)

    const couponsSlice = useSelector(store => store.datatablesAdminManageCoupons)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!!couponsSlice?.couponsListing) {
            const {links, meta, pages} = couponsSlice?.couponsListing
            setPaginationInfo({...links, ...meta, pages})
            setIsTableLoading(false)
        }

        if (!!couponsSlice?.error?.response?.data) {
            const {errors} = couponsSlice?.error?.response?.data
            setVisible(true)
            if (errors) {
                if (!!errors?.coupon_code) {
                    setErrorMessageOne(errors?.coupon_code[0])
                }
                if (!!errors?.value) {
                    setErrorMessageTwo(errors?.value[0])
                }
                setTimeout(() => {
                    setVisible(false)
                    setErrorMessageOne("")
                    setErrorMessageTwo("")
                    dispatch(nullResponse(NULL.ERROR))
                }, 4000)
            }
        }

        if (!!couponsSlice?.couponCreated) {
            const {msg, status} = couponsSlice?.couponCreated
            setVisible(true)
            if (status) {
                setSuccessMessage(msg)
                setTimeout(() => {
                    setSuccessMessage("")
                    setVisible(false)
                    dispatch(nullResponse(NULL.NEW_COUPON_ADDED))
                    setCenteredModal(false)
                    dispatch(getCouponsListing())
                    setIsTableLoading(false)
                    dispatch(nullResponse(NULL.COUPONS_LISTING_DATA))
                }, 4000)
            }
        }

        if (!!couponsSlice?.deleteCoupon) {
            const {msg, status} = couponsSlice?.deleteCoupon
            if (status) {
                setToastClass('success')
                setToastHeaderContent('Deleted Success')
                setToastMessageContent(msg)
                setShowModal(true)
                setTimeout(() => {
                    setShowModal(false)
                    setToastMessageContent("")
                    dispatch(nullResponse(NULL.DELETE_COUPON_DATA))
                }, 3000)
                dispatch(getCouponsListing())
                setIsTableLoading(false)
            } else {
                setToastClass('danger')
                setToastHeaderContent('Deleted Error')
                setToastMessageContent(msg)
                setShowModal(true)
                setTimeout(() => {
                    setShowModal(false)
                    setToastMessageContent("")
                    dispatch(nullResponse(NULL.DELETE_COUPON_DATA))
                }, 3000)
                dispatch(getCouponsListing())
                setIsTableLoading(false)
            }

        }
    }, [couponsSlice])

    useEffect(() => {
        setShowModal(false)
        dispatch(nullResponse(NULL.DELETE_COUPON_DATA))
        setIsTableLoading(true)
        dispatch(getCouponsListing())
    }, [])

    const changeStatus = (couponId) => {
        const params = {
            id: couponId
        }
        dispatch(changeCouponStatus(params))
        setToastClass('bg-success')
        setToastHeaderContent("Success")
        setToastMessageContent("Status updated successfully.")
        setShowModal(true)
        setTimeout(() => {
            setShowModal(false)
        }, 3000)
    }
    const [advSearchColumns, setAdvSearchColumns] = useState([
        {
            name: 'Coupon Code',
            selector: 'coupon_code',
            sortable: true,
            minWidth: '180px'
        },
        {
            name: 'Coupon Type',
            selector: 'coupon_type',
            sortable: true,
            minWidth: '120px',
            cell: row => {
                return (
                    row.coupon_type.replace('_', ' ')
                )
            }
        },
        {
            name: 'Value ',
            selector: 'value',
            sortable: true,
            minWidth: '120px'
        },
        {
            name: 'Date',
            selector: 'date',
            sortable: true,
            minWidth: '70px'
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            minWidth: '50px',
            cell: row => {
                return (
                    <CustomInput
                        className='custom-control-success'
                        type='switch'
                        id={`success_${row.id}`}
                        name='success'
                        inline
                        defaultChecked={row.status === 'enabled'}
                        onClick={() => changeStatus(row.id)}
                    />
                )
            }
        },
        {
            name: 'Actions',
            allowOverflow: true,
            cell: row => {
                return (
                    <div className='d-flex pl-2'>
                        <UncontrolledDropdown>
                            <DropdownToggle className='pr-1' tag='span'>
                                <MoreHorizontal size={15}/>
                            </DropdownToggle>
                            <DropdownMenu right onClick={() => {
                                setDeleteFAQModal(true)
                                setDeleteId(row.id)
                            }}>
                                <DropdownItem>
                                    <Trash size={15}/>
                                    <span className='align-middle ml-50'>Delete</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            }
        }
    ])

    const searchCoupon = () => {
        setIsTableLoading(true)
        dispatch(searchCouponsListing(false, {
            coupon_code: searchCouponCode
        }))
    }

    const dataToRender = () => {
        if (
            couponsSlice?.couponsListing?.data?.length
        ) {
            return couponsSlice?.couponsListing?.data
        }
    }

    const refreshSearchForm = () => {
        setSearchCouponCode('')
        setIsTableLoading(true)
        dispatch(getCouponsListing())
    }

    const createCoupon = () => {
        const params = {
            coupon_code: code,
            coupon_type: codeType,
            value: parseFloat(value).toFixed(2)
        }

        dispatch(addNewCoupons(params))
        dispatch(getCouponsListing())
        setIsTableLoading(false)
        dispatch(getCouponsListing())
    }

    const deleteCoupon = () => {
        dispatch(deleteCouponData(deleteId))
        setDeleteFAQModal(false)
    }

    const handleCustomPagination = pageUrl => {
        setIsTableLoading(true)
        dispatch(searchCouponsListing(true, {
            coupon_code: searchCouponCode
        }, pageUrl))
    }

    const onEnterSearch = (e) => {
        if (e.key === 'Enter') {
            searchCoupon()
        }
    }

    return (
        <Fragment>
            <Card>
                <CardHeader className='d-flex align-items-center justify-content-between flex-wrap border-bottom p-1 px-2'>
                    <CardTitle className="my-1" tag='h4'>Manage Coupons</CardTitle>
                    <Button.Ripple className="my-1" color='primary' onClick={() => setCenteredModal(!centeredModal)}>Add Coupon</Button.Ripple>
                    <Modal isOpen={centeredModal} toggle={() => setCenteredModal(!centeredModal)} className='modal-dialog-centered'>
                        <ModalHeader toggle={() => setCenteredModal(!centeredModal)}>Add Coupon</ModalHeader>
                        <ModalBody>
                            {couponsSlice?.error?.response?.data?.errors?.coupon_code && (<Fragment>
                                <Alert color='danger' isOpen={visible} toggle={() => setVisible(false)}>
                                    <div className='alert-body'>
                                        {errorMessageOne}
                                    </div>
                                </Alert>
                            </Fragment>)}

                            {couponsSlice?.error?.response?.data?.errors?.value && (<Fragment>
                                <Alert color='danger' isOpen={visible} toggle={() => setVisible(false)}>
                                    <div className='alert-body'>
                                        {errorMessageTwo}
                                    </div>
                                </Alert>
                            </Fragment>)}
                            {!!couponsSlice?.couponCreated && (<Fragment>
                                <Alert color='success' isOpen={visible} toggle={() => setVisible(false)}>
                                    <div className='alert-body'>
                                        {successMessage}
                                    </div>
                                </Alert>
                            </Fragment>)}
                            <FormGroup tag={Col} md='12'>
                                <Label className="mb-1">Coupon Type</Label>
                                <br></br>
                                <CustomInput type='radio' id='exampleCustomRadio' name='customRadio'
                                             inline label='Dollar Amount' value="amount_off" onChange={(e) => setCodeType(e.target.value)}
                                />
                                <CustomInput type='radio' id='exampleCustomRadio2' name='customRadio'
                                             inline label='Percent' value="percent_off" onChange={(e) => setCodeType(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup tag={Col} md='12'>
                                <Label htmlFor={`code`}>
                                    Code
                                </Label>
                                <Input
                                    required
                                    type='text'
                                    name={`code`}
                                    id={`code`}
                                    placeholder=''
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup tag={Col} md='12'>
                                <Label htmlFor={`value`}>
                                    Value
                                </Label>
                                <Input
                                    required
                                    type='number'
                                    name={`value`}
                                    id={`value`}
                                    placeholder=''
                                    onChange={(e) => setValue(e.target.value)}
                                />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='primary' onClick={createCoupon}>
                                Submit
                            </Button>
                        </ModalFooter>
                    </Modal>
                </CardHeader>
                <CardBody>
                    <Row form className='mt-1 mb-50'>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='name'>Coupon Code</Label>
                                <Input id='name' placeholder='FOODBORO10' value={searchCouponCode} onChange={(e) => setSearchCouponCode(e.target.value)} onKeyDown={onEnterSearch}/>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <div className="admin-filter-actions inline-direction">
                                <Button.Ripple className='btn-icon mr-10px' color='primary' onClick={searchCoupon}>
                                    <Search size={16}/>
                                </Button.Ripple>
                                <Button.Ripple className='btn-icon' outline color='primary' onClick={refreshSearchForm}>
                                    <RefreshCcw size={16}/>
                                </Button.Ripple>
                            </div>
                        </Col>
                    </Row>

                    <Alert color={toastClass} isOpen={showModal}>
                        <div className='alert-body'>
                            {toastMessageContent}
                        </div>
                    </Alert>

                </CardBody>
                {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!isTableLoading && (
                    <>
                        <DataTable
                            noHeader
                            paginationServer
                            columns={advSearchColumns}
                            paginationPerPage={7}
                            className='react-dataTable'
                            sortIcon={<ChevronDown size={10}/>}
                            paginationDefaultPage={currentPage + 1}
                            data={dataToRender()}
                        />
                        <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
                    </>
                )}
                <Modal isOpen={deleteFAQModal} toggle={() => setDeleteFAQModal(!deleteFAQModal)} className='modal-dialog-centered'>
                    <ModalHeader toggle={() => setDeleteFAQModal(!deleteFAQModal)}>Delete Coupon</ModalHeader>
                    <ModalBody>
                        Are you sure want to delete this coupon?
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' outline onClick={() => setDeleteFAQModal(!deleteFAQModal)}>
                            No
                        </Button>
                        <Button color='danger' onClick={deleteCoupon}>
                            Yes
                        </Button>
                    </ModalFooter>
                </Modal>
            </Card>
        </Fragment>
    )
}

export default ManageCoupons
